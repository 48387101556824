import React, {useContext} from 'react';
import {useHistory} from "react-router";

function StudentFinishPage(props) {
    let history = useHistory();

    return (
        <div className="mx-auto max-w-lg py-12">
            <div>
                <div className="text-center">
                    <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                    </svg>
                    <h2 className="mt-2 text-lg font-medium text-gray-900">You are all set!</h2>
                    <p className="mt-1 text-sm text-gray-500">
                        Ready to elevate your learning experience? Secure your tutoring session now by clicking on your tutor's personalized booking link!
                    </p>
                </div>
            </div>
            <div className={"flex justify-center py-14"}>
                <button
                    onClick={()=>history.push("/appointments")}
                    type="submit"
                    className="flex w-96 justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary-alt focus:outline-none"
                >
                    To the homepage
                </button>
            </div>
        </div>
    );
}

export default StudentFinishPage;