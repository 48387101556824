import React, {useContext, useEffect, useRef, useState} from "react";
import UserContext from "../../auth/UserContext";
import dataLoader from "../../api/DataLoader";
import BasicModal from "../../components/BasicModal";

// Import Swiper React components
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import CourseCard from "../../components/CourseCard";

function CourseCardStack(props) {
    const userContext = useContext(UserContext)
    const [courseCard, setCourseCard] = useState();

    const [loading, setLoading] = useState(true);
    const isMounted = useRef(false);


    useEffect(() => {
        if(userContext?.userInfo?.id !== null){
            dataLoader.getLessonCardsForUser(userContext?.userInfo?.id, userContext?.authInfo?.accessToken).then(res =>
                setCourseCard(res.data)
                // console.log(res.data)
            )
        }
    }, [userContext?.userInfo?.id]);

    useEffect(() => {
        if (isMounted.current) {
            setLoading(false)
        } else {
            isMounted.current = true;
        }
    }, [courseCard]);

    return (
        <div>
            {loading
            ?
                <div>loading</div>
                :

                <div>
                    <div className={"flex flex-col items-center gap-4"}>
                        <div className={"rounded-full bg-emerald-100 p-2"}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#059669" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
                            </svg>
                        </div>
                        <h3 className={"text-xl"}>Just a few more clicks...</h3>
                        <p className={"text-center leading-relaxed text-slate-500"}>Share your course and get your first bookings.</p>
                    </div>

                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation={{enabled:true}}
                        pagination={{ clickable: true }}
                        // onSwiper={(swiper) => console.log(swiper)}
                        // onSlideChange={() => console.log('slide change')}
                    >
                        {courseCard.map((card) => (
                            <SwiperSlide>
                                <div className={"mb-12"}>
                                    <CourseCard courseInfo={card}></CourseCard>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

            }

        </div>
    );
}

export default CourseCardStack;