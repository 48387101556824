import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import globe from "../../images/globe.svg"


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function getNumberBasedOnTime() {
    const now = new Date();
    const currentHour = now.getHours();

    // Define the start and end hours of the growing and declining phases
    const startGrowingPhase = 9; // 9am
    const endGrowingPhase = 18; // 6pm

    // Calculate the total duration of each phase in hours
    const growingPhaseDuration = endGrowingPhase - startGrowingPhase;
    const decliningPhaseDuration = 24 - growingPhaseDuration;

    // Define the range of the output number
    const minNumber = 10;
    const maxNumber = 50;

    // Calculate the increment/decrement per hour for each phase
    const incrementPerHour = (maxNumber - minNumber) / growingPhaseDuration;
    const decrementPerHour = (maxNumber - minNumber) / decliningPhaseDuration;

    let number;

    if (currentHour >= startGrowingPhase && currentHour < endGrowingPhase) {
        // We are in the growing phase
        const hoursIntoGrowingPhase = currentHour - startGrowingPhase;
        number = minNumber + (hoursIntoGrowingPhase * incrementPerHour);
    } else {
        // We are in the declining phase
        const hoursIntoDecliningPhase = currentHour < startGrowingPhase ? currentHour + (24 - endGrowingPhase) : currentHour - endGrowingPhase;
        number = maxNumber - (hoursIntoDecliningPhase * decrementPerHour);
    }

    return Math.floor(number);
}

function CtaTable(props) {


    return (
        <div>
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <tbody className="divide-y divide-gray-200 bg-white">

                    <tr key={Math.random()} className={"h-44 shadow-xl relative"}>
                        <td className={"relative whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6"}>
                            <div className={"w-28 h-28 rounded-full bg-indigo-200 absolute left-10 top-5"}></div>
                            <img src={globe} alt="Globe" className={"absolute left-5 top-0 w-44"}/>
                        </td>
                        <td className={"w-[520px]"}>
                            <div className={"flex"}>
                                <h2 className={"font-bold text-3xl mr-12"}>Looks like you have no bookings yet... </h2>
                            </div>
                        </td>

                        <td className={"absolute right-25 top-14 text-center"}>
                            <div>
                                <div className={"text-sm flex gap-1 pt-3 w-64"}>
                                    <dl className="flex flex-none justify-between gap-x-8">
                                        <div className="flex -space-x-0.5">
                                            <dd key={"asd"}>
                                                <img
                                                    className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white"
                                                    src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSoQFvYAr4KD4S-iecBnmLmPf7zuyFyHkd8w&usqp=CAU"}
                                                    alt={"as"}
                                                />
                                            </dd>
                                            <dd key={"asd"}>
                                                <img
                                                    className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white"
                                                    src={"https://ph-files.imgix.net/81c8176a-1b00-4f10-b60f-2ab2729d0a14.png?auto=format"}

                                                    alt={"as"}
                                                />
                                            </dd>
                                            <dd key={"asd"}>
                                                <img
                                                    className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white"
                                                    src={"https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}

                                                    alt={"as"}
                                                />
                                            </dd>
                                            <dd key={"asd"}>
                                                <img
                                                    className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white"
                                                    src={"https://www.mensjournal.com/.image/t_share/MTk2MTM2NTcwNDMxMjg0NzQx/man-taking-selfie.jpg"}

                                                    alt={"as"}
                                                />
                                            </dd>
                                        </div>
                                    </dl>
                                    <p className={"w-32"}>+{getNumberBasedOnTime()} other people</p>

                                </div>

                                <h2 className={"font-bold text-sm text-slate-600 py-2 flex gap-2"}>
                                    {/*<div className={"rounded-full flex border-green-500 h-5 w-5 border animate-ping"}>*/}
                                    {/*    <div className={"mx-auto my-auto rounded-full bg-green-500 h-3 w-3"}></div>*/}
                                    {/*</div>*/}
                                    <span className="relative flex h-5 w-5">
                                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                                        <span className="relative inline-flex rounded-full h-5 w-5 bg-green-500"></span>
                                    </span>
                                    <p>Teaching right now</p>
                                </h2>
                            </div>
                        </td>

                        <td className={"w-64 absolute right-0 top-10 text-center"}>
                           <h2 className={"font-bold text-xl my-2"}>...lets change that!</h2>
                            <button
                                type="button"
                                onClick={props.showShareModal}
                                // className="inline-flex items-center gap-x-2 rounded-md bg-secondary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-secondary-alt focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                className="inline-flex items-center gap-x-1  rounded-md border border-transparent bg-secondary px-4 py-4 text-sm font-medium text-white shadow-sm hover:bg-secondary-alt"

                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75" />
                                </svg>

                                Share your course
                            </button>

                        </td>

                    </tr>

                    <tr key={Math.random()} className={"opacity-20"}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 relative w-72">
                            <div className="flex items-center">
                                <img
                                    src={"https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
                                    alt={props.name}
                                    className={"h-12 w-12 flex-none rounded-full bg-white object-cover"}
                                />
                                <div className="ml-4">
                                    <div
                                        className="font-medium text-gray-900">{"Lyn Connor"}</div>
                                    <div
                                        className="text-gray-500 hidden sm:table-cell">{"lyncon@gmail.com"}</div>
                                </div>
                            </div>
                        </td>
                        <td className="text-ellipsis overflow-hidden px-3 py-4 text-sm text-gray-500 hidden md:table-cell relative max-w-4/12">
                            <div className="text-gray-900">{"Mastering Calculus: Unveiling the Mathematics of Change"}</div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                            {(moment().minute() || moment().second() || moment().millisecond() ? moment().add(1, 'hour').startOf('hour') : moment().startOf('hour')).add(1, "days").subtract(5, "hours").format("MMMM Do YYYY, HH:mm")}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                <span
                                                                    className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                                            Accepted
                                                                </span>

                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 w-48">

                            {/*{getBookingButtons(person)}*/}
                        </td>
                    </tr>
                    <tr key={Math.random()} className={"opacity-20"}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 relative w-72">
                            <div className="flex items-center">
                                <img
                                    src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSoQFvYAr4KD4S-iecBnmLmPf7zuyFyHkd8w&usqp=CAU"}
                                    alt={props.name}
                                    className={"h-12 w-12 flex-none rounded-full bg-white object-cover"}
                                />
                                <div className="ml-4">
                                    <div
                                        className="font-medium text-gray-900">{"Josephina Merv"}</div>
                                    <div
                                        className="text-gray-500 hidden sm:table-cell">{"jomerv@gmail.com"}</div>
                                </div>
                            </div>
                        </td>
                        <td className="text-ellipsis overflow-hidden px-3 py-4 text-sm text-gray-500 hidden md:table-cell relative max-w-4/12">
                            <div className="text-gray-900">{"Mastering Calculus: Unveiling the Mathematics of Change"}</div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                            {(moment().minute() || moment().second() || moment().millisecond() ? moment().add(1, 'hour').startOf('hour') : moment().startOf('hour')).add(1, "days").subtract(3, "hours").format("MMMM Do YYYY, HH:mm")}

                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                <span
                                                                    className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                                            Accepted
                                                                </span>

                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 w-48">

                            {/*{getBookingButtons(person)}*/}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default CtaTable;