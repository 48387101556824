import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";

const app = initializeApp({
    apiKey: "AIzaSyDPiggUfcy9sotaIu04S-h7nhos8dP1Bk0",
    authDomain: "abacus-9ff77.firebaseapp.com",
    projectId: "abacus-9ff77",
    storageBucket: "abacus-9ff77.appspot.com",
    messagingSenderId: "420007233293",
    appId: "1:420007233293:web:4b5453475561376678d244",
    measurementId: "G-BVVR2NDT79"
});

const auth = getAuth(app);

export default auth;