import React, {useContext, useEffect, useRef, useState} from 'react';
import { Cog6ToothIcon } from '@heroicons/react/24/solid'
import UserContext from "../../auth/UserContext";
import dataLoader from "../../api/DataLoader";
import WorkspaceCard from "./WorkspaceCard";
import DriveCTA from "./DriveCTA";
import WorkspaceCardSkeleton from "../../components/WorkspaceCardSkeleton";
import {Helmet} from "react-helmet";

function ResourcesPage(props) {
    const userContext = useContext(UserContext)

    const [workspaceInfo, setWorkspaceInfo] = useState([])
    const [loading, setLoading] = useState(true)
    const [empty, setEmpty] = useState(true)


    useEffect(() => {
        dataLoader.getGoolgeDriveWorkspaces(userContext.userInfo.id, userContext.authInfo.accessToken)
            .then(res => setWorkspaceInfo(res.data))
    }, []);

    useEffect(() => {
        if(workspaceInfo.length > 0){
            setLoading(false)
            setEmpty(false)
        }
        else{
            setLoading(false)
            setEmpty(true)
        }
    }, [workspaceInfo]);

    return (
        <div className={"bg-white h-screen"}>
            <Helmet>
                <title>{"Resources - Abakusz"}</title>
            </Helmet>
            <DriveCTA></DriveCTA>

            {loading ?
                <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8 pt-6 mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <WorkspaceCardSkeleton></WorkspaceCardSkeleton>
                    <WorkspaceCardSkeleton></WorkspaceCardSkeleton>
                </ul>
            :
                <div>
                    {empty ?
                        <div className="text-center mt-12">
                            <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"/>
                            </svg>

                            <h3 className="mt-2 text-sm font-semibold text-gray-900">No workspaces</h3>
                            <p className="mt-1 text-sm text-gray-500">When you have an accepted booking with someone, workspaces will be generated automatically.</p>
                        </div>
                    :
                        <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8 pt-6 mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                            {workspaceInfo.map((workspace) => (<WorkspaceCard partnerId={workspace.partnerId} shareURL={workspace.shareURL} name={workspace.partnerDisplayName} fileNames={workspace.fileNames}></WorkspaceCard>))}
                        </ul>
                    }
                </div>


            }

        </div>
    );
}

export default ResourcesPage;