import {Fragment, useEffect, useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid'
import moment from "moment";
import {onAuthStateChanged} from "firebase/auth";
import auth from "../../auth/base";

// const people = [
//     { id: 1, name: '8:00am' },
//     { id: 2, name: '9:00am' },
// ]

const options = [
    '12:00 AM',
    '1:00 AM',
    '2:00 AM',
    '3:00 AM',
    '4:00 AM',
    '5:00 AM',
    '6:00 AM',
    '7:00 AM',
    '8:00 AM',
    '9:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '1:00 PM',
    '2:00 PM',
    '3:00 PM',
    '4:00 PM',
    '5:00 PM',
    '6:00 PM',
    '7:00 PM',
    '8:00 PM',
    '9:00 PM',
    '10:00 PM',
    '11:00 PM'
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function HourSelect(props) {
    const [selected, setSelected] = useState(props.time)
    // const [selected, setSelected] = useState()
    //
    useEffect(() => {
        console.log(props.time)
        if(props.time !== null){
            setSelected(props.time)
        }
        else{
            setSelected("09:00")
        }
    }, [props.time]);


    const editTime = (time) => {
        props.editTime(time.target.value)
        setSelected(time)
    }

    return (
        // <Listbox value={selected} onChange={editTime}>
        //     {({open}) => (
        //         <>
        //             <div className="relative w-[114px]">
        //                 <Listbox.Button
        //                     className={props.style +" " +"relative w-full cursor-pointer rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 sm:text-sm sm:leading-6" }>
        //
        //                     <span className="block truncate">{selected}</span>
        //                     <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        //         <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
        //       </span>
        //                 </Listbox.Button>
        //
        //                 <Transition
        //                     show={open}
        //                     as={Fragment}
        //                     leave="transition ease-in duration-100"
        //                     leaveFrom="opacity-100"
        //                     leaveTo="opacity-0"
        //                 >
        //                     <Listbox.Options
        //                         className="z-50 max-h-32 absolute mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        //                         {options.map((option) => (
        //                             <Listbox.Option
        //                                 key={option}
        //                                 className={({active}) =>
        //                                     classNames(
        //                                         active ? 'bg-indigo-600 text-white' : 'text-gray-900',
        //                                         'relative cursor-default select-none py-2 pl-3 pr-9'
        //                                     )
        //                                 }
        //                                 value={option}
        //                             >
        //                                 {({selected, active}) => (
        //                                     <>
        //                 <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
        //                   {option}
        //
        //                 </span>
        //
        //                                         {selected ? (
        //                                             <span
        //                                                 className={classNames(
        //                                                     active ? 'text-white' : 'text-indigo-600',
        //                                                     'absolute inset-y-0 right-0 flex items-center pr-4'
        //                                                 )}
        //                                             >
        //                     <CheckIcon className="h-5 w-5" aria-hidden="true"/>
        //                   </span>
        //                                         ) : null}
        //                                     </>
        //                                 )}
        //                             </Listbox.Option>
        //                         ))}
        //                     </Listbox.Options>
        //                 </Transition>
        //             </div>
        //         </>
        //     )}
        // </Listbox>

        <div>
            <input type="time" min="09:00" max="18:00" value={selected} onChange={editTime}
                   className={props.style +" " +"bg-transparent border-none relative w-full cursor-pointer rounded-md text-left text-gray-900 sm:text-sm sm:leading-6"}/>
        </div>
    )
}
