import { XMarkIcon } from '@heroicons/react/20/solid'
import Navbar from "./Navbar";
import {useState} from "react";

export default function DiscordBanner(props) {
    const { location } = props;

    const [disabled, setDisabled] = useState(localStorage.getItem("showDiscordBanner") !== null)

    // if (localStorage.getItem("showDiscordBanner") !== null){
    //     return null;
    // }
    if (location.pathname.match(/classroom/)){
        return null;
    }
    else if (location.pathname.match(/signup/)){
        return null;
    }
    else if (location.pathname.match(/login/)){
        return null;
    }

    else if (location.pathname.match(/calendar/)){
        return null;
    }

    else if (location.pathname.match(/makeappointment/)){
        return null;
    }

    else if (location.pathname.match(/room/)){
        return null;
    }

    else if (location.pathname.match(/\d+/)){
        return null;
    }

    const dismiss=()=>{
        localStorage.setItem("showDiscordBanner", "false");
        setDisabled(true)
    }

    if(!disabled){
        return (
            <div className="flex flex-row justify-center gap-x-6 bg-yellow-400 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                {/*<div className="flex flex-row justify-center gap-x-6 bg-yellow-400 px-6 py-2 sm:px-3.5">*/}
                <p className="text-sm leading-6 text-black">
                    <div className={"justify-center align-center flex gap-4"}>
                        <p className={"leading-9"}>Join to our community and get tips on how to get started with online tutoring.</p>
                        <button
                            type="button"
                            className="rounded border border-black bg-transparent px-4 py-2 text-sm font-semibold text-gray-940 hover:bg-yellow-200"
                        >
                            Join Discord
                        </button>
                    </div>
                </p>
                <div className="flex flex-1 justify-end">
                    <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                            onClick={dismiss}
                    >
                        <span className="sr-only">Dismiss</span>
                        <XMarkIcon className="h-5 w-5 text-black" aria-hidden="true" />
                    </button>
                </div>
            </div>
        )
    }

    return(<div></div>)

}