import React, {useContext, useEffect, useRef, useState} from 'react';
import UserContext from "../../auth/UserContext";
import moment from "moment";
import dataLoader from "../../api/DataLoader";
import BookingModalContent from "../lessonpage/BookingModalContent";
import BasicModal from "../../components/BasicModal";
import BasicButton from "../../components/BasicButton";
import globe from "../../images/globe.svg"
import arrow from "../../images/curly_arrow.svg"
import TableSkeleton from "./TableSkeleton";
import {useHistory} from "react-router";
import ShareCourseTimeline from "./ShareCourseTimeline";
import {Step, Steps, Wizard} from "react-albus";
import BasicSignup from "../signup/BasicSignup";
import ProfilePicturePicker from "../signup/ProfilePicturePicker";
import CreateCourse from "../signup/CreateCourse";
import WorkingHours from "../signup/WorkingHours";
import TeacherFinishPage from "../signup/TeacherFinishPage";
import CourseCard from "../../components/CourseCard";
import ShareCourseModal from "./ShareCourseModal";
import ProfilePicture from "../../components/ProfilePicture";
import {Helmet} from "react-helmet";
import CtaTable from "./CtaTable";
import TimezoneSelect from "react-timezone-select";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function AppointmentsPage(props) {
    const userContext = useContext(UserContext)
    let history = useHistory();

    const [bookings, setBookings] = useState([]);
    const [enhancedBookings, setEnhancedBookings] = useState([]);
    // const [profilePictures, setProfilePictures] = useState(new Map());
    const [loading, setLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [selectedToAccept, setSelectedToAccept] = useState(null);
    const [selectedToDecline, setSelectedToDecline] = useState(null);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showDeclineQuestionModal, setShowDeclineQuestionModal] = useState(false);
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [showShareCourseModal, setShowShareCourseModal] = useState(false);

    const [selectedTimezone, setSelectedTimezone] = useState(
        localStorage.getItem("selectedTimezone") !== null ?
            localStorage.getItem("selectedTimezone")
            :

            Intl.DateTimeFormat().resolvedOptions().timeZone
    )

    const isMounted = useRef(false);
    const isMounted2 = useRef(false);


    useEffect(() => {
        setLoading(true)
        if(userContext.userInfo?.id !== null){
            dataLoader.getNextAppointments(userContext.userInfo?.id, userContext.authInfo.accessToken).then(res =>
                    setBookings(res.data)
            )
        }
    }, [userContext.userInfo?.id]);

    useEffect(() => {
        if (isMounted2.current) {
            let enhancedBookingsLocal = [];

            if(bookings.length > 0){
                //TODO batch call
                for (let booking of bookings) {
                    dataLoader.getUserInfo(userContext.userInfo.id === booking.teacherId ? booking.studentId : booking.teacherId, userContext.authInfo.accessToken).then(res => {
                        setLoading(true)

                        // let timezone = localStorage.getItem("selectedTimezone")!==null? localStorage.getItem("selectedTimezone"): Intl.DateTimeFormat().resolvedOptions().timeZone

                        let eBooking = {
                            id: booking.id,
                            name: res.data.givenName,
                            teacherId: booking.teacherId,
                            studentId: booking.studentId,
                            connectionId: booking.connectionId,
                            title: booking.lessonTitle,
                            state: booking.state,
                            roomName: booking.roomName,
                            email: res.data.email,
                            date: moment(booking.startDate).tz(selectedTimezone).format("MMMM Do YYYY, HH:mm"),
                            familyName: res.data.familyName,
                            givenName: res.data.givenName,
                            // userId: userContext.userInfo.userType === "STUDENT" ? booking.teacherId : booking.studentId
                            userId: userContext.userInfo.id === booking.teacherId ? booking.studentId : booking.teacherId
                            // profilePicture: profilePictures.get(booking.studentId)
                        }

                        enhancedBookingsLocal.push(eBooking)

                        setLoading(false)


                    })
                }
                // let sortedBookings = enhancedBookings.sort(function(a,b){
                //     return b.date - a.date;
                // });
                // console.log(sortedBookings)

                // console.log(enhancedBookingsLocal)
                setEnhancedBookings(enhancedBookingsLocal)

            }
            else{
                setIsEmpty(true)
                setLoading(false)
            }

        } else {
            isMounted2.current = true;
        }

    }, [bookings, selectedTimezone]);


    useEffect(() => {
        if(selectedToAccept){
            acceptBooking(selectedToAccept);
        }
    }, [selectedToAccept]);

    useEffect(() => {
        if(selectedToDecline){
            setShowDeclineQuestionModal(true)
        }
    }, [selectedToDecline]);

    const joinClassroom = (roomName) => {
        history.push('/classroom/' + roomName)
    }

    const getBookingButtons = (booking) => {
        if ((booking.state === "APPROVED") && Math.abs(moment(booking.date, "MMMM Do YYYY, HH:mm").diff(moment(), 'minutes')) <= 60) {
            return <div className={"flex justify-end gap-5"}>
                <button
                    type="button"
                    onClick={()=>joinClassroom(booking.roomName)}
                    className="inline-flex items-center gap-x-1.5 rounded-full bg-secondary px-5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-secondary-alt focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                    </svg>
                    Join
                </button>
            </div>
        }
        else if (booking.state === "WAITING_FOR_APPROVAL" && userContext.userInfo.id === booking.teacherId) {
            return <div className={"flex justify-end gap-5"}>
                <span
                    className="text-secondary hover:text-secondary-alt hover:cursor-pointer font-normal"
                    onClick={(e)=> setSelectedToDecline(booking)}
                >Decline</span>
                <span className="text-secondary hover:text-secondary-alt hover:cursor-pointer font-normal"
                      onClick={(e)=>{setSelectedToAccept(booking)}}
                >
                    Accept</span>
            </div>
        }


        return <div className={"flex justify-end gap-5"}></div>

    }

    const acceptBooking = (booking) => {
        let modifiedBookings = enhancedBookings;
        modifiedBookings.find(old => old.id === booking.id).state = "APPROVED"
        dataLoader.approveAppointment(booking.id, userContext.authInfo.accessToken).then(
            setEnhancedBookings(modifiedBookings)
        )

        setShowApproveModal(true)
    }

    const declineBooking = (booking) => {
        setShowDeclineQuestionModal(false)
        let modifiedBookings = enhancedBookings.filter(old => old.id !== booking.id)
        dataLoader.cancelAppointment(booking.id, userContext.authInfo.accessToken).then(
            setEnhancedBookings(modifiedBookings)
        )
        setShowDeclineModal(true)
    }

    const selectTimezone = (timezone) => {
        setSelectedTimezone(timezone)
        localStorage.setItem("selectedTimezone", timezone)
    }

    return (

        <div className={"h-screen bg-background"}>
            <Helmet>
                <title>Appointments - Abakusz</title>
            </Helmet>
            <div className="pt-6 mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="border-b border-gray-200 pb-5 flex justify-between">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 py-1.5 ">
                        Appointments
                    </h3>
                    {userContext?.userInfo?.userType === "TEACHER" ?
                        <div>{
                            enhancedBookings.length > 0 ?
                                <button
                                    type="button"
                                    onClick={()=> setShowShareCourseModal(true)}
                                    className="inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm text-secondary hover:text-secondary-alt"
                                >
                                    Share your course
                                </button>
                                :
                                // <button
                                //     type="button"
                                //     onClick={()=> setShowShareCourseModal(true)}
                                //     // className="inline-flex items-center gap-x-2 rounded-md bg-secondary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-secondary-alt focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                //     className="inline-flex items-center gap-x-1  rounded-md border border-transparent bg-secondary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-alt"
                                //
                                // >
                                //     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                //         <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75" />
                                //     </svg>
                                //
                                //     Share your course
                                // </button>
                                <div></div>
                        }</div>
                        :
                        <div></div>
                    }


                </div>

                <div className={"mt-6 w-96"}>
                    <TimezoneSelect
                        value={selectedTimezone}
                        onChange={(e) => selectTimezone(e.value)}
                    />
                </div>

                {/*<div className="w-full mx-1 grid gap-4 md:grid-cols-2 lg:grid-cols-4 mt-4">*/}
                    {/*<div className="rounded-md border bg-card text-card-foreground">*/}
                    {/*    <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">*/}
                    {/*        <h3*/}
                    {/*        className="tracking-tight text-sm font-medium">Action Required</h3>*/}
                    {/*        /!*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"*!/*/}
                    {/*        /!*     stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"*!/*/}
                    {/*        /!*     className="h-4 w-4 text-muted-foreground">*!/*/}
                    {/*        /!*    <path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>*!/*/}
                    {/*        /!*</svg>*!/*/}
                    {/*    </div>*/}
                    {/*    <div className="p-6 pt-0">*/}
                    {/*        <div className="text-2xl font-bold">4</div>*/}
                    {/*        <p className="text-xs text-muted-foreground">You have pending bookings</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="rounded-xl border bg-card text-card-foreground shadow">*/}
                    {/*    <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2"><h3*/}
                    {/*        className="tracking-tight text-sm font-medium">Completed Sessions</h3>*/}
                    {/*        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"*/}
                    {/*             stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"*/}
                    {/*             className="h-4 w-4 text-muted-foreground">*/}
                    {/*            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>*/}
                    {/*            <circle cx="9" cy="7" r="4"></circle>*/}
                    {/*            <path d="M22 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75"></path>*/}
                    {/*        </svg>*/}
                    {/*    </div>*/}
                    {/*    <div className="p-6 pt-0">*/}
                    {/*        <div className="text-2xl font-bold">+0</div>*/}
                    {/*        <p className="text-xs text-muted-foreground">+180.1% from last month</p></div>*/}
                    {/*</div>*/}
                    {/*<div className="rounded-xl border bg-card text-card-foreground shadow">*/}
                    {/*    <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2"><h3*/}
                    {/*        className="tracking-tight text-sm font-medium">Booked Sessions</h3>*/}
                    {/*        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"*/}
                    {/*             stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"*/}
                    {/*             className="h-4 w-4 text-muted-foreground">*/}
                    {/*            <rect width="20" height="14" x="2" y="5" rx="2"></rect>*/}
                    {/*            <path d="M2 10h20"></path>*/}
                    {/*        </svg>*/}
                    {/*    </div>*/}
                    {/*    <div className="p-6 pt-0">*/}
                    {/*        <div className="text-2xl font-bold">+1</div>*/}
                    {/*        <p className="text-xs text-muted-foreground">+19% from last month</p></div>*/}
                    {/*</div>*/}
                    {/*<div className="rounded-xl border bg-card text-card-foreground shadow">*/}
                    {/*    <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2"><h3*/}
                    {/*        className="tracking-tight text-sm font-medium">Page Views</h3>*/}
                    {/*        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"*/}
                    {/*             stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"*/}
                    {/*             className="h-4 w-4 text-muted-foreground">*/}
                    {/*            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>*/}
                    {/*        </svg>*/}
                    {/*    </div>*/}
                    {/*    <div className="p-6 pt-0">*/}
                    {/*        <div className="text-2xl font-bold">+573</div>*/}
                    {/*        <p className="text-xs text-muted-foreground">+201 since last month</p></div>*/}
                    {/*</div>*/}
                {/*</div>*/}

                <div className="mt-4 flex flex-col">
                    <div className={classNames(isEmpty?"overflow-x-none":"overflow-x-auto","-my-2 -mx-4  sm:-mx-6 lg:-mx-8")}>
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div>
                                {
                                    loading ?
                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            <TableSkeleton></TableSkeleton>
                                        </div>
                                        :

                                        <div>
                                            {isEmpty ?
                                                <div>

                                                {userContext?.userInfo?.userType === "TEACHER"?
                                                    <div>
                                                        {/*No appointments yet*/}
                                                        {/*<div className={"flex justify-between relative"}>*/}
                                                        {/*    <img src={globe} alt="Globe"/>*/}
                                                        {/*    <div className={"text-4xl right-20 opacity-40 absolute top-64"}>"Hmm... that blue button must be important..."</div>*/}
                                                        {/*    <img src={arrow} alt="Arrow" className={"absolute right-[-50px] top-[-50px]"}/>*/}
                                                        {/*</div>*/}
                                                        <CtaTable showShareModal={()=> setShowShareCourseModal(true)}></CtaTable>
                                                    </div>
                                                    :
                                                    <div>You don't have any bookings yet.</div>
                                                }
                                                </div>


                                                :
                                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 lg:pl-8 py-3.5 pl-4 pr-3 text-left text-xs font-normal text-gray-900">
                                                            {userContext?.userInfo?.userType === "TEACHER" ? "STUDENT NAME" : "TEACHER NAME"}
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 hidden md:table-cell px-3 py-3.5 text-left text-xs font-normal text-gray-900">

                                                            COURSE
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 hidden sm:table-cell px-3 py-3.5 text-left text-xs font-normal text-gray-900">

                                                            DATE
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 py-3.5 px-3 text-left text-xs font-normal text-gray-900">
                                                            STATUS
                                                        </th>

                                                        <th scope="col"
                                                            className="sticky top-0 z-10 lg:pr-8  py-3.5 pl-3 pr-4 sm:pr-6">
                                                            <span className="sr-only">Edit</span>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                    {enhancedBookings.map((person, personIdx) => (
                                                        <tr key={Math.random()}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 relative w-72">
                                                                <div className="flex items-center">
                                                                    {/*<div className="h-10 w-10 flex-shrink-0">*/}
                                                                    {/*    <img className="h-10 w-10 rounded-full"*/}
                                                                    {/*         src={person.profilePicture} alt=""/>*/}
                                                                    {/*</div>*/}
                                                                    <ProfilePicture userId={person.userId} name={person.name} height={"h-10 w-10"}></ProfilePicture>
                                                                    <div className="ml-4">
                                                                        <div
                                                                            className="font-medium text-gray-900">{person.name}</div>
                                                                        <div
                                                                            className="text-gray-500 hidden sm:table-cell truncate">{person.email}</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-ellipsis overflow-hidden px-3 py-4 text-sm text-gray-500 hidden md:table-cell relative max-w-4/12">
                                                                <div className="text-gray-900">{person.title}</div>
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                                                                {person.date}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {person.state === "APPROVED" ?
                                                                    <div>
                                                                        {Math.abs(moment(person.date, "MMMM Do YYYY, HH:mm").diff(moment(), 'minutes')) <= 60 ?
                                                                            <span
                                                                                className="inline-flex rounded-full bg-gray-200 px-2 text-xs font-semibold leading-5 text-black">
                                                                        Starting
                                                                    </span>
                                                                            :
                                                                            <span
                                                                                className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                                        Accepted
                                                                    </span>
                                                                        }
                                                                    </div>

                                                                    :
                                                                    <span
                                                                        className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                                                Pending
                                                            </span>

                                                                }

                                                            </td>
                                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 w-48">

                                                                {getBookingButtons(person)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                                </div>
                                            }
                                        </div>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BasicModal show={showApproveModal} closeModal={() => {}}>
                <div className={"flex flex-col items-center gap-4"}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#4ade80" className="w-20 h-20">
                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                    </svg>
                    <h3 className={"text-xl"}>Booking accepted</h3>
                    <p className={"text-center leading-relaxed text-slate-500"}>You successfully accepted this booking request and your student has been notified.</p>
                    <BasicButton text="Done" onClick={()=>setShowApproveModal(false)}></BasicButton>
                </div>
            </BasicModal>
            <BasicModal show={showDeclineQuestionModal} closeModal={() => {}}>
                {/*<div className={"flex flex-col items-center gap-4"}>*/}
                {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#dc2626" className="w-20 h-20">*/}
                {/*        <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />*/}
                {/*    </svg>*/}

                {/*    <h3 className={"text-xl"}>Decline booking</h3>*/}
                {/*    <p className={"text-center leading-relaxed text-slate-500"}>Are you sure you want to decline this booking? This action cannot be undone.</p>*/}
                {/*    <button*/}
                {/*        type="button"*/}
                {/*        className="w-full rounded-md bg-red-600 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"*/}
                {/*        onClick={()=> declineBooking(selectedToDecline)}*/}
                {/*    >*/}
                {/*        Decline*/}
                {/*    </button>*/}
                {/*    <button*/}
                {/*        type="button"*/}
                {/*        className="w-full rounded-md bg-white py-2.5 px-3.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"*/}
                {/*        onClick={()=> setShowDeclineQuestionModal(false)}*/}
                {/*    >*/}
                {/*        Cancel*/}
                {/*    </button>*/}
                {/*</div>*/}


                <div className={"flex flex-col gap-2"}>
                    <h1 className={"font-semibold text-lg"}>Are you sure that you want to decline this booking?</h1>
                    <p>An email regarding your decision will be sent to the individual who made the booking.</p>
                    <div className={"mt-2 p-4 bg-orange-100 rounded-lg text-red-700"}>
                        <div className={"flex gap-2 font-semibold"}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                            </svg>

                            <p>Warning</p>
                        </div>
                        <p className={"text-sm mt-2"}>You will not be able to accept this booking later.</p>
                    </div>


                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                            onClick={()=> declineBooking(selectedToDecline)}
                        >
                            Yes, decline booking
                        </button>
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={()=> setShowDeclineQuestionModal(false)}
                            // ref={cancelButtonRef}
                        >
                            No
                        </button>
                    </div>
                </div>


            </BasicModal>
            <BasicModal show={showDeclineModal} closeModal={() => {}}>
                <div className={"flex flex-col items-center gap-4"}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#4ade80" className="w-20 h-20">
                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                    </svg>
                    <h3 className={"text-xl"}>Booking declined</h3>
                    <p className={"text-center leading-relaxed text-slate-500"}>You declined this booking request and your student has been notified.</p>
                    <BasicButton text="Done" onClick={()=>setShowDeclineModal(false)}></BasicButton>
                </div>
            </BasicModal>
            <ShareCourseModal show={showShareCourseModal} setShow={()=>setShowShareCourseModal(false)}></ShareCourseModal>
        </div>

    );
}

export default AppointmentsPage;