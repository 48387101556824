import React, {useContext, useState} from 'react';
import Avatar from "react-avatar-edit";
import dataLoader from "../../api/DataLoader";
import UserContext from "../../auth/UserContext";
import {TailSpin} from 'react-loader-spinner'

function ProfilePicturePicker(props) {
    const [avatar, setAvatar] = useState(null);
    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(false);

    const userContext = useContext(UserContext)


    const onClose = () => {
        setPreview(null)
    }

    const onCrop = (previewImg) => {
        setPreview(previewImg)
    }

    const saveProfilePicture = (profilePicture) => {
        if(preview){
            setLoading(true)
            dataLoader.saveProfilePicture(userContext.userInfo.id, ".png", userContext.authInfo.accessToken)
                .then(res => dataLoader.uploadFileToAWS(res.data, profilePicture,null))
                .then(res => userContext.updateProfilePicture(profilePicture))
                .then(res => props.next())
        }
    }



    return (
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-4 px-4 sm:rounded-lg sm:px-10">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <img
                            className="mx-auto h-12 w-auto"
                            src={process.env.PUBLIC_URL + '/primary-logo.svg'} alt="logo"
                        />
                    </div>
                    <p className="mt-4 text-2xl font-bold flex justify-center">First impressions...</p>
                    <p className="mt-4 text-sm text-gray-500 flex justify-center">First impression is really important! Pick a profile picture so your students can recognize you.</p>
                    <div className={"flex py-5 min-h-full justify-center"}>
                        <Avatar
                            width={250}
                            height={250}
                            exportMimeType={"png"}
                            exportSize={500}
                            cropRadius={100}
                            label={"Pick a profile picture"}
                            onCrop={onCrop}
                            onClose={onClose}
                            src={avatar}
                        />
                    </div>

                    <div className={"flex flex-col gap-2 justify-center w-full"}>
                        <button
                            type="submit"
                            className={
                                preview ?
                                    "disabled:bg-blue-300 flex w-full justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary-alt hover:cursor-pointer focus:outline-none"
                            :
                                    "disabled:bg-blue-300 flex w-full justify-center rounded-md border border-transparent bg-inactive py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none hover:cursor-default"
                            }
                            onClick={()=>saveProfilePicture(preview)}
                            disabled = {(preview === null) || loading}
                        >
                            {loading ?
                                <TailSpin
                                    height="20"
                                    width="20"
                                    color="white"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                :
                                "Next"
                            }
                        </button>

                        <div className={"flex justify-center"}>
                            <button className={"text-sm hover:text-gray-400"} onClick={()=>props.next()}>Skip</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ProfilePicturePicker;