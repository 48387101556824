import React, {Component} from 'react';

class TableSkeleton extends Component {
    render() {
        return (

            <div role="status"
                 className="w-full p-4 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-400 md:p-6 dark:border-gray-400">
                <div className="flex items-center justify-between">
                    <div className={"flex gap-5 "}>
                        <div className="h-10 bg-gray-300 rounded-full dark:bg-gray-300 w-10"></div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-200 w-24 my-2"></div>
                            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                        </div>

                    </div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-12"></div>
                </div>
                <div className="flex items-center justify-between pt-4">
                    <div className={"flex gap-5 "}>
                        <div className="h-10 bg-gray-300 rounded-full dark:bg-gray-300 w-10"></div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-200 w-24 my-2"></div>
                            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                        </div>

                    </div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-12"></div>
                </div>
                <div className="flex items-center justify-between pt-4">
                    <div className={"flex gap-5 "}>
                        <div className="h-10 bg-gray-300 rounded-full dark:bg-gray-300 w-10"></div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-200 w-24 my-2"></div>
                            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                        </div>

                    </div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-12"></div>
                </div>
                <div className="flex items-center justify-between pt-4">
                    <div className={"flex gap-5 "}>
                        <div className="h-10 bg-gray-300 rounded-full dark:bg-gray-300 w-10"></div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-200 w-24 my-2"></div>
                            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                        </div>

                    </div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-12"></div>
                </div>
                <div className="flex items-center justify-between pt-4">
                    <div className={"flex gap-5 "}>
                        <div className="h-10 bg-gray-300 rounded-full dark:bg-gray-300 w-10"></div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-200 w-24 my-2"></div>
                            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                        </div>

                    </div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-12"></div>
                </div>
                <span className="sr-only">Loading...</span>
            </div>

        );
    }
}

export default TableSkeleton;