import UserContext from './UserContext';
import {useEffect, useRef, useState} from "react";
import auth from "./base";
import {createUserWithEmailAndPassword, signOut, onAuthStateChanged} from "firebase/auth";
import axios from "axios";
import {FullPageLoading} from "../FullPageLoading";
import dataLoader from "../api/DataLoader";

export const AuthProvider = ({ children }) => {
    const SERVICE_URL = "https://api.abakusz.io";
    // const SERVICE_URL = "http://localhost:8080";


    const [authInfo, setAuthInfo] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [profilePicture, setProfilePicture] = useState(null);
    const [isTeacher, setIsTeacher] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [authErrorCode, setAuthErrorCode] = useState(null);
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(false);

    const getUserInfo = (authId, accessToken) => {
        return axios.get(SERVICE_URL + '/api/getuserinfo/'+ authId,{withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    const createUserInfo = (firstname, surname, email, authId, userType, accessToken) => {
        return axios.post(SERVICE_URL + "/api/signup", {authId: authId, givenName: firstname, familyName: surname, email: email, userType: userType}, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }


    useEffect(() => {
        setLoading(true);
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            console.log("refresh")
            setAuthInfo(currentUser);
            if(currentUser){
                getUserInfo(currentUser.uid, currentUser.accessToken)
                    .then((res) => setUserInfo(res.data))

                setInterval(() => {
                    auth.currentUser.getIdToken().then((res) =>
                        setAuthInfo({...authInfo, accessToken: res})
                    )
                }, 60000)

                console.log("good")

                setIsAuthenticated(true)
            }

            else{
                console.log("bad")
                setIsAuthenticated(false)
                setLoading(false)
            }
        });

        // auth.onIdTokenChanged((currentUser) => {
        //     setAuthInfo(currentUser);
        //
        //     console.log("changed")
        //     console.log(currentUser)
        // });

        return unsubscribe;
    }, []);

    useEffect(() => {
        if(isMounted.current){
            dataLoader.getProfilePicture(userInfo.id, userInfo.givenName).then((res => setProfilePicture(res.data)))
                .catch((error) => {
                    setProfilePicture(`https://avatars.dicebear.com/api/initials/:${userInfo.givenName} ${userInfo.familyName}.svg`)
                    // setProfilePicture(`https://api.dicebear.com/6.x/initials/svg?seed=${userInfo.givenName} ${userInfo.familyName}.svg`)
            });
            setIsTeacher(userInfo.userType === "TEACHER")
            setLoading(false)
        }
        else {
            isMounted.current = true;
        }

    }, [userInfo]);

    const signUp = (email, password, firstname, surname, userType) =>{
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                // console.log(user)
                return createUserInfo(firstname, surname, email, user.uid, userType, user.accessToken).then(res => setUserInfo(res.data))
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setAuthErrorCode(errorMessage)
            });
    }

    const logout = () =>{
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    }

    const updateProfilePicture = (profilePicture) =>{
        setProfilePicture(profilePicture)
    }

    // const getAccessToken = () =>{
    //     return auth.currentUser.getIdToken()
    // }
    //
    // const authenticatedCall = (_callback) =>{
    //     auth.currentUser.getIdToken().then(res =>
    //         _callback(res)
    //     )
    // }


    return (
        <UserContext.Provider
            value={{
                authInfo,
                userInfo,
                profilePicture,
                isTeacher,
                isAuthenticated,
                authErrorCode,
                updateProfilePicture,
                // getAccessToken,
                // authenticatedCall,
                signUp,
                logout
            }}
        >
            {loading
                ?
                <FullPageLoading></FullPageLoading>
                :
                children
            }
        </UserContext.Provider>
    );
};