import React, {useCallback, useContext, useEffect, useState} from 'react';
import {BanknotesIcon} from "@heroicons/react/20/solid";
import UserContext from "../../auth/UserContext";
import dataLoader from "../../api/DataLoader";
import Hashids from "hashids";
import CustomCurrencyInput from "./CustomCurrencyInput";
import {onAuthStateChanged} from "firebase/auth";
import auth from "../../auth/base";
import ProfilePicture from "../../components/ProfilePicture";

function CreateCourse(props) {
    const userContext = useContext(UserContext)
    const [loading, setLoading] = useState(false);
    const [titleValid, setTitleValid] = useState(false);
    const [price, setPrice] = useState();
    const [currency, setCurrency] = useState("EUR");

    const getLessonId=()=>{
        let hashids = new Hashids("life is very short and anxious for those who forget the past, neglect the present, and fear the future", 7);
        return hashids.encode(Math.floor(Math.random() * (10000 - 1) + 1));
    }

    const handleSubmit = useCallback(async event => {
        event.preventDefault();
        setLoading(true)

        const { title, description, freelesson } = event.target.elements;
        let lessonId=getLessonId();
        const newLessonCard = {userId: userContext.userInfo.id, lessonId: lessonId, displayName: userContext.userInfo.givenName + " " + userContext.userInfo.familyName, title: title.value, description: description.value, price: parseFloat(price), currency: currency, freeLesson:freelesson.checked, isActive: true};
        dataLoader.saveLessonCard(newLessonCard, userContext.authInfo.accessToken)
            .then(r => props.setCourse(r.data))
            .then(r => props.next())
    }, [price, currency]);

    const handleChange = useCallback(async event => {
        event.preventDefault();
        setTitleValid(event.target.value.length > 0)
    }, []);


    return (
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-4 px-4 sm:rounded-lg sm:px-10">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <img
                            className="mx-auto h-12 w-auto"
                            src={process.env.PUBLIC_URL + '/primary-logo.svg'} alt="logo"
                        />
                    </div>
                    <p className="mt-4 mb-4 text-xl font-bold flex justify-center">Your first course</p>
                    <p className="mt-4 mb-4 text-sm text-gray-500 flex justify-center">Give us a few details about your course. Don't worry, you will be able to edit these things anytime.</p>
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div className={"flex flex-row gap-4"}>
                                {/*<img*/}
                                {/*    className="mt-1 inline-block h-16 w-16 rounded-full"*/}
                                {/*    src={userContext.profilePicture}*/}
                                {/*    alt=""*/}
                                {/*/>*/}

                            <ProfilePicture userId={userContext.userInfo.id} name={userContext.userInfo.givenName} height={"h-16 w-16"}></ProfilePicture>




                            <div className={"text-xs font-medium lg:text-base w-full"}>
                                <h3>{userContext.userInfo.givenName + " " + userContext.userInfo.familyName}</h3>
                                {/*<label htmlFor="title" className="sr-only">*/}
                                {/*    Email*/}
                                {/*</label>*/}
                                <input
                                    onChange={handleChange}
                                    autoFocus
                                    type="text"
                                    name="title"
                                    id="title"
                                    className="my-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                    placeholder="Title of your first course"
                                />
                            </div>
                        </div>
                        <div className={"flex py-1 min-h-full justify-center"}>
                            <textarea
                                rows={8}
                                name="description"
                                id="description"
                                className="resize-none block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                placeholder="Tell us about your first course..."
                                defaultValue={''}
                            />
                        </div>

                        <div>
                            {/*<label htmlFor="price" className="block text-sm font-medium text-gray-700">*/}
                            {/*    Price / hour*/}
                            {/*</label>*/}
                            <div className="relative mt-1 rounded-md shadow-sm">
                                {/*<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">*/}
                                {/*    <BanknotesIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                                {/*</div>*/}
                                {/*<input*/}
                                {/*    type="number"*/}
                                {/*    name="price"*/}
                                {/*    min="1"*/}
                                {/*    step="any"*/}
                                {/*    id="price"*/}
                                {/*    className="block w-full rounded-md border-gray-300 pl-10 pr-12 focus:border-secondary focus:ring-secondary sm:text-sm"*/}
                                {/*    placeholder="0.00"*/}
                                {/*/>*/}
                                {/*<div className="absolute inset-y-0 right-0 flex items-center">*/}
                                {/*    <label htmlFor="currency" className="sr-only">*/}
                                {/*        Currency*/}
                                {/*    </label>*/}
                                {/*    <select*/}
                                {/*        id="currency"*/}
                                {/*        name="currency"*/}
                                {/*        className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-secondary focus:ring-secondary sm:text-sm"*/}
                                {/*    >*/}
                                {/*        <option>USD</option>*/}
                                {/*        <option>HUF</option>*/}
                                {/*        <option>CAD</option>*/}
                                {/*        <option>EUR</option>*/}
                                {/*    </select>*/}
                                {/*</div>*/}

                                <CustomCurrencyInput setPrice={(price)=>setPrice(price)} setCurrency={(currency)=>setCurrency(currency)}></CustomCurrencyInput>
                                {/*<CustomCurrencyInput></CustomCurrencyInput>*/}


                            </div>
                        </div>

                        <div className="flex items-center py-1">
                            <input
                                id="freelesson"
                                name="freelesson"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-secondary focus:ring-0 cursor-pointer"
                            />
                            <label htmlFor="freelesson" className="ml-2 block text-sm text-gray-900 cursor-pointer">
                                The first, introductory lesson is free
                            </label>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={!titleValid}
                                className={
                                    titleValid?
                                        "flex w-full justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary-alt focus:outline-none cursor-pointer"
                                        :
                                        "flex w-full justify-center rounded-md border border-transparent bg-inactive py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none"
                                }
                            >
                                Next
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
}

export default CreateCourse;