import UserContext from "../../auth/UserContext";
import React, {useCallback, useContext, useState} from "react";
import moment from "moment";
import {ShieldCheckIcon} from "@heroicons/react/24/outline";
import {TailSpin} from "react-loader-spinner";
import {FullPageLoading} from "../../FullPageLoading";
import ProfilePicture from "../../components/ProfilePicture";


export default function StudentSummary(props){
    const userContext = useContext(UserContext)

    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailAlreadyUsedError, setEmailAlreadyUsedError] = useState(false);
    const [givenNameError, setGivenNameError] = useState(false);
    const [familyNameError, setFamilyNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);


    // const processBooking=()=>{
    //     props.bookAppointment()
    // }
    const delay = ms => new Promise(res => setTimeout(res, ms));

    const handleSignup = useCallback(async event  => {
        event.preventDefault();
        setLoading(true);
        const { email, password, givenname, familyname } = event.target.elements;
        if(validate({ email, password, givenname, familyname })){
            userContext.signUp(email.value, password.value, givenname.value, familyname.value, "STUDENT");
            // auth/email-already-in-use
            // props.bookAppointment()
            await delay(5000);
            setLoading(false);
        }
    }, []);

    const processBooking = () => {
        props.bookAppointment();
    }

    const validate = (elements) => {
        let pass = true;
        if(elements.email.value.length === 0){
            setEmailError(true);
            pass=false;
        }
        if(elements.givenname.value.length === 0){
            setGivenNameError(true);
            pass=false;
        }
        if(elements.familyname.value.length === 0){
            setFamilyNameError(true);
            pass=false;
        }
        if(elements.password.value.length === 0){
            setPasswordError(true);
            pass=false;
        }

        return pass;
    }

    return(
        <div className={"mt-10 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center"}>
            <div className="mt-10 lg:mt-0">
                <div className={"flex justify-between items-center"}>
                    <h2 className="text-lg font-medium text-gray-900">Session summary</h2>
                    <div className={"text-sm cursor-pointer text-secondary flex gap-1"} onClick={()=>props.back()}>
                        Back to calendar
                    </div>
                </div>

                <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
                    <dl className="space-y-6 border-t border-gray-200 py-6 px-4 sm:px-6">
                        {loading?
                            <div className={"bg-white w-screen h-screen absolute top-0 left-0 z-50"}>
                                <FullPageLoading></FullPageLoading>
                            </div>
                        :
                           <div>{userContext.isAuthenticated ?
                               <div className="space-y-6 py-4 px-4 sm:px-6">

                                   <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
                                       <div className="sm:flex sm:items-start">
                                <span className="relative inline-block">
                                {/*<img*/}
                                {/*    className="h-12 w-12 rounded-full"*/}
                                {/*    src={userContext?.profilePicture}*/}
                                {/*    alt=""*/}
                                {/*/>*/}
                                <ProfilePicture height={"h-12"} userId={userContext?.userInfo?.id} name={userContext?.userInfo?.givenName + " " + userContext?.userInfo?.familyName}></ProfilePicture>
                                <span className="absolute top-0 right-0 block h-3 w-3 rounded-full bg-green-400 ring-2 ring-white" />
                              </span>
                                           <div className="mt-3 sm:mt-0 sm:ml-4">
                                               <div className="text-sm font-medium text-gray-900">
                                                   {userContext?.userInfo?.givenName + " " + userContext?.userInfo?.familyName}
                                               </div>
                                               <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                                                   <div>{userContext?.authInfo?.email}</div>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                                   <div className={"flex gap-2"}>
                                       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                           <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                       </svg>
                                       <ul role="list" className="px-6 list-disc text-slate-500 w-96">
                                           <li className="text-sm">Please take care of the necessary payments as you agreed with your tutor</li>
                                           <li className="text-sm">In case you need to modify your booking, please notify the tutor as soon as possible</li>
                                       </ul>
                                   </div>

                                   <div className={"text-slate-500 text-sm"}>
                                       Once you've made a booking, your tutor will be promptly notified. They'll then have the option to accept or decline the booking, and we'll keep you informed via email about their decision.
                                   </div>
                               </div>


                               :


                               <div className={"space-y-6 py-3 px-4"}>
                                   <div>
                                       <div className={"flex items-center gap-3 rounded-md bg-gray-50 px-3 py-2"}>
                                           <img
                                               className="h-8 w-auto"
                                               src={process.env.PUBLIC_URL + '/primary-logo.svg'} alt="logo"
                                           />
                                           <p className={"text-sm"}>
                                               <span>Please sign-up here to book your session or </span>
                                               <a href="/" className={"underline text-secondary"}>login</a></p>
                                       </div>
                                   </div>

                                   <form id={"signup-form"} className="space-y-6" onSubmit={handleSignup}>
                                       <div>
                                           <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                               Email address
                                           </label>
                                           <div className="mt-1">
                                               <input
                                                   id="email"
                                                   name="email"
                                                   type="email"
                                                   autoComplete="email"
                                                   className=
                                                       {emailError ? "block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                                           :
                                                           "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-indigo-500 sm:text-sm"

                                                       }
                                               />
                                           </div>
                                           {emailError ?
                                               <p className="mt-2 text-sm text-red-600" id="email-error">
                                                   {
                                                       emailAlreadyUsedError ?
                                                           "This email address is already in use"
                                                           :
                                                           "Please fill out this field."
                                                   }
                                               </p>
                                               :
                                               ""
                                           }

                                       </div>

                                       <div className={"flex flex-row gap-4"}>
                                           <div className={"flex-1"}>
                                               <label htmlFor="given-name" className="block text-sm font-medium text-gray-700">
                                                   Given Name
                                               </label>
                                               <div className="mt-1">
                                                   <input
                                                       id="givenname"
                                                       name="given-name"
                                                       type="given-name"
                                                       autoComplete="given-name"
                                                       className=
                                                           {givenNameError ?
                                                               "block w-full appearance-none rounded-md border border-red-300 px-3 py-2 placeholder-red-300 shadow-sm focus:border-red-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"

                                                               :
                                                               "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                           }
                                                   />
                                               </div>
                                               {givenNameError ?
                                                   <p className="mt-2 text-sm text-red-600" id="email-error">
                                                       Please fill out this field.
                                                   </p>
                                                   :
                                                   ""
                                               }
                                           </div>
                                           <div className={"flex-1"}>
                                               <label htmlFor="family-name" className="block text-sm font-medium text-gray-700">
                                                   Family Name
                                               </label>
                                               <div className="mt-1">
                                                   <input
                                                       id="familyname"
                                                       name="family-name"
                                                       type="family-name"
                                                       autoComplete="family-name"
                                                       className=
                                                           {familyNameError ?
                                                               "block w-full appearance-none rounded-md border border-red-300 px-3 py-2 placeholder-red-300 shadow-sm focus:border-red-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                               :
                                                               "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-indigo-500 sm:text-sm"

                                                           }
                                                   />
                                               </div>
                                               {familyNameError ?
                                                   <p className="mt-2 text-sm text-red-600" id="email-error">
                                                       Please fill out this field.
                                                   </p>
                                                   :
                                                   ""
                                               }
                                           </div>
                                       </div>


                                       <div>
                                           <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                               Password
                                           </label>
                                           <div className="mt-1">
                                               <input
                                                   id="password"
                                                   name="password"
                                                   type="password"
                                                   autoComplete="current-password"
                                                   className=
                                                       {passwordError ?
                                                           "block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                                           :
                                                           "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                       }
                                               />
                                           </div>
                                           {passwordError ?
                                               <p className="mt-2 text-sm text-red-600" id="email-error">
                                                   Please fill out this field.
                                               </p>
                                               :
                                               ""
                                           }
                                       </div>
                                   </form>
                               </div>
                           }</div>
                        }


                        <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                            <dt className="text-base font-medium flex flex-row gap-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                Time
                            </dt>
                            <div>
                                <span className="text-sm text-gray-900">{moment(props.bookingTime).format('MMMM Do, ')}</span>
                                <span className="text-xl font-medium text-gray-900">{moment(props.bookingTime).format('HH:mm')}</span>
                            </div>
                        </div>
                    </dl>

                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                        {userContext.isAuthenticated ?
                            <button
                                onClick={(e)=>processBooking(e)}
                                className="w-full rounded-md border border-transparent bg-secondary py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-secondary-alt"
                            >
                                Confirm booking
                            </button>
                            :
                            <button
                                form={"signup-form"}
                                type="submit"
                                className="w-full rounded-md border border-transparent bg-secondary py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-secondary-alt focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                            >
                                {loading ?
                                    <TailSpin
                                        height="20"
                                        width="20"
                                        color="white"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                    :
                                    "Confirm booking"
                                }
                            </button>
                        }
                        <div className="mt-6 text-center">
                            <a href={"https://www.craft.me/s/IMdzEh8ikRiyPv"} target={"_blank"} className="group inline-flex text-base font-medium">
                                <ShieldCheckIcon
                                    className="mr-2 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                />
                                <span className="text-gray-500 hover:text-gray-700">Protected by Abakusz</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}