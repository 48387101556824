import React, {useState} from 'react';
// import videochat from "./images/videochat-full.png";
import videochat from "./images/mock.png";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import FreeClassroom from "./FreeClassroom";
import {useHistory} from "react-router";
import dataLoader from "../../api/DataLoader";
import {TailSpin} from "react-loader-spinner";

function FreeClassroomLanding() {
    const history = useHistory();
    // const [startRoom, setStartRoom] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [roomURL, setRoomURL] = useState();

    function createRoom() {
        setLoading(true)
        dataLoader.createFreeClassroom().then(res =>
            // window.location.href = res.data
            // setRoomURL(res.data.roomURL)
            // console.log(res.data.roomName)
            history.push("/videochat/"+res.data.roomName)

        )
    }
    return (
        <div className="relative isolate overflow-hidden bg-white">
            <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
                    <img
                        className="h-11"
                        src={process.env.PUBLIC_URL + '/primary-logo.svg'}
                        alt="Your Company"
                    />
                    <div className="mt-24 sm:mt-32 lg:mt-16">
                        <a href="#" className="inline-flex space-x-6">
                            <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                <span>Learn more</span>
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
                        </a>
                    </div>
                    <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                        Free videochat for education
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Experience a new dimension of online education with our videochat feature for free. Connect with students in real time, fostering engaging, face-to-face interactions from the comfort of your own space.

                    </p>
                    <div className="mt-10 flex items-center gap-x-6">
                        {loading ?
                            <a
                                className="animate-pulse flex gap-3 rounded-md bg-secondary-alt px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-secondary-alt focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                <TailSpin
                                    height="20"
                                    width="20"
                                    color="white"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                <p>
                                    Creating your room...
                                </p>
                            </a>
                            :
                            <a
                                onClick={()=>createRoom()}
                                className="hover:cursor-pointer rounded-md bg-secondary px-8 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-secondary-alt focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Start your videochat
                            </a>
                        }
                    </div>
                </div>
                <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                    <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                        <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                            <img
                                src={videochat}
                                alt="App screenshot"
                                width={2432}
                                height={1442}
                                className="w-[76rem] rounded-md shadow-2xl ring-1 ring-gray-900/10"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default FreeClassroomLanding;