import {useEffect, useState} from 'react'
import { Switch } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TimeformatToggle(props) {
    const [enabled, setEnabled] = useState(true)

    const setOptimised = (e) => {
        setEnabled(e)
        props.setTimeFormat(e)
    }

    const setMode = (e) => {
        if(enabled){
            setEnabled(false)
            props.setTimeFormat(false)
        }

        else{
            setEnabled(true)
            props.setTimeFormat(true)
        }
    }

    return (
        <div className={"flex text-xs gap-1 text-gray-500"}>
            {/*<p className={classNames(!enabled ? "text-gray-800": "text-gray-500","pt-0.5")}>am/pm</p>*/}
            {/*<Switch*/}
            {/*    checked={enabled}*/}
            {/*    onChange={setOptimised}*/}
            {/*    className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"*/}
            {/*>*/}
            {/*    <span className="sr-only">Use setting</span>*/}
            {/*    <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />*/}
            {/*    <span*/}
            {/*        aria-hidden="true"*/}
            {/*        className={classNames(*/}
            {/*            'bg-gray-200',*/}
            {/*            'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'*/}
            {/*        )}*/}
            {/*    />*/}
            {/*    <span*/}
            {/*        aria-hidden="true"*/}
            {/*        className={classNames(*/}
            {/*            enabled ? 'translate-x-5' : 'translate-x-0',*/}
            {/*            'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-800 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'*/}
            {/*        )}*/}
            {/*    />*/}
            {/*</Switch>*/}
            {/*<p className={classNames(enabled ? "text-gray-800": "text-gray-500","pt-0.5")}>24hr</p>*/}

            {/*<div className="mx-8 px-4 border bg-gray-200 rounded-lg h-8 flex p-1 relative items-center gap-5 relative">*/}
            {/*    <div className="w-full flex justify-center">*/}
            {/*        <button>am/pm</button>*/}
            {/*    </div>*/}
            {/*    <div className="w-full flex justify-center">*/}
            {/*        <button>24hr</button>*/}
            {/*    </div>*/}
            {/*    <span*/}
            {/*        aria-hidden="true"*/}
            {/*        className={classNames(*/}
            {/*            enabled ? 'translate-x-[52px]' : 'translate-x-[4px]',*/}
            {/*            'pointer-events-none absolute left-0 inline-block h-6 w-16 transform rounded-lg border border-gray-800 shadow ring-0 transition-transform duration-200 ease-in-out'*/}
            {/*        )}*/}

            {/*        asd*/}
            {/*    />*/}
            {/*</div>*/}

            <div
                className="relative bg-gray-200 text-sm text-gray-500 leading-none border-2 border-gray-200 rounded-full inline-flex">
                <button
                    onClick={setMode}
                    className={classNames(!enabled?"bg-white text-blue-400" : "bg-transparent","rounded-full inline-flex items-center transition-colors duration-300 ease-in focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-l-full px-4 py-2 active")}
                    id="grid">
                    {/*<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                    {/*     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"*/}
                    {/*     className="fill-current w-4 h-4 mr-2">*/}
                    {/*    <rect x="3" y="3" width="7" height="7"></rect>*/}
                    {/*    <rect x="14" y="3" width="7" height="7"></rect>*/}
                    {/*    <rect x="14" y="14" width="7" height="7"></rect>*/}
                    {/*    <rect x="3" y="14" width="7" height="7"></rect>*/}
                    {/*</svg>*/}
                    <span>am/pm</span>
                </button>
                <button
                    onClick={setMode}
                    className={classNames(enabled?"bg-white text-blue-400" : "bg-transparent", "rounded-full inline-flex items-center transition-colors duration-300 ease-in focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-r-full px-4 py-2")}
                    id="list">
                    {/*<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                    {/*     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"*/}
                    {/*     className="fill-current w-4 h-4 mr-2">*/}
                    {/*    <line x1="8" y1="6" x2="21" y2="6"></line>*/}
                    {/*    <line x1="8" y1="12" x2="21" y2="12"></line>*/}
                    {/*    <line x1="8" y1="18" x2="21" y2="18"></line>*/}
                    {/*    <line x1="3" y1="6" x2="3.01" y2="6"></line>*/}
                    {/*    <line x1="3" y1="12" x2="3.01" y2="12"></line>*/}
                    {/*    <line x1="3" y1="18" x2="3.01" y2="18"></line>*/}
                    {/*</svg>*/}
                    <span>24hr</span>
                </button>

            </div>
        </div>

    )
}
