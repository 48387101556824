import React, {useContext, useState} from "react";
import { RadioGroup } from '@headlessui/react'
import {CheckCircleIcon, EnvelopeIcon} from "@heroicons/react/20/solid";
import {Redirect} from "react-router-dom";

const mailingLists = [
    { id: 1, title: 'Teacher', description: 'I have something to teach', url: '/signup/teacher', type: "TEACHER" },
    { id: 2, title: 'Student', description: 'I have something to learn', url: '/signup/student', type: "STUDENT" },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const UserTypeSelection = ({ history }) => {
    const [selectedMailingLists, setSelectedMailingLists] = useState(mailingLists[0])
    return (
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
                    <RadioGroup value={selectedMailingLists} onChange={setSelectedMailingLists}>
                        <RadioGroup.Label className="text-xl font-medium text-gray-900">What would you like to do on Abakusz?</RadioGroup.Label>

                        <div className="mt-5 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                            {mailingLists.map((mailingList) => (
                                <RadioGroup.Option
                                    key={mailingList.id}
                                    value={mailingList}
                                    className={({ checked, active }) =>
                                        classNames(
                                            checked ? 'border-transparent' : 'border-gray-300',
                                            active ? 'border-secondary ring-2 ring-secondary' : '',
                                            'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                                        )
                                    }
                                >
                                    {({ checked, active }) => (
                                        <>
                    <span className="flex flex-1">
                      <span className="flex flex-col">
                        <RadioGroup.Label as="span" className="block text-base font-medium text-gray-900">
                          {mailingList.title}
                        </RadioGroup.Label>
                        <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                          {mailingList.description}
                        </RadioGroup.Description>
                      </span>
                    </span>
                                            <CheckCircleIcon
                                                className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-secondary')}
                                                aria-hidden="true"
                                            />
                                            <span
                                                className={classNames(
                                                    active ? 'border' : 'border-2',
                                                    checked ? 'border-secondary' : 'border-transparent',
                                                    'pointer-events-none absolute -inset-px rounded-lg'
                                                )}
                                                aria-hidden="true"
                                            />
                                        </>
                                    )}
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>
                    <div className={"flex lg:justify-end"}>
                        <button
                            type="button"
                            // className="w-full my-6 justify-center inline-flex items-center rounded-md border border-transparent bg-secondary lg:w-44 lg:my-4 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-secondary-alt focus:outline-none"
                            className="flex w-full my-6 justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-base leading-7 font-medium text-white shadow-sm hover:bg-secondary-alt focus:outline-none"

                            onClick={()=>{history.push(selectedMailingLists.url)}}
                        >
                            {`Join as a ${selectedMailingLists.title}`}
                        </button>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default UserTypeSelection;