import React, {Fragment, useContext, useEffect, useState} from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { DocumentPlusIcon } from '@heroicons/react/24/outline'

import driveIcon from "../../images/google_drive.svg"

import moment from "moment";
import dataLoader from "../../api/DataLoader";
import UserContext from "../../auth/UserContext";
import ProfilePicture from "../../components/ProfilePicture";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function WorkspaceCard(props) {
    const userContext = useContext(UserContext)

    // const [profilePicture, setProfilePicture] = useState("")

    // useEffect(() => {
    //     dataLoader.getProfilePicture(props.partnerId)
    //         .then(res => setProfilePicture(res.data))
    // }, []);

    return (
                <li key={props.partnerId} className="overflow-hidden rounded-xl border border-gray-200">
                    <div className="flex justify-between gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                        <div className={"flex gap-x-4"}>
                            {/*<img*/}
                            {/*    src={profilePicture}*/}
                            {/*    alt={props.name}*/}
                            {/*    className="h-12 w-12 flex-none rounded-full bg-white object-cover ring-1 ring-gray-900/10"*/}
                            {/*/>*/}
                            <ProfilePicture name={props.name} userId={props.partnerId} height={"h-12 w-12"} ring={"ring-1 ring-gray-900/10"}></ProfilePicture>
                            <div>
                                <div className="text-sm font-medium leading-6 text-gray-900">{props.name}</div>
                                <div className="text-sm text-gray-500">{props.fileNames.length + " shared files"}</div>

                            </div>
                        </div>
                        <div className={"flex"}>
                            <button
                                type="button"
                                onClick={()=>window.open(props.shareURL, '_blank').focus()}
                                className="flex gap-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                <img src={driveIcon} alt="" className={"h-8 w-8"}/>
                                <p className={"text-sm leading-8"}>Open in Google Drive</p>
                            </button>
                        </div>
                        {/*<Menu as="div" className="relative ml-auto">*/}
                        {/*    <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">*/}
                        {/*        <span className="sr-only">Open options</span>*/}
                        {/*        <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />*/}
                        {/*    </Menu.Button>*/}
                        {/*    <Transition*/}
                        {/*        as={Fragment}*/}
                        {/*        enter="transition ease-out duration-100"*/}
                        {/*        enterFrom="transform opacity-0 scale-95"*/}
                        {/*        enterTo="transform opacity-100 scale-100"*/}
                        {/*        leave="transition ease-in duration-75"*/}
                        {/*        leaveFrom="transform opacity-100 scale-100"*/}
                        {/*        leaveTo="transform opacity-0 scale-95"*/}
                        {/*    >*/}
                        {/*        <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">*/}
                        {/*            <Menu.Item>*/}
                        {/*                {({ active }) => (*/}
                        {/*                    <a*/}
                        {/*                        href="#"*/}
                        {/*                        className={classNames(*/}
                        {/*                            active ? 'bg-gray-50' : '',*/}
                        {/*                            'block px-3 py-1 text-sm leading-6 text-gray-900'*/}
                        {/*                        )}*/}
                        {/*                    >*/}
                        {/*                        View<span className="sr-only">, {props.name}</span>*/}
                        {/*                    </a>*/}
                        {/*                )}*/}
                        {/*            </Menu.Item>*/}
                        {/*            <Menu.Item>*/}
                        {/*                {({ active }) => (*/}
                        {/*                    <a*/}
                        {/*                        href="#"*/}
                        {/*                        className={classNames(*/}
                        {/*                            active ? 'bg-gray-50' : '',*/}
                        {/*                            'block px-3 py-1 text-sm leading-6 text-gray-900'*/}
                        {/*                        )}*/}
                        {/*                    >*/}
                        {/*                        Edit<span className="sr-only">, {props.name}</span>*/}
                        {/*                    </a>*/}
                        {/*                )}*/}
                        {/*            </Menu.Item>*/}
                        {/*        </Menu.Items>*/}
                        {/*    </Transition>*/}
                        {/*</Menu>*/}
                    </div>
                    {props.fileNames.length === 0 ?
                        <div className={"w-full  h-64 flex flex-col justify-center text-center overflow-0 hover:bg-gray-100 cursor-pointer text-gray-300 hover:text-gray-500"}
                             onClick={()=>window.open(props.shareURL, '_blank').focus()}
                        >
                            <DocumentPlusIcon className={"mx-auto w-16 h-16"}></DocumentPlusIcon>
                            <p>Add new file</p>
                        </div>
                        :
                        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6 h-64 overflow-auto">
                            {props.fileNames.map((fileName) => (
                                <div className="flex justify-between gap-x-4 py-3 px-3 hover:bg-gray-100 rounded-lg cursor-pointer"
                                     onClick={()=>window.open(props.shareURL, '_blank').focus()}
                                >
                                    <dt className="text-gray-500">{fileName}</dt>
                                    <dd className="text-gray-700">
                                        <div>{moment().format("MMMM Do YYYY, h:mm a")}</div>
                                    </dd>
                                </div>
                            ))}

                        </dl>
                    }

                </li>
    )
}