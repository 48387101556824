import React, {useCallback, useContext, useState} from 'react';

import UserContext from "../../auth/UserContext";
import { Redirect, withRouter} from "react-router-dom";
import auth from "../../auth/base.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import {TailSpin} from "react-loader-spinner";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Login = ({ history }) => {
    const { currentUser, isAuthenticated } = useContext(UserContext);
    const [ incorrect, setIncorrect ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const handleLogin = useCallback(
        async event => {
            setLoading(true);
            event.preventDefault();
            const { email, password } = event.target.elements;
                signInWithEmailAndPassword(auth, email.value, password.value)
                    .then((userCredential) => {
                        // Signed in
                        const user = userCredential.user;
                        history.push("/");
                        // window.location.reload();

                        // ...
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        setIncorrect(true)
                        setLoading(false);
                    });
                // history.push("/appointments");
        },
        [history]
    );



    if (isAuthenticated) {
        return <Redirect to="/appointments" />;
    }

    return (
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-12 w-auto"
                    src={process.env.PUBLIC_URL + '/primary-logo.svg'} alt="logo"
                />
                <h2 className="mt-6 text-center md:text-4xl font-bold tracking-tight text-gray-900 text-2xl">Sign in to your account</h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                    Or{' '}
                    <a href="/signup" className="font-medium text-secondary hover:text-secondary-alt">
                        create an account for free
                    </a>
                </p>
            </div>

            <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={handleLogin}>
                        <div>
                            <p className={incorrect ? "block text-sm text-red-600 mb-4" :"hidden"}>E-mail or password incorrect</p>

                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none sm:text-xl"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none  sm:text-xl"
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center cursor-pointer">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-secondary focus:ring-0 cursor-pointer"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900 cursor-pointer">
                                    Remember me
                                </label>
                            </div>

                            <div className="text-sm">
                                <a href="#" className="font-medium text-secondary hover:text-secondary-alt">
                                    Forgot your password?
                                </a>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={loading}
                                className={classNames("disabled:bg-blue-300 flex w-full justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-base leading-7 font-medium text-white shadow-sm hover:bg-secondary-alt focus:outline-none")}
                            >
                                {loading ?
                                    <TailSpin
                                        height="28"
                                        width="20"
                                        color="white"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />:

                                "Sign in"
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
};

export default withRouter(Login);