import { useState } from 'react'
import './OptimisedBookingsDemoBox.scss';
import animationData from '../../lotties/workhours-suggest.json';
import Lottie from "react-lottie";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export default function OptimisedBookingsDemoBox() {
    const [enabled, setEnabled] = useState(false)

    return (
        <div className={"w-full rounded-lg border-solid border border-gray-300 flex justify-between"}>
            <div className={"py-4 pl-5 text-xs leading-relaxed"}>Preferred hours are <span className={"underline decoration-2 underline-offset-4 decoration-secondary"}>highlighted</span> to your students</div>
            {/*<div className={"h-16 overflow-hidden relative"}>*/}
                {/*<div id={"fadeout-top"}></div>*/}
                {/*<div className="w-full h-16 pl-2">*/}
                {/*    <ul className={"animate-infinite-scroll leading-8"}>*/}
                {/*        <li className={"flex gap-8 text-sm py-1 justify-center"}>*/}
                {/*            <div>11:00</div>*/}
                {/*            <div>09:00</div>*/}
                {/*            <div>15:00</div>*/}
                {/*            <div>12:00</div>*/}
                {/*        </li>*/}
                {/*        <li className={"flex gap-8 text-sm py-1 justify-center"}>*/}
                {/*            <div>12:00</div>*/}
                {/*            <div className={"underline decoration-2 underline-offset-2 decoration-secondary"}>10:00</div>*/}
                {/*            <div>16:00</div>*/}
                {/*            <div>13:00</div>*/}
                {/*        </li>*/}
                {/*        <li className={"flex gap-8 text-sm py-1 justify-center"}>*/}
                {/*            <div>13:00</div>*/}
                {/*            <div className={"underline decoration-2 underline-offset-2 decoration-secondary"}>11:00</div>*/}
                {/*            <div className={"underline decoration-2 underline-offset-2 decoration-secondary"}>17:00</div>*/}
                {/*            <div>14:00</div>*/}
                {/*        </li>*/}
                {/*        <li className={"flex gap-8 text-sm py-1 justify-center"}>*/}
                {/*            <div>14:00</div>*/}
                {/*            <div>12:00</div>*/}
                {/*            <div className={"underline decoration-2 underline-offset-2 decoration-secondary"}>18:00</div>*/}
                {/*            <div>15:00</div>*/}
                {/*        </li>*/}

                {/*    </ul>*/}
                {/*    <ul className={"animate-infinite-scroll leading-8"} aria-hidden="true">*/}
                {/*        <li className={"flex gap-8 text-sm py-1 justify-center"}>*/}
                {/*            <div>11:00</div>*/}
                {/*            <div>09:00</div>*/}
                {/*            <div>15:00</div>*/}
                {/*            <div>12:00</div>*/}
                {/*        </li>*/}
                {/*        <li className={"flex gap-8 text-sm py-1 justify-center"}>*/}
                {/*            <div>12:00</div>*/}
                {/*            <div className={"underline decoration-2 underline-offset-2 decoration-secondary"}>10:00</div>*/}
                {/*            <div>16:00</div>*/}
                {/*            <div>13:00</div>*/}
                {/*        </li>*/}
                {/*        <li className={"flex gap-8 text-sm py-1 justify-center"}>*/}
                {/*            <div>13:00</div>*/}
                {/*            <div className={"underline decoration-2 underline-offset-2 decoration-secondary"}>11:00</div>*/}
                {/*            <div className={"underline decoration-2 underline-offset-2 decoration-secondary"}>17:00</div>*/}
                {/*            <div>14:00</div>*/}
                {/*        </li>*/}
                {/*        <li className={"flex gap-8 text-sm py-1 justify-center"}>*/}
                {/*            <div>14:00</div>*/}
                {/*            <div>12:00</div>*/}
                {/*            <div className={"underline decoration-2 underline-offset-2 decoration-secondary"}>18:00</div>*/}
                {/*            <div>15:00</div>*/}
                {/*        </li>*/}
                {/*    </ul>*/}

                {/*</div>*/}
                {/*<div id={"fadeout"}></div>*/}



            {/*</div>*/}
            <div className={"pt-1"}>
                <Lottie
                    options={defaultOptions}
                    height={80}
                    width={300}
                />
            </div>

        </div>
    )
}