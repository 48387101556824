import React, {FC, useEffect, useState} from 'react';
import CurrencyInput from "react-currency-input-field";
import {onAuthStateChanged} from "firebase/auth";
import auth from "../../auth/base";

const options= [
    {
        locale: 'de-DE',
        currency: 'EUR',
    },
    {
        locale: 'hu-HU',
        currency: 'HUF',
    },
    {
        locale: 'en-US',
        currency: 'USD',
    },
    {
        locale: 'en-GB',
        currency: 'GBP',
    },
];

export default function CustomCurrencyInput (props) {
    const [intlConfig, setIntlConfig] = useState(options[0]);
    const [value, setValue] = useState(props.value);
    // const [value, setValue] = useState('123');
    const [rawValue, setRawValue] = useState(' ');

    useEffect(() => {
        props.setPrice(value)
        props.setCurrency(intlConfig.currency)
        // console.log(value)
    }, [value, intlConfig]);

    const handleOnValueChange = (value) => {
        setRawValue(value === undefined ? 'undefined' : value || ' ');
        setValue(value);
    };

    const handleIntlSelect = (event) => {
        const config = options[Number(event.target.value)];
        if (config) {
            setIntlConfig(config);
        }
    };

    return (
        <div className="row">
            <div className="col-12 mb-4">
                <div className="row">
                    <div className="flex">
                        <div className="flex w-full gap-4">
                            <div className="w-9/12">
                                <CurrencyInput
                                    id="validationCustom04"
                                    name="input-1"
                                    intlConfig={intlConfig}
                                    className={`form-control w-full border-1 rounded-md border-gray-300`}
                                    onValueChange={handleOnValueChange}
                                    decimalsLimit={2}
                                    decimalSeparator="."
                                    groupSeparator=","
                                    value={value}
                                    step={1}
                                />
                            </div>
                            <div>
                                <select className="form-control border-1 rounded-md border-gray-300" id="intlConfigSelect" onChange={handleIntlSelect}>
                                    {options.map((config, i) => {
                                        if (config) {
                                            const { locale, currency } = config;
                                            return (
                                                <option key={`${locale}${currency}`} value={i}>
                                                    {currency}
                                                </option>
                                            );
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group col">
            {/*<pre className="h-100 p-3 bg-dark text-white">*/}
            {/*  <div className="row">*/}
            {/*    <div className="col-12">*/}
            {/*      <div className="text-muted mr-3">onValueChange:</div>*/}
            {/*        {rawValue}*/}
            {/*        <div className="text-muted mr-3 mt-3">intlConfig:</div>*/}
            {/*        {JSON.stringify(intlConfig)}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</pre>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};
