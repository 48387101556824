import Logo from "./logo.svg"
import spinner from "./lotties/spinner.json";
import Lottie from "react-lottie";


export const FullPageLoading = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: spinner,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className={"flex items-center justify-center h-screen"}>
            {/*<div className={"flex w-44 h-44 rounded-full bg-slate-200"}>*/}
            {/*    <img className={"mx-auto w-32 h-auto"} src={Logo} alt=""/>*/}
            {/*</div>*/}
            <div>
                <Lottie
                    options={defaultOptions}
                    height={100}
                    width={100}
                />
            </div>
        </div>
    );
}