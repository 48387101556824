import React, {useContext, useEffect, useRef, useState} from 'react';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid'
import UserContext from "../../auth/UserContext";
import {Helmet} from "react-helmet";

function Settings(props) {
    const userContext = useContext(UserContext)


    return (
        <div className={"bg-white h-screen"}>
            <Helmet>
                <title>Settings - Abakusz</title>
            </Helmet>
            <div className={"pt-6 mx-auto max-w-7xl px-2 sm:px-6 lg:px-8"}>
                <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 ">
                        Settings
                    </h3>
                    <div className="mt-3 sm:mt-0 sm:ml-4">
                        <button
                            onClick={userContext.logout}
                            type="button"
                            className="inline-flex items-center rounded-md ring-1 ring-inset ring-red-600 px-4 py-2 text-sm font-medium text-red-600 shadow-sm hover:bg-red-200"
                        >
                            Log out
                        </button>
                    </div>
                </div>

                <div className={"mt-12 flex gap-4"}>
                    <h3 className={"text-slate-600"}>Having trouble with something? We’re here to help.</h3>
                    <div className={"flex gap-2 text-secondary w-32 leading-2"}>
                        <p>Contact us</p>
                        <ArrowLongRightIcon className={"w-7"}></ArrowLongRightIcon>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;