import React, {useContext} from 'react';
import {CheckCircleIcon, ClipboardDocumentCheckIcon, ClipboardDocumentIcon} from "@heroicons/react/20/solid";
import UserContext from "../../auth/UserContext";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";
import {useHistory} from "react-router";
import CourseCard from "../../components/CourseCard";

function TeacherFinishPage(props) {
    const userContext = useContext(UserContext)
    let history = useHistory();


    return (
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="text-center">
                    <div className={"flex gap-4 justify-center items-center"}>
                        <CheckCircleIcon className={"text-green-400 h-12"}></CheckCircleIcon>
                        <h2 className="text-2xl font-medium text-gray-900">We are ready!</h2>
                    </div>
                    <p className="mt-4 text-sm text-gray-500">
                        Let people know about your new course! With a single link, people can book a lesson with you.
                    </p>
                </div>
                <CourseCard courseInfo={props.courseInfo} editable={false}></CourseCard>

                <div className={"flex justify-center py-14"}>
                    <button
                        onClick={()=>history.push("/appointments")}
                        type="submit"
                        className="flex w-96 justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-base font-medium text-white shadow-sm hover:bg-secondary-alt focus:outline-none"
                    >
                        To the homepage
                    </button>
                </div>
            </div>

        </div>
    );
}

export default TeacherFinishPage;