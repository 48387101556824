import React, {useContext, useEffect, useState} from 'react';
import {Step, Steps, Wizard} from "react-albus";
import ShareCourseTimeline from "./ShareCourseTimeline";
import BasicButton from "../../components/BasicButton";
import CourseCard from "../../components/CourseCard";
import BasicModal from "../../components/BasicModal";
import dataLoader from "../../api/DataLoader";
import UserContext from "../../auth/UserContext";
import CourseCardStack from "./CourseCardStack";

function ShareCourseModal(props) {
    const userContext = useContext(UserContext)
    const [courseCard, setCourseCard] = useState();


    useEffect(() => {
        if(userContext?.userInfo?.id !== null){
            dataLoader.getLessonCardsForUser(userContext?.userInfo?.id, userContext?.authInfo?.accessToken).then(res =>
                setCourseCard(res.data[0])
            )
        }
    }, [userContext?.userInfo?.id]);

    return (
        <BasicModal show={props.show} closeModal={()=>props.setShow(false)}>
            {/*<Wizard>*/}
            {/*    <Steps>*/}
            {/*        <Step*/}
            {/*            id="merlin"*/}
            {/*            render={({ next }) => (*/}
            {/*                <div className={"flex flex-col items-center gap-4"}>*/}
            {/*                    <div className={"rounded-full bg-emerald-100 p-2"}>*/}
            {/*                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#059669" className="w-8 h-8">*/}
            {/*                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />*/}
            {/*                        </svg>*/}
            {/*                    </div>*/}
            {/*                    <h3 className={"text-xl"}>Just few more clicks...</h3>*/}
            {/*                    <p className={"text-center leading-relaxed text-slate-500"}>Share your course and get your first booking.</p>*/}
            {/*                    <div className={"my-8"}>*/}
            {/*                        <ShareCourseTimeline></ShareCourseTimeline>*/}
            {/*                    </div>*/}
            {/*                    <BasicButton text="Next" onClick={next}></BasicButton>*/}
            {/*                </div>*/}
            {/*            )}*/}
            {/*        />*/}
            {/*        <Step*/}
            {/*            id="dumbledore"*/}
            {/*            render={({ next, previous }) => (*/}
            {/*                <div>*/}
            {/*                    <div className={"flex flex-col items-center gap-4"}>*/}
            {/*                        <div className={"rounded-full bg-emerald-100 p-2"}>*/}
            {/*                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#059669" className="w-8 h-8">*/}
            {/*                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />*/}
            {/*                            </svg>*/}
            {/*                        </div>*/}
            {/*                        <h3 className={"text-xl"}>Just few more clicks...</h3>*/}
            {/*                        <p className={"text-center leading-relaxed text-slate-500"}>Share your course and get your first booking.</p>*/}
            {/*                    </div>*/}
            {/*                    <div className={"mb-8"}>*/}
            {/*                        <CourseCard courseInfo={courseCard}></CourseCard>*/}
            {/*                    </div>*/}
            {/*                    <BasicButton text="Done" onClick={()=>props.setShow(false)}></BasicButton>*/}
            {/*                </div>*/}
            {/*            )}*/}
            {/*        />*/}
            {/*    </Steps>*/}
            {/*</Wizard>*/}

            <CourseCardStack></CourseCardStack>


        </BasicModal>
    );
}

export default ShareCourseModal;