import React, {useContext, useEffect, useRef, useState} from "react";
import UserContext from "../../auth/UserContext";
import dataLoader from "../../api/DataLoader";
import moment from "moment";

const ProcessingBooking = (props) => {
    const [closed, setClosed] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [validationResult, setValidationResult] = useState();
    const userContext = useContext(UserContext)

    const isMounted = useRef(false);


    useEffect(() => {
        dataLoader.saveAppointment(props.courseInfo?.userId, userContext.userInfo.id, moment(props.date + " "+ props.time).tz(props.timeZone, true).format(), moment(props.date + " "+ props.time).tz(props.timeZone, true).add(props.courseInfo.duration, 'minutes').format(), props.courseInfo?.title, userContext.authInfo.accessToken)
                    .then(r => props.setBooked(true))

    }, []);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return <div>
        PROCESSING
    </div>

}

export default ProcessingBooking