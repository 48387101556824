import TimezoneSelect from "react-timezone-select";
import TimeRangeSelector from "../workhours-settings/TimeRangeSelector";
import {PlusCircleIcon, TrashIcon} from "@heroicons/react/24/outline";
import {SparklesIcon, QuestionMarkCircleIcon} from "@heroicons/react/24/solid";
import BasicButton from "../../components/BasicButton";
import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../auth/UserContext";
import dataLoader from "../../api/DataLoader";
import OptimiseSwitch from "../workhours-settings/OptimiseSwitch";
import OptimisedBookingsDemoBox from "../workhours-settings/OptimisedBookingsDemoBox";
import GradientScroll from "react-gradient-scroll-indicator";

import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';

import "./OnBoarding.scss"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function WorkhourHours(props) {
    const userContext = useContext(UserContext)

    const [loading, setLoading] = useState(true)
    // const [timeFrame, setTimeframe] = useState({MONDAY:[{from: 480, to: 540}], TUESDAY:[{from: 480, to: 540}, {from: 600, to: 660}], WEDNESDAY:[{from: 600, to: 720}], THURSDAY:[], FRIDAY:[],SATURDAY:[],SUNDAY:[]})
    const [timeFrame, setTimeframe] = useState({
        MONDAY: [],
        TUESDAY: [],
        WEDNESDAY: [],
        THURSDAY: [],
        FRIDAY: [],
        SATURDAY: [],
        SUNDAY: []
    })
    const [selectedTimezone, setSelectedTimezone] = useState(
        localStorage.getItem("selectedTimezone") !== null ?
            localStorage.getItem("selectedTimezone")
            :

            Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    const [optimiseWorkhours, setOptimiseWorkhours] = useState(false)


    useEffect(() => {
        // dataLoader.getWorkinghours(userContext.userInfo.id, selectedTimezone).then(res =>
        //     mapWorkhoursToTimeframes(res.data)
        // )
        // dataLoader.getCourseSettings(userContext.userInfo.id, userContext.authInfo.accessToken).then(res =>
        //     setOptimiseWorkhours(res.data.optimiseHours)
        // )

    }, [selectedTimezone]);


    // let timeFrame = {MONDAY:[{from: 480, to: 540}], TUESDAY:[{from: 480, to: 540}]}

    const selectTimezone = (timezone) => {
        setSelectedTimezone(timezone)
        localStorage.setItem("selectedTimezone", timezone)
    }


    const editMinutes = (minutes, boundary, day, index) => {
        let clone = Object.assign({}, timeFrame);

        if (boundary === "from") {
            clone[day][index].from = minutes;
        } else {
            clone[day][index].to = minutes;

        }

        setTimeframe(clone)
    }

    const addRange = (day) => {
        let clone = Object.assign({}, timeFrame);

        clone[day].push({from: 480, to: 540})
        setTimeframe(clone)
    }

    const removeRange = (day, index) => {
        let clone = Object.assign({}, timeFrame);
        let asd = clone[day].splice(index, 1)

        setTimeframe(clone)
    }

    const transformWorkhours = (workhoursList) => {
        let res = []
        for (let day of Object.entries(workhoursList)) {
            for (let range of day[1]) {
                res.push({userId: userContext.userInfo.id, day: day[0], startHour: range.from, endHour: range.to})

            }
        }

        return res
    }

    const mapWorkhoursToTimeframes = (workhourList) => {
        let timeSceleton = {MONDAY: [], TUESDAY: [], WEDNESDAY: [], THURSDAY: [], FRIDAY: [], SATURDAY: [], SUNDAY: []}
        let res = timeSceleton
        for (const [day, ranges] of Object.entries(timeSceleton)) {
            for (let workhour of workhourList.filter(r => r.day === day)) {
                res[day].push({from: workhour.startHour, to: workhour.endHour})
            }


        }
        setTimeframe(res)
    }

    const saveWorkingHours = () => {
        // setLoading(true)
        let workingHoursWithTimezone = {
            workhoursList: transformWorkhours(timeFrame),
            timezone: selectedTimezone,
            optimiseWorkhours: optimiseWorkhours
        }
        dataLoader.saveAllWorkinghours(userContext.userInfo.id, workingHoursWithTimezone, userContext.authInfo.accessToken)
            .then(r =>props.next())

        // console.log(workingHoursWithTimezone)

    }

    return (
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
                <div className="bg-white py-4 px-4 sm:rounded-lg sm:px-10">
                    {/*<div className="sm:mx-auto sm:w-full sm:max-w-md">*/}
                    {/*    <img*/}
                    {/*        className="mx-auto h-12 w-auto"*/}
                    {/*        src={process.env.PUBLIC_URL + '/primary-logo.svg'} alt="logo"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className={"font-semibold leading-7 py-3"}>
                        Set your availability
                    </div>
                    <div className={"flex h-full justify-between gap-2"}>
                        {/*<div className={"h-10/12 flex flex-col gap-6 justify-between"}>*/}
                        <div className={"flex flex-col gap-6 justify-center w-full"}>
                            <div>
                                <TimezoneSelect
                                    value={selectedTimezone}
                                    onChange={(e) => selectTimezone(e.value)}
                                />
                            </div>

                            <div className={"h-64 relative"}>
                                {/*<div id={"fadeout-top-2"}></div>*/}

                                <GradientScroll>
                                    {/*<div className={"h-64 overflow-auto flex flex-col gap-4"}>*/}
                                    <div className={"h-64 flex flex-col gap-4"}>
                                        <div className={"flex justify-between items-center gap-10"}>
                                            <p className={"pt-2 w-1/12"}>Mon</p>
                                            {/*<p className={"text-slate-400"}>Not available</p>*/}
                                            <div className={"flex flex-col gap-2"}>
                                                {
                                                    timeFrame.MONDAY.length > 0 ?
                                                        timeFrame.MONDAY.map((range, index) =>
                                                            <div className={"flex gap-3"}>
                                                                <TimeRangeSelector from={range.from} to={range.to}
                                                                                   editMinutes={(minutes) => editMinutes(minutes, "from", "MONDAY", index)}
                                                                                   editToMinutes={(minutes) => editMinutes(minutes, "to", "MONDAY", index)}
                                                                ></TimeRangeSelector>

                                                                <TrashIcon
                                                                    className={"w-6 h-6 mt-[5px] mr-6 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                                    onClick={() => removeRange("MONDAY", index)}></TrashIcon>
                                                                {index === timeFrame.MONDAY.length - 1 &&
                                                                <div className={"flex"}>
                                                                    <div
                                                                        className={"w-[1px] h-6 mt-[6px] mx-2 bg-slate-400"}></div>

                                                                    <PlusCircleIcon
                                                                        className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                                        onClick={() => addRange("MONDAY")}></PlusCircleIcon>
                                                                </div>
                                                                }

                                                            </div>
                                                        )
                                                        :
                                                        <div className={"text-slate-400"}>Not available</div>

                                                }
                                            </div>
                                            {timeFrame.MONDAY.length === 0 && <PlusCircleIcon
                                                className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                onClick={() => addRange("MONDAY")}></PlusCircleIcon>}

                                        </div>
                                        <div className={"flex justify-between items-center gap-10"}>
                                            <p className={"pt-2 w-1/12"}>Tue</p>
                                            <div className={"flex flex-col gap-2"}>
                                                {
                                                    timeFrame.TUESDAY.length > 0 ?
                                                        timeFrame.TUESDAY.map((range, index) =>
                                                            <div className={"flex gap-3"}>
                                                                <TimeRangeSelector from={range.from} to={range.to}
                                                                                   editFromMinutes={(minutes) => editMinutes(minutes, "from", "TUESDAY", index)}
                                                                                   editToMinutes={(minutes) => editMinutes(minutes, "to", "TUESDAY", index)}
                                                                ></TimeRangeSelector>

                                                                <TrashIcon
                                                                    className={"w-6 h-6 mt-[5px] mr-6 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                                    onClick={() => removeRange("TUESDAY", index)}></TrashIcon>
                                                                {index === timeFrame.TUESDAY.length - 1 &&
                                                                <div className={"flex"}>
                                                                    <div
                                                                        className={"w-[1px] h-6 mt-[6px] mx-2 bg-slate-400"}></div>

                                                                    <PlusCircleIcon
                                                                        className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                                        onClick={() => addRange("TUESDAY")}></PlusCircleIcon>
                                                                </div>
                                                                }
                                                            </div>
                                                        )
                                                        :
                                                        <div className={"text-slate-400"}>Not available</div>


                                                }
                                            </div>


                                            {/*<TimeRangeSelector from={timeFrame.TUESDAY[0].from} to={timeFrame.TUESDAY[0].to} editFromMinutes={(minutes)=>editMinutes(minutes, "from", "TUESDAY", 0)}*/}
                                            {/*                   editToMinutes={(minutes)=>editMinutes(minutes, "to", "TUESDAY", 0)}*/}
                                            {/*></TimeRangeSelector>*/}
                                            {timeFrame.TUESDAY.length === 0 && <PlusCircleIcon
                                                className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                onClick={() => addRange("TUESDAY")}></PlusCircleIcon>}

                                        </div>
                                        <div className={"flex justify-between items-center gap-10"}>
                                            <p className={"pt-2 w-1/12"}>Wed</p>
                                            <div className={"flex flex-col gap-2"}>
                                                {
                                                    timeFrame.WEDNESDAY.length > 0 ?
                                                        timeFrame.WEDNESDAY.map((range, index) =>
                                                            <div className={"flex gap-3"}>

                                                                <TimeRangeSelector from={range.from} to={range.to}
                                                                                   editFromMinutes={(minutes) => editMinutes(minutes, "from", "WEDNESDAY", index)}
                                                                                   editToMinutes={(minutes) => editMinutes(minutes, "to", "WEDNESDAY", index)}
                                                                ></TimeRangeSelector>
                                                                <TrashIcon
                                                                    className={"w-6 h-6 mt-[5px] mr-6 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                                    onClick={() => removeRange("WEDNESDAY", index)}></TrashIcon>
                                                                {index === timeFrame.WEDNESDAY.length - 1 &&
                                                                <div className={"flex"}>
                                                                    <div
                                                                        className={"w-[1px] h-6 mt-[6px] mx-2 bg-slate-400"}></div>

                                                                    <PlusCircleIcon
                                                                        className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                                        onClick={() => addRange("WEDNESDAY")}></PlusCircleIcon>
                                                                </div>
                                                                }
                                                            </div>
                                                        )
                                                        :
                                                        <div className={"text-slate-400"}>Not available</div>

                                                }
                                            </div>
                                            {timeFrame.WEDNESDAY.length === 0 && <PlusCircleIcon
                                                className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                onClick={() => addRange("WEDNESDAY")}></PlusCircleIcon>}


                                        </div>
                                        <div className={"flex justify-between gap-10"}>
                                            <p className={"pt-2 w-1/12"}>Thu</p>
                                            <div className={"flex flex-col gap-2"}>
                                                {
                                                    timeFrame.THURSDAY.length > 0 ?
                                                        timeFrame.THURSDAY.map((range, index) =>
                                                            <div className={"flex gap-3"}>

                                                                <TimeRangeSelector from={range.from} to={range.to}
                                                                                   editFromMinutes={(minutes) => editMinutes(minutes, "from", "THURSDAY", index)}
                                                                                   editToMinutes={(minutes) => editMinutes(minutes, "to", "THURSDAY", index)}
                                                                ></TimeRangeSelector>
                                                                <TrashIcon
                                                                    className={"w-6 h-6 mt-[5px] mr-6 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                                    onClick={() => removeRange("THURSDAY", index)}></TrashIcon>
                                                                {index === timeFrame.THURSDAY.length - 1 &&
                                                                <div className={"flex"}>
                                                                    <div
                                                                        className={"w-[1px] h-6 mt-[6px] mx-2 bg-slate-400"}></div>
                                                                    <PlusCircleIcon
                                                                        className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                                        onClick={() => addRange("THURSDAY")}></PlusCircleIcon>
                                                                </div>
                                                                }
                                                            </div>
                                                        )
                                                        :
                                                        <div className={"text-slate-400"}>Not available</div>

                                                }
                                            </div>
                                            {timeFrame.THURSDAY.length === 0 && <PlusCircleIcon
                                                className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                onClick={() => addRange("THURSDAY")}></PlusCircleIcon>}


                                        </div>
                                        <div className={"flex justify-between items-center gap-10"}>
                                            <p className={"pt-2 w-1/12"}>Fri</p>
                                            <div className={"flex flex-col gap-2"}>
                                                {
                                                    timeFrame.FRIDAY.length > 0 ?
                                                        timeFrame.FRIDAY.map((range, index) =>
                                                            <div className={"flex gap-3"}>

                                                                <TimeRangeSelector from={range.from} to={range.to}
                                                                                   editFromMinutes={(minutes) => editMinutes(minutes, "from", "FRIDAY", index)}
                                                                                   editToMinutes={(minutes) => editMinutes(minutes, "to", "FRIDAY", index)}
                                                                ></TimeRangeSelector>
                                                                <TrashIcon
                                                                    className={"w-6 h-6 mt-[5px] mr-6 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                                    onClick={() => removeRange("FRIDAY", index)}></TrashIcon>
                                                                {index === timeFrame.FRIDAY.length - 1 &&
                                                                <div className={"flex"}>
                                                                    <div
                                                                        className={"w-[1px] h-6 mt-[6px] mx-2 bg-slate-400"}></div>
                                                                    <PlusCircleIcon
                                                                        className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                                        onClick={() => addRange("FRIDAY")}></PlusCircleIcon>
                                                                </div>

                                                                }
                                                            </div>
                                                        )
                                                        :
                                                        <div className={"text-slate-400"}>Not available</div>

                                                }
                                            </div>
                                            {timeFrame.FRIDAY.length === 0 && <PlusCircleIcon
                                                className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                onClick={() => addRange("FRIDAY")}></PlusCircleIcon>}

                                        </div>
                                        <div className={"flex justify-between items-center gap-10"}>
                                            <p className={"pt-2 w-1/12"}>Sat</p>
                                            <div className={"flex flex-col gap-2"}>
                                                {
                                                    timeFrame.SATURDAY.length > 0 ?
                                                        timeFrame.SATURDAY.map((range, index) =>
                                                            <div className={"flex gap-3"}>

                                                                <TimeRangeSelector from={range.from} to={range.to}
                                                                                   editFromMinutes={(minutes) => editMinutes(minutes, "from", "SATURDAY", index)}
                                                                                   editToMinutes={(minutes) => editMinutes(minutes, "to", "SATURDAY", index)}
                                                                ></TimeRangeSelector>
                                                                <TrashIcon
                                                                    className={"w-6 h-6 mt-[5px] mr-6 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                                    onClick={() => removeRange("SATURDAY", index)}></TrashIcon>
                                                                {index === timeFrame.SATURDAY.length - 1 &&
                                                                <div className={"flex"}>
                                                                    <div
                                                                        className={"w-[1px] h-6 mt-[6px] mx-2 bg-slate-400"}></div>

                                                                    <PlusCircleIcon
                                                                        className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                                        onClick={() => addRange("SATURDAY")}></PlusCircleIcon>
                                                                </div>
                                                                }
                                                            </div>
                                                        )
                                                        :
                                                        <div className={"text-slate-400"}>Not available</div>

                                                }
                                            </div>
                                            {timeFrame.SATURDAY.length === 0 && <PlusCircleIcon
                                                className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                onClick={() => addRange("SATURDAY")}></PlusCircleIcon>}


                                        </div>
                                        <div className={"flex justify-between gap-10"}>
                                            <p className={"pt-2 w-1/12"}>Sun</p>
                                            <div className={"flex flex-col gap-2"}>
                                                {
                                                    timeFrame.SUNDAY.length > 0 ?
                                                        timeFrame.SUNDAY.map((range, index) =>
                                                            <div className={"flex gap-3"}>

                                                                <TimeRangeSelector from={range.from} to={range.to}
                                                                                   editFromMinutes={(minutes) => editMinutes(minutes, "from", "SUNDAY", index)}
                                                                                   editToMinutes={(minutes) => editMinutes(minutes, "to", "SUNDAY", index)}
                                                                ></TimeRangeSelector>
                                                                <TrashIcon
                                                                    className={"w-6 h-6 mt-[5px] mr-6 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                                    onClick={() => removeRange("SUNDAY", index)}></TrashIcon>
                                                                {index === timeFrame.SUNDAY.length - 1 &&
                                                                <div className={"flex"}>
                                                                    <div
                                                                        className={"w-[1px] h-6 mt-[6px] mx-2 bg-slate-400"}></div>

                                                                    <PlusCircleIcon
                                                                        className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                                        onClick={() => addRange("SUNDAY")}></PlusCircleIcon>
                                                                </div>

                                                                }
                                                            </div>
                                                        )
                                                        :
                                                        <div className={"text-slate-400"}>Not available</div>

                                                }
                                            </div>
                                            {timeFrame.SUNDAY.length === 0 && <PlusCircleIcon
                                                className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                onClick={() => addRange("SUNDAY")}></PlusCircleIcon>}

                                        </div>
                                    </div>
                                </GradientScroll>

                                {/*<div id={"fadeout2"}></div>*/}
                            </div>

                            <div className="w-full border-t border-gray-300"/>
                            <div className={"flex justify-between"}>
                                <div className={"flex gap-2"}>
                                    <SparklesIcon className={"w-4 h-4 mt-1"}></SparklesIcon>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">Optimise bookable
                                        hours</h3>
                                    <Tippy content={<span>If active, we will automatically recommend available
                                slots within 2 hours of existing bookings.</span>}>
                                        <QuestionMarkCircleIcon className={"w-5 cursor-pointer text-gray-800"}></QuestionMarkCircleIcon>
                                    </Tippy>
                                </div>
                                <OptimiseSwitch setOptimised={setOptimiseWorkhours}
                                                enabled={optimiseWorkhours}
                                                loaded={true}
                                ></OptimiseSwitch>
                            </div>

                            <OptimisedBookingsDemoBox></OptimisedBookingsDemoBox>


                            <div className="w-full border-t border-gray-300"/>
                            <div>
                                <p className={"text-sm text-gray-500 mb-4"}>You can change these settings anytime</p>
                                <BasicButton text={"Finish"} onClick={() => saveWorkingHours()}></BasicButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkhourHours;