import React, {useContext, useEffect, useRef, useState} from 'react';
import UserContext from "../../auth/UserContext";
import dataLoader from "../../api/DataLoader";
import CourseCard from "../../components/CourseCard";
import EditCourseModal from "./EditCourseModal";
import EditWorkHoursModal from "./EditWorkHoursModal";
import CourseCardSkeleton from "../../components/CourseCardSkeleton";
import {Helmet} from "react-helmet";
import WorkhourRangesModal from "../workhours-settings/WorkhourRangesModal";

function CoursesPage(props) {
    const userContext = useContext(UserContext)
    const [workingHours, setWorkingHours] = useState([]);
    const [courseCards, setCourseCards] = useState([]);
    const [courseModalOpen, setCourseModalOpen] = useState(false)
    const [workhoursModalOpen, setWorkhoursModalOpen] = useState(false)
    const isMounted = useRef(false);


    const [loading, setLoading] = useState(false);



    useEffect(() => {
        setLoading(true)
        dataLoader.getLessonCardsForUser(userContext.userInfo.id, userContext.authInfo.accessToken).then(res =>
            setCourseCards(res.data)
        )
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setLoading(false)
        }
        else{
            isMounted.current = true;
        }
    }, [courseCards]);

    const createCourseCards = () => {
        let courseCardList = []

        for(let card of courseCards){
            courseCardList.push(<CourseCard courseInfo={card} editable={true}></CourseCard>)
        }

        // console.log(courseCardList)

        return courseCardList;
    }

    return (
        <div className={"bg-white h-screen"}>
            {/*<div className="pt-6 mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">*/}
            {/*    <h3 className="text-lg font-medium leading-6 text-gray-900">Your Courses</h3>*/}
            {/*    <div className="mt-3 sm:mt-0 sm:ml-4">*/}
            {/*        <button*/}
            {/*            onClick={()=>setWorkhoursModalOpen(true)}*/}
            {/*            type="button"*/}
            {/*            className="inline-flex items-center rounded-md border border-transparent bg-secondary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"*/}
            {/*        >*/}
            {/*            Edit workhours*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <Helmet>
                <title>Your Courses - Abakusz</title>
            </Helmet>
            <div className={"pt-6 mx-auto max-w-7xl px-2 sm:px-6 lg:px-8"}>
                <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 ">
                        Your courses
                    </h3>
                    <div className="mt-3 sm:mt-0 sm:ml-4">
                        <button
                            onClick={()=>setWorkhoursModalOpen(true)}
                            type="button"
                            className="inline-flex items-center rounded-md border border-transparent bg-secondary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-alt"
                        >
                            Edit Availability
                        </button>
                    </div>
                </div>
            </div>

            <div
                className={"grid md:grid-cols-3 grid-cols-1 gap-10 mx-auto max-w-7xl px-2 sm:px-6 lg:px-8"}
            >
                {loading ?
                    <CourseCardSkeleton></CourseCardSkeleton>
                :
                    <>{createCourseCards()}</>

                }

                <button
                    type="button"
                    onClick={()=>setCourseModalOpen(true)}
                    className="mt-10 relative block w-full rounded-lg border border-dashed border-gray-300 p-12 text-center hover:border-gray-400"
                >

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                         stroke="currentColor" className="w-6 h-6"
                         className="mx-auto h-12 w-12 text-gray-400"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"/>
                    </svg>

                    <span className="mt-2 block text-sm font-medium text-gray-900">Add a new course</span>
                </button>

            </div>
            <EditCourseModal open={courseModalOpen} setOpen={setCourseModalOpen}></EditCourseModal>
            {/*<EditWorkHoursModal open={workhoursModalOpen} setOpen={setWorkhoursModalOpen}></EditWorkHoursModal>*/}
            <WorkhourRangesModal open={workhoursModalOpen} setOpen={setWorkhoursModalOpen}></WorkhourRangesModal>
        </div>
    );
}

export default CoursesPage;