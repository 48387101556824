import React, {useContext, useState} from 'react';
import { Wizard, Steps, Step } from 'react-albus';
import ProfilePicturePicker from "./ProfilePicturePicker";
import CreateCourse from "./CreateCourse";
import TeacherFinishPage from "./TeacherFinishPage";
import BasicSignup from "./BasicSignup";
import WorkingHours from "./WorkingHours";

function TeacherMultiStepForm(props) {
    const [profilePicture, setProfilePicture] = useState(null);
    const [courseInfo, setCourseInfo] = useState({});

    const setAvatar = (profile) => {
        setProfilePicture(profile)
    }

    const setCourse = (course) => {
        setCourseInfo(course)
    }

    return (
        <Wizard>
            <Steps>
                <Step
                    id="form"
                    render={({ next }) => (
                        <BasicSignup next={next} userType={"TEACHER"}></BasicSignup>
                        // <WorkingHours next={next}></WorkingHours>

                    )}
                />
                <Step
                    id="merlin"
                    render={({ next }) => (
                        <ProfilePicturePicker setAvatar={(avatar)=>setAvatar(avatar)} next={next}></ProfilePicturePicker>
                    )}
                />
                <Step
                    id="dumbledore"
                    render={({ next, previous }) => (
                        <CreateCourse setCourse={(course)=>setCourse(course)} next={next}></CreateCourse>
                    )}
                />
                <Step
                    id="dumbledore2"
                    render={({ next, previous }) => (
                        <WorkingHours next={next}></WorkingHours>
                    )}
                />
                <Step
                    id="finish"
                    render={({ previous }) => (
                        <TeacherFinishPage courseInfo={courseInfo}></TeacherFinishPage>
                    )}
                />
            </Steps>
        </Wizard>
    );
}

export default TeacherMultiStepForm;