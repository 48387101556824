import UserContext from "../auth/UserContext";
import dataLoader from "../api/DataLoader";
import React, {useContext, useEffect, useState} from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function ProfilePicture(props) {
    const userContext = useContext(UserContext)

    const [profilePicture, setProfilePicture] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(typeof props.userId !== "undefined"){
            dataLoader.getProfilePicture(props.userId)
                .then(res => getOrDefaultProfilePicture(res.data, props.name))
        }

        else{
            getOrDefaultProfilePicture("", props.name)
        }
    }, [props]);

    useEffect(() => {
        if(profilePicture){
            setLoading(false)
        }
    }, [profilePicture]);

    const getOrDefaultProfilePicture = (pictureURL, givenName)=> {
        if(pictureURL.length === 0){
            // pictureURL = `https://avatars.dicebear.com/api/initials/:${givenName}.svg`;
            pictureURL = `https://api.dicebear.com/7.x/initials/svg?seed=${givenName}`;
        }
        setProfilePicture(pictureURL);
    }

    return (
        // typeof props.userId !== "undefined" ?
        loading ?
            <div className={"animate-pulse rounded-full bg-gray-300 " + props.height}></div>
            // <div></div>
            :
            <img
                src={profilePicture}
                alt={props.name}
                className={classNames(props.height, props.ring,"flex-none rounded-full bg-white object-cover")}
            />
    );
}

export default ProfilePicture;