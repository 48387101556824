import React from 'react';

function BasicButton(props) {
    return (
        <button
            onClick={()=>props.onClick()}
            type="button"
            className="w-full rounded-md bg-secondary py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-secondary-alt focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
            {props.text}
        </button>
    );
}

export default BasicButton;