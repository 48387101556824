import "@whereby.com/browser-sdk"
import {useEffect} from "react";

const FreeClassroom = (props) => {
    // const elm = document.querySelector("whereby-embed");
    // function leaveRoom(event) {
    //     alert("dsds")
    //
    // }
    // elm?.addEventListener("leave", leaveRoom)

    useEffect(() => {
        const elm=document.querySelector("whereby-embed");
        elm?.addEventListener("leave", props.leaveRoom)
    });


    return <whereby-embed
        room={props.roomURL}
        participantCount={"off"}
        locking={"off"}
        roomIntegrations={"on"}
        moreButton={"off"}
        people={"off"}
        settingsButton={"off"}
    />
}

export default FreeClassroom