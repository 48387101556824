import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import { withRouter } from "react-router";
import UserContext from "../../auth/UserContext";
import {TailSpin} from "react-loader-spinner";
import PasswordStrengthBar from "react-password-strength-bar";

import "./OnBoarding.scss"

const SignupModalForm = ({ callback, changeMode, history, next, userType }) => {
    const userContext = useContext(UserContext)

    const [emailError, setEmailError] = useState(false);
    const [emailAlreadyUsedError, setEmailAlreadyUsedError] = useState(false);
    const [givenNameError, setGivenNameError] = useState(false);
    const [familyNameError, setFamilyNameError] = useState(false);
    const [passwordState, setPasswordState] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [loading, setLoading] = useState(false);
    const isMounted = useRef(false);

    const delay = ms => new Promise(res => setTimeout(res, ms));


    useEffect(() => {
        if(isMounted.current) {
            if (userContext.isAuthenticated) {
                // callback()
            } else {
                setEmailError(true)
                setEmailAlreadyUsedError(true)
                setLoading(false)
            }
        }
        else{
            isMounted.current = true;
        }
    }, [userContext]);

    const handleSignUp = useCallback(async event  => {
        event.preventDefault();
        setLoading(true);
        const { email, password, givenname, familyname } = event.target.elements;
        if(validate({ email, password, givenname, familyname })){
            userContext.signUp(email.value, password.value, givenname.value, familyname.value, userType);
            // setLoading(false);
            await delay(5000);
            console.log("delayed")
            // console.log(userContext.isAuthenticated)
            callback()

        }
        else{
            setLoading(false)
        }
    }, [history, userContext.isAuthenticated]);

    const validate = (elements) => {
        let pass = true;
        if(elements.email.value.length === 0){
            setEmailError(true);
            pass=false;
        }
        if(elements.givenname.value.length === 0){
            setGivenNameError(true);
            pass=false;
        }
        if(elements.familyname.value.length === 0){
            setFamilyNameError(true);
            pass=false;
        }
        if(elements.password.value.length === 0){
            setPasswordError(true);
            pass=false;
        }

        return pass;
    }

    return (
        <div className="flex min-h-full flex-col justify-center py-2 sm:px-2 lg:px-4">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center md:text-4xl font-bold tracking-tight text-gray-900 text-2xl">Create an account</h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                    Or{' '}
                    <span onClick={()=>changeMode()} className="font-medium text-secondary hover:text-secondary-alt">
                        sign in to your account
                    </span>
                </p>
            </div>

            <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={handleSignUp}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    autoFocus
                                    className=
                                        {emailError ? "block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-xl"
                                            :
                                            "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-indigo-500 sm:text-xl"

                                        }
                                />
                            </div>
                            {emailError ?
                                <p className="mt-2 text-sm text-red-600" id="email-error">
                                    {
                                        emailAlreadyUsedError ?
                                            "This email address is already in use"
                                            :
                                            "Please fill out this field."
                                    }
                                </p>
                                :
                                ""
                            }

                        </div>

                        <div className={"flex flex-row gap-4"}>
                            <div>
                                <label htmlFor="given-name" className="block text-sm font-medium text-gray-700">
                                    Given Name
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="givenname"
                                        name="given-name"
                                        type="given-name"
                                        autoComplete="given-name"
                                        className=
                                            {givenNameError ?
                                                "block w-full appearance-none rounded-md border border-red-300 px-3 py-2 placeholder-red-300 shadow-sm focus:border-red-500 focus:outline-none focus:ring-indigo-500 sm:text-xl"

                                                :
                                                "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-indigo-500 sm:text-xl"
                                            }
                                    />
                                </div>
                                {givenNameError ?
                                    <p className="mt-2 text-sm text-red-600" id="email-error">
                                        Please fill out this field.
                                    </p>
                                    :
                                    ""
                                }
                            </div>
                            <div>
                                <label htmlFor="family-name" className="block text-sm font-medium text-gray-700">
                                    Family Name
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="familyname"
                                        name="family-name"
                                        type="family-name"
                                        autoComplete="family-name"
                                        className=
                                            {familyNameError ?
                                                "block w-full appearance-none rounded-md border border-red-300 px-3 py-2 placeholder-red-300 shadow-sm focus:border-red-500 focus:outline-none focus:ring-indigo-500 sm:text-xl"
                                                :
                                                "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-indigo-500 sm:text-xl"

                                            }
                                    />
                                </div>
                                {familyNameError ?
                                    <p className="mt-2 text-sm text-red-600" id="email-error">
                                        Please fill out this field.
                                    </p>
                                    :
                                    ""
                                }
                            </div>
                        </div>


                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1 mb-3">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    onChange={(e)=>setPasswordState(e.target.value)}
                                    autoComplete="current-password"
                                    className=
                                        {passwordError ?
                                            "block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-xl"
                                            :
                                            "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-indigo-500 sm:text-xl"
                                        }
                                />
                            </div>
                            <PasswordStrengthBar password={passwordState}
                                                 className={"passwordStrength"}
                                                 scoreWordClassName={"scoreWords"}
                                                 shortScoreWord={"Password is too short"}
                                                 scoreWords={['Weak - This password is highly vulnerable to attacks', 'Weak - This password is highly vulnerable to attacks', 'Okay - This password could be improved', 'Good - This password is reasonably secure', 'Strong - This password offers excellent protection']} />
                            {passwordError ?
                                <p className="mt-2 text-sm text-red-600" id="email-error">
                                    Please fill out this field.
                                </p>
                                :
                                ""
                            }
                        </div>
                        {/*<div>*/}
                        {/*    <label htmlFor="password" className="block text-sm font-medium text-gray-700">*/}
                        {/*        Password again*/}
                        {/*    </label>*/}
                        {/*    <div className="mt-1">*/}
                        {/*        <input*/}
                        {/*            id="password"*/}
                        {/*            name="password"*/}
                        {/*            type="password"*/}
                        {/*            autoComplete="current-password"*/}
                        {/*            required*/}
                        {/*            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-indigo-500 sm:text-sm"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div>
                            <button
                                type="submit"
                                disabled={loading}
                                className="disabled:bg-blue-300 flex w-full justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-base leading-7 font-medium text-white shadow-sm hover:bg-secondary-alt focus:outline-none"
                            >
                                {loading ?
                                    <TailSpin
                                        height="28"
                                        width="20"
                                        color="white"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                    :
                                    "Sign up and Accept"
                                }
                            </button>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center cursor-pointer">

                                <label htmlFor="rememberme" className="ml-2 block text-xs text-gray-900">
                                    By Signing Up, you agree to the Abakusz Terms of Service, including the
                                    <span>{" "}</span>
                                    <a href="https://www.craft.me/s/IMdzEh8ikRiyPv" target={"_blank"} className={"font-bold hover:text-slate-600"}>User Agreement</a>
                                    <span>{" and "}</span>
                                    <a href="https://www.craft.me/s/IMdzEh8ikRiyPv" target={"_blank"} className={"font-bold hover:text-slate-600"}>Privacy Policy</a>
                                </label>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default withRouter(SignupModalForm);