import React, {useContext} from 'react';

import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import UserContext from "../auth/UserContext";
import {Link} from "react-router-dom";
import ProfilePicture from "../components/ProfilePicture";


function Navbar(props) {
    const userContext = useContext(UserContext)

    return (
        <Disclosure as="nav" className="bg-white shadow">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                {/*<Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">*/}
                                {/*    <span className="sr-only">Open main menu</span>*/}
                                {/*    {open ? (*/}
                                {/*        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />*/}
                                {/*    ) : (*/}
                                {/*        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />*/}
                                {/*    )}*/}
                                {/*</Disclosure.Button>*/}
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex flex-shrink-0 items-center">
                                    <img
                                        className="block h-12 w-auto lg:hidden"
                                        src={process.env.PUBLIC_URL + '/primary-logo.svg'}
                                        alt="Your Company"
                                    />
                                    <img
                                        className="hidden h-12 w-auto lg:block"
                                        src={process.env.PUBLIC_URL + '/primary-logo.svg'}
                                        alt="Abakusz"
                                    />
                                </div>
                                {userContext.isAuthenticated
                                ?
                                    <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                        <Link
                                            to={"/appointments"}
                                            className=
                                                {
                                                    props.location.pathname.match(/appointments/) ?
                                                        "inline-flex items-center border-b-2 border-secondary px-1 pt-1 text-sm font-medium text-gray-900"
                                                        :
                                                        "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                                }
                                        >
                                            Appointments
                                        </Link>

                                        {
                                            userContext?.userInfo?.userType === "TEACHER" &&
                                            <Link
                                                to={"/courses"}
                                                className=
                                                    {
                                                        props.location.pathname.match(/courses/) ?
                                                            "inline-flex items-center border-b-2 border-secondary px-1 pt-1 text-sm font-medium text-gray-900"
                                                            :
                                                            "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                                    }
                                            >
                                                Courses
                                            </Link>
                                        }
                                        {
                                            userContext?.userInfo?.userType === "TEACHER" &&
                                            <Link
                                                to={"/dashboard"}
                                                className=
                                                    {
                                                        props.location.pathname.match(/dashboard/) ?
                                                            "inline-flex items-center border-b-2 border-secondary px-1 pt-1 text-sm font-medium text-gray-900"
                                                            :
                                                            "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                                    }
                                            >
                                                Dashboard
                                            </Link>
                                        }

                                        {/*<a*/}
                                        {/*    href="#"*/}
                                        {/*    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"*/}
                                        {/*>*/}
                                        {/*    Notes*/}
                                        {/*</a>*/}
                                        <Link
                                            to={"/resources"}
                                            className=
                                                {
                                                    props.location.pathname.match(/resources/) ?
                                                        "inline-flex items-center border-b-2 border-secondary px-1 pt-1 text-sm font-medium text-gray-900"
                                                        :
                                                        "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                                }
                                        >
                                            Resources
                                        </Link>
                                        <Link
                                            to={"/settings"}
                                            className=
                                                {
                                                    props.location.pathname.match(/settings/) ?
                                                        "inline-flex items-center border-b-2 border-secondary px-1 pt-1 text-sm font-medium text-gray-900"
                                                        :
                                                        "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                                }
                                        >
                                            Settings
                                        </Link>
                                    </div>
                                    :
                                    <div></div>
                                }
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {/* Profile dropdown */}
                                {/*<div>*/}
                                {/*    <img*/}
                                {/*    className="h-8 w-8 rounded-full"*/}
                                {/*    src={userContext.profilePicture}*/}
                                {/*    alt=""*/}
                                {/*/>*/}
                                {/*</div>*/}

                                <ProfilePicture name={userContext.userInfo?.givenName + "_" + userContext.userInfo?.familyName} userId={userContext.userInfo?.id} height={"h-8"}></ProfilePicture>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 pt-2 pb-4">
                            {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                            <Disclosure.Button
                                as="a"
                                href="#"
                                className="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
                            >
                                Dashboard
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href="#"
                                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                            >
                                Team
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href="#"
                                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                            >
                                Projects
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href="#"
                                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                            >
                                Calendar
                            </Disclosure.Button>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

export default Navbar;