import React, {useState} from "react";
import {Helmet} from "react-helmet";
import { PlusCircleIcon, ArrowsRightLeftIcon, TrashIcon } from '@heroicons/react/24/outline'
import HourSelect from "./HourSelect";
import CalendarPreview from "./CalendarPreview";
import TimezoneSelect from 'react-timezone-select'
import BasicButton from "../../components/BasicButton";
import TimeRangeSelector from "./TimeRangeSelector";
import moment from "moment";
import EditWorkHoursModal from "../courses/EditWorkHoursModal";
import WorkhourRangesModal from "./WorkhourRangesModal";
import dataLoader from "../../api/DataLoader";

export default function WorkhoursSettings(props) {
    const [timeFrame, setTimeframe] = useState({MONDAY:[{from: 480, to: 540}], TUESDAY:[{from: 480, to: 540}, {from: 600, to: 660}], WEDNESDAY:[{from: 600, to: 720}], THURSDAY:[], FRIDAY:[],SATURDAY:[],SUNDAY:[]})
    const [selectedTimezone, setSelectedTimezone] =useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )

    // let timeFrame = {MONDAY:[{from: 480, to: 540}], TUESDAY:[{from: 480, to: 540}]}


    const editMinutes = (minutes,boundary, day, index) =>{
        let clone = Object.assign({}, timeFrame);

        if(boundary === "from"){
            clone[day][index].from = minutes;
        }

        else{
            clone[day][index].to = minutes;

        }

        setTimeframe(clone)
    }

    const addRange = (day) =>{
        let clone = Object.assign({}, timeFrame);

        clone[day].push({from: 480, to: 540})
        setTimeframe(clone)
    }

    const removeRange = (day, index) =>{
        let clone = Object.assign({}, timeFrame);
        let asd = clone[day].splice(index, 1)

        setTimeframe(clone)
    }

    return(
        <div className={"h-screen bg-background"}>
            <Helmet>
                <title>Edit workhours - Abakusz</title>
            </Helmet>

            <div className="pt-6 mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="border-b border-gray-200 pb-5 flex justify-between">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 py-1.5 ">
                        Workhour Settings
                    </h3>
                </div>

                <div className={"flex h-full justify-between gap-2"}>
                    <div className={"w-5/12 h-10/12 shadow-md rounded-lg p-8 flex flex-col gap-6 justify-between"}>
                        <div>
                            <TimezoneSelect
                                value={selectedTimezone}
                                onChange={setSelectedTimezone}
                            />
                        </div>
                        <div className={"flex flex-col gap-4"}>
                            <div className={"flex justify-between gap-10"}>
                                <p className={"pt-2 w-1/12"}>Mon</p>
                                {/*<p className={"text-slate-400"}>Not available</p>*/}
                                <div className={"flex flex-col gap-2"}>
                                {
                                    timeFrame.MONDAY.length > 0 ?
                                        timeFrame.MONDAY.map((range, index) =>
                                            <div className={"flex gap-6"}>
                                                <TimeRangeSelector from={range.from} to={range.to} editMinutes={(minutes)=>editMinutes(minutes, "from", "TUESDAY", index)}
                                                                   editToMinutes={(minutes)=>editMinutes(minutes, "to", "MONDAY", index)}
                                                ></TimeRangeSelector>
                                                <TrashIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"} onClick={()=>removeRange("MONDAY", index)}></TrashIcon>
                                                {index === timeFrame.MONDAY.length - 1 &&
                                                <PlusCircleIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer  hover:text-slate-900"} onClick={()=>addRange("MONDAY")}></PlusCircleIcon>

                                                }
                                            </div>
                                        )
                                        :
                                        <div className={"text-slate-400"}>Not available</div>

                                }
                                </div>
                                {timeFrame.MONDAY.length === 0 &&  <PlusCircleIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"} onClick={()=>addRange("MONDAY")}></PlusCircleIcon>}

                            </div>
                            <div className={"flex justify-between gap-10"}>
                                <p className={"pt-2 w-1/12"}>Tue</p>
                                <div className={"flex flex-col gap-2"}>
                                    {
                                        timeFrame.TUESDAY.length > 0 ?
                                            timeFrame.TUESDAY.map((range, index) =>
                                                <div className={"flex gap-6"}>
                                                    <TimeRangeSelector from={range.from} to={range.to} editFromMinutes={(minutes)=>editMinutes(minutes, "from", "TUESDAY", index)}
                                                                       editToMinutes={(minutes)=>editMinutes(minutes, "to", "TUESDAY", index)}
                                                    ></TimeRangeSelector>
                                                    <TrashIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"} onClick={()=>removeRange("TUESDAY", index)}></TrashIcon>
                                                    {index === timeFrame.TUESDAY.length - 1 &&
                                                    <PlusCircleIcon
                                                        className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                        onClick={() => addRange("TUESDAY")}></PlusCircleIcon>
                                                    }
                                                    </div>
                                        )
                                            :
                                            <div className={"text-slate-400"}>Not available</div>



                                    }
                                </div>


                                {/*<TimeRangeSelector from={timeFrame.TUESDAY[0].from} to={timeFrame.TUESDAY[0].to} editFromMinutes={(minutes)=>editMinutes(minutes, "from", "TUESDAY", 0)}*/}
                                {/*                   editToMinutes={(minutes)=>editMinutes(minutes, "to", "TUESDAY", 0)}*/}
                                {/*></TimeRangeSelector>*/}
                                {timeFrame.TUESDAY.length === 0 &&  <PlusCircleIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"} onClick={()=>addRange("TUESDAY")}></PlusCircleIcon>}

                            </div>
                            <div className={"flex justify-between items-center gap-10"}>
                                <p>Wed</p>
                                <div className={"flex flex-col gap-2"}>
                                    {
                                        timeFrame.WEDNESDAY.length > 0 ?
                                            timeFrame.WEDNESDAY.map((range, index) =>
                                                <div className={"flex gap-6"}>

                                                <TimeRangeSelector from={range.from} to={range.to} editFromMinutes={(minutes)=>editMinutes(minutes, "from", "WEDNESDAY", index)}
                                                                   editToMinutes={(minutes)=>editMinutes(minutes, "to", "WEDNESDAY", index)}
                                                ></TimeRangeSelector>
                                                    <TrashIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"} onClick={()=>removeRange("WEDNESDAY", index)}></TrashIcon>
                                                    {index === timeFrame.WEDNESDAY.length - 1 &&
                                                    <PlusCircleIcon
                                                        className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                        onClick={() => addRange("WEDNESDAY")}></PlusCircleIcon>
                                                    }
                                                </div>
                                            )
                                            :
                                            <div className={"text-slate-400"}>Not available</div>

                                    }
                                </div>
                                {timeFrame.WEDNESDAY.length === 0 &&  <PlusCircleIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"} onClick={()=>addRange("WEDNESDAY")}></PlusCircleIcon>}


                            </div>
                            <div className={"flex justify-between gap-10"}>
                                <p className={"pt-2 w-1/12"}>Thu</p>
                                <div className={"flex flex-col gap-2"}>
                                    {
                                        timeFrame.THURSDAY.length > 0 ?
                                            timeFrame.THURSDAY.map((range, index) =>
                                                <div className={"flex gap-6"}>

                                                <TimeRangeSelector from={range.from} to={range.to} editFromMinutes={(minutes)=>editMinutes(minutes, "from", "TUESDAY", index)}
                                                                   editToMinutes={(minutes)=>editMinutes(minutes, "to", "THURSDAY", index)}
                                                ></TimeRangeSelector>
                                                    <TrashIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"} onClick={()=>removeRange("THURSDAY", index)}></TrashIcon>
                                                    {index === timeFrame.THURSDAY.length - 1 &&
                                                    <PlusCircleIcon
                                                        className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                        onClick={() => addRange("THURSDAY")}></PlusCircleIcon>
                                                    }
                                                </div>
                                            )
                                            :
                                            <div className={"text-slate-400"}>Not available</div>

                                    }
                                </div>
                                {timeFrame.THURSDAY.length === 0 &&  <PlusCircleIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"} onClick={()=>addRange("THURSDAY")}></PlusCircleIcon>}


                            </div>
                            <div className={"flex justify-between items-center gap-10"}>
                                <p className={"pt-2 w-1/12"}>Fri</p>
                                <div className={"flex flex-col gap-2"}>
                                    {
                                        timeFrame.FRIDAY.length > 0 ?
                                            timeFrame.FRIDAY.map((range, index) =>
                                                <div className={"flex gap-6"}>

                                                <TimeRangeSelector from={range.from} to={range.to} editFromMinutes={(minutes)=>editMinutes(minutes, "from", "FRIDAY", index)}
                                                                   editToMinutes={(minutes)=>editMinutes(minutes, "to", "FRIDAY", index)}
                                                ></TimeRangeSelector>
                                                    <TrashIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"} onClick={()=>removeRange("FRIDAY", index)}></TrashIcon>
                                                    {index === timeFrame.FRIDAY.length - 1 &&
                                                    <PlusCircleIcon
                                                        className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                        onClick={() => addRange("FRIDAY")}></PlusCircleIcon>
                                                    }
                                                </div>
                                            )
                                            :
                                            <div className={"text-slate-400"}>Not available</div>

                                    }
                                </div>
                                {timeFrame.FRIDAY.length === 0 &&  <PlusCircleIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"} onClick={()=>addRange("FRIDAY")}></PlusCircleIcon>}

                            </div>
                            <div className={"flex justify-between items-center gap-10"}>
                                <p className={"pt-2 w-1/12"}>Sat</p>
                                <div className={"flex flex-col gap-2"}>
                                    {
                                        timeFrame.SATURDAY.length > 0 ?
                                            timeFrame.SATURDAY.map((range, index) =>
                                                <div className={"flex gap-6"}>

                                                <TimeRangeSelector from={range.from} to={range.to} editFromMinutes={(minutes)=>editMinutes(minutes, "from", "SATURDAY", index)}
                                                                   editToMinutes={(minutes)=>editMinutes(minutes, "to", "SATURDAY", index)}
                                                ></TimeRangeSelector>
                                                    <TrashIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"} onClick={()=>removeRange("SATURDAY", index)}></TrashIcon>
                                                    {index === timeFrame.SATURDAY.length - 1 &&
                                                    <PlusCircleIcon
                                                        className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                        onClick={() => addRange("SATURDAY")}></PlusCircleIcon>
                                                    }
                                                </div>
                                            )
                                            :
                                            <div className={"text-slate-400"}>Not available</div>

                                    }
                                </div>
                                {timeFrame.SATURDAY.length === 0 &&  <PlusCircleIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"} onClick={()=>addRange("SATURDAY")}></PlusCircleIcon>}


                            </div>
                            <div className={"flex justify-between gap-10"}>
                                <p className={"pt-2 w-1/12"}>Sun</p>
                                <div className={"flex flex-col gap-2"}>
                                    {
                                        timeFrame.SUNDAY.length > 0 ?
                                            timeFrame.SUNDAY.map((range, index) =>
                                                <div className={"flex gap-6"}>

                                                <TimeRangeSelector from={range.from} to={range.to} editFromMinutes={(minutes)=>editMinutes(minutes, "from", "SUNDAY", index)}
                                                                   editToMinutes={(minutes)=>editMinutes(minutes, "to", "SUNDAY", index)}
                                                ></TimeRangeSelector>
                                                    <TrashIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"} onClick={()=>removeRange("SUNDAY", index)}></TrashIcon>
                                                    {index === timeFrame.SUNDAY.length - 1 &&
                                                    <PlusCircleIcon
                                                        className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"}
                                                        onClick={() => addRange("SUNDAY")}></PlusCircleIcon>
                                                    }
                                                </div>
                                            )
                                            :
                                            <div className={"text-slate-400"}>Not available</div>

                                    }
                                </div>
                                {timeFrame.SUNDAY.length === 0 &&  <PlusCircleIcon className={"w-7 h-7 mt-1 text-slate-600 cursor-pointer hover:text-slate-900"} onClick={()=>addRange("SUNDAY")}></PlusCircleIcon>}

                            </div>

                        </div>
                        <hr
                            className="my-0 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50"/>
                        {/*<div>*/}
                        {/*    <div className={"flex gap-4"}>*/}
                        {/*        <div className={"w-8"}>*/}
                        {/*            <ArrowsRightLeftIcon></ArrowsRightLeftIcon>*/}
                        {/*        </div>*/}
                        {/*        <div>*/}
                        {/*            <div className={"text-sm mb-5"}>*/}
                        {/*                The maximum number of days students can book a session with you prior to the session's commencement:*/}
                        {/*                <div className={"mt-2"}>*/}
                        {/*                    <input type="number" className={"border-0 text-sm bg-gray-100 focus:bg-gray-200 rounded-md w-20 focus:ring-0"}/>*/}
                        {/*                    <span className={"px-2"}>days</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className={"text-sm"}>*/}
                        {/*                The minimum number of hours students can book a session with you prior to the session's commencement:*/}
                        {/*                <div className={"mt-2"}>*/}
                        {/*                    <input type="number" className={"border-0 text-sm bg-gray-100 focus:bg-gray-200 rounded-md w-20 focus:ring-0"}/>*/}
                        {/*                    <span className={"px-2"} >hours</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}

                        {/*    </div>*/}



                        {/*</div>*/}

                        {/*<div className={"relative bottom-0"}>*/}
                        <div>
                            <p className={"text-sm text-gray-500 mb-4"}>Changes will not effect your current bookings</p>
                            <BasicButton text={"Save"}></BasicButton>
                        </div>
                    </div>
                    <div className={"w-7/12 h-full"}>
                        <div className={"h-[80vh]"}>
                            <CalendarPreview timeFrames={timeFrame}></CalendarPreview>
                        </div>
                    </div>

                </div>
            </div>
            <WorkhourRangesModal open={true} setOpen={()=>{}}></WorkhourRangesModal>

        </div>

    );
}