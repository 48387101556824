import React, {useContext, useState} from "react";
import {CheckCircleIcon, ClipboardDocumentIcon, EllipsisHorizontalIcon} from "@heroicons/react/20/solid";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import UserContext from "../auth/UserContext";
import EditCourseModal from "../pages/courses/EditCourseModal";
import dataLoader from "../api/DataLoader";
import toast, { Toaster } from 'react-hot-toast';
import ProfilePicture from "./ProfilePicture";
import {useHistory} from "react-router";
import InvoiceModal from "./InvoiceModal";

function CourseCard(props) {
    const userContext = useContext(UserContext)

    const [invoiceModalOpen, setInvoiceModalOpen] = useState(false)

    let history = useHistory();

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    // const deleteCard = () =>{
    //     dataLoader.deleteLessonCard(props.courseInfo.id, userContext.authInfo.accessToken)
    //         .then(r => window.location.reload())
    // }

    const notify = () => toast.success('Your magic link is copied to the clipboard!');


    const copyToCLipboard = (text) => {
        navigator.clipboard.writeText(text);
        notify()
    }

    return (
        <div className="mt-10 flex justify-center">
            <Toaster
                position="top-right"
                reverseOrder={false}
            />

            <div className="col-span-1 w-96 divide-y divide-gray-200 rounded-lg bg-white border">
                <div>
                    <div className="flex w-full items-center justify-between space-x-6 p-6">
                        {/*<img className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"*/}
                        {/*     src={userContext.profilePicture}*/}
                        {/*     alt="" />*/}

                        <ProfilePicture userId={userContext?.userInfo?.id} name={userContext?.userInfo?.givenName} height={"h-10 w-10"}></ProfilePicture>

                        <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                                <h3 className="truncate text-sm font-medium text-gray-900">{props.courseInfo.displayName}</h3>

                                {
                                    props.courseInfo.isActive?
                                        <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                                    {"Active"}
                                </span>:
                                        <span className="inline-block flex-shrink-0 rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800">
                                    {"Inactive"}
                                </span>

                                }

                            </div>
                            <p className="mt-1 truncate text-sm text-gray-500">{props.courseInfo.title}</p>
                        </div>
                        {props.editable &&
                        <button
                            onClick={()=>history.push("/courses/edit/" + props.courseInfo.lessonId)}
                            type="button"
                            className="rounded bg-white px-3 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            Edit
                        </button>
                        }

                    </div>

                    <div className={"px-6 flex flex-row justify-between"}>
                        {
                            props.courseInfo.freeLesson?
                                <div className={"flex gap-2 text-sm"}>
                                    <CheckCircleIcon className={"text-green-500 h-5 w-5"}></CheckCircleIcon>
                                    <p className={"text-gray-500"}>The first lesson if free</p>
                                </div>
                                :
                                <div></div>
                        }

                        <div className={"text-sm text-gray-900"}>{`${props.courseInfo.price} ${props.courseInfo.currency}/hour`}</div>
                    </div>
                </div>


                <div className="mt-5">
                    <div className="rounded-md px-6 py-5 lg:justify-center sm:flex sm:items-start sm:justify-between">
                        <div className="sm:flex sm:items-start">

                            <div className="mt-3 sm:mt-0 sm:ml-4">

                                <div className="lg:w-72 mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                                        {/*<span className="lg:w-72 flex justify-between rounded-md  bg-gray-100 px-3 py-2 text-gray-700 lg:text-base sm:text-sm">*/}
                                        {/*    <div className={"w-8/12 truncate ..."}>app.abakusz.io/{props.courseInfo.lessonId}</div>*/}
                                        {/*    <div className={"text-xs flex gap-1 text-secondary cursor-pointer hover:text-secondary-alt"}*/}
                                        {/*    onClick={()=>copyToCLipboard("app.abakusz.io/"+props.courseInfo.lessonId)}*/}
                                        {/*    >*/}
                                        {/*        <ClipboardDocumentIcon className="h-5 w-5" aria-hidden="true" />*/}
                                        {/*        <p className={"pt-0.5"}>Copy link</p>*/}
                                        {/*    </div>*/}

                                        {/*</span>*/}

                                    <div className="flex space-x-2">
                                        <label
                                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 sr-only"
                                        htmlFor="link">Link</label>
                                        <input
                                        className="flex h-10 w-full rounded-md border border-input px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                                        id="link" readOnly="" value={"app.abakusz.io/" + props.courseInfo.lessonId}>
                                        </input>
                                            <button
                                                onClick={()=>copyToCLipboard("app.abakusz.io/"+props.courseInfo.lessonId)}
                                            className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-gray-100 text-secondary-foreground hover:bg-gray-200 h-10 px-4 py-2 shrink-0">Copy
                                            Link
                                        </button>
                                        </div>

                                </div>


                                <div className="mt-6">
                                    <div className="relative">
                                        <div className="absolute inset-0 flex items-center">
                                            <div className="w-full border-t border-gray-300" />
                                        </div>
                                        <div className="relative flex justify-center text-xs">
                                            <span className="bg-white px-2 text-gray-500">Or send a custum link</span>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            onClick={()=>setInvoiceModalOpen(true)}
                                            type="button"
                                            className="w-full rounded-md bg-secondary mt-2 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-secondary-alt focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                                        >
                                            Create Invitation Link
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<EditCourseModal open={courseModalOpen} setOpen={setCourseModalOpen} courseInfo={props.courseInfo}></EditCourseModal>*/}
            <InvoiceModal open={invoiceModalOpen} setOpen={()=>setInvoiceModalOpen(false)} courseInfo={props.courseInfo}></InvoiceModal>
        </div>
    );
}

export default CourseCard;