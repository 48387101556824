import React, {useContext, useEffect, useRef, useState} from 'react'
import {CheckIcon, StarIcon, XMarkIcon} from '@heroicons/react/20/solid'
import auth from "../../auth/base";
import dataLoader from "../../api/DataLoader";
import Calendar from "./Calendar";
import BasicModal from "../../components/BasicModal";
import BookingModalContent from "./BookingModalContent";
import StudentSummary from "./StudentSummary";
import UserContext from "../../auth/UserContext";
import moment from "moment";
import { nanoid } from 'nanoid'
import ProfilePicture from "../../components/ProfilePicture";
import {useHistory} from "react-router";
import {Helmet} from "react-helmet";

const reviews = { average: 4, totalCount: 1624 }

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ReservationPage(props) {
  const userContext = useContext(UserContext)
  let history = useHistory();
  const [courseInfo, setCourseInfo] = useState()
  const [profilePicture, setProfilePicture] = useState()
  const [showBookingModal, setShowBookingModal] = useState(false)
  const [timeSelected, setTimeSelected] = useState(false)
  const [bookingTime, setBookingTime] = useState()
  const [booked, setBooked] = useState(false)
  const [loading, setLoading] = useState(true)
  const isMounted = useRef(false);

  useEffect(() => {
    dataLoader.getLessonCard(props.match.params.lessonid)
        .then(res => res.data ? setCourseInfo(res.data) : history.push("/404"))

    dataLoader.saveBookingHit(props.match.params.lessonid)
  }, []);

  // useEffect(() => {
  //   if(isMounted.current) {
  //     dataLoader.getProfilePicture(courseInfo.userId)
  //         .then(res => setProfilePicture(res.data))
  //   }
  //   else{
  //     isMounted.current=true
  //   }
  // }, [courseInfo]);

  useEffect(() => {
    if(courseInfo?.userId){
      setLoading(false);
    }
  }, [courseInfo]);

  const makeAppointment = (bookingTime) =>{
    setBookingTime(bookingTime)
    setTimeSelected(true)
  }

  const bookAppointment = () => {
    setShowBookingModal(true)
    // dataLoader.saveAppointment(courseInfo?.userId, userContext.userInfo.id, bookingTime, moment(bookingTime).add(1, 'hours').format(), nanoid(9), courseInfo?.title, userContext.authInfo.accessToken)

    let timezone = localStorage.getItem("selectedTimezone")!==null? localStorage.getItem("selectedTimezone"): Intl.DateTimeFormat().resolvedOptions().timeZone


    dataLoader.saveAppointment(courseInfo?.userId, userContext.userInfo.id, moment.tz(bookingTime, timezone).format(), moment.tz(bookingTime, timezone).add(courseInfo.duration, 'minutes').format(), courseInfo?.title, userContext.authInfo.accessToken)
         .then(r => setBooked(true))
  }

  const back = () =>{
    setTimeSelected(false)
  }

  return (
    <div className="bg-white h-screen">
      <Helmet>
        <title>{"Book a session with " + courseInfo?.displayName + " - Abakusz"}</title>
      </Helmet>
      <div>
        {userContext?.userInfo?.id === courseInfo?.userId  &&
        <div className="flex flex-row justify-center gap-x-6 bg-yellow-400 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
          <p className="text-sm leading-6 text-black">
            <div className={"justify-center align-center flex gap-4"}>
              <p className={"leading-9"}>This is the preview of your booking page</p>
            </div>
          </p>
          <div className="flex flex-1 justify-end">
          </div>
        </div>
        }
      </div>
      {/*<div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">*/}

      {
        courseInfo?.isActive?


      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        {/* Product details */}
        <div className="lg:max-w-lg">
                  <div className="flex items-center gap-2">
                    {/*<img*/}
                    {/*    className="inline-block h-10 w-10 rounded-full"*/}
                    {/*    src={profilePicture}*/}
                    {/*    alt=""*/}
                    {/*/>*/}
                    <ProfilePicture userId={courseInfo?.userId} name={courseInfo?.displayName} height={"h-10"}></ProfilePicture>
                    <div className={"text-sm font-medium leading-6"}>
                      {courseInfo?.displayName}
                    </div>
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700">
        <svg className="h-1.5 w-1.5 fill-blue-500" viewBox="0 0 6 6" aria-hidden="true">
          <circle cx={3} cy={3} r={3} />
        </svg>
        Teaching Online
      </span>
                  </div>
          <div className="mt-4">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{courseInfo?.title}</h1>
          </div>

          <section aria-labelledby="information-heading" className="mt-4">
            <h2 id="information-heading" className="sr-only">
              Product information
            </h2>

            <div className="flex items-center">
              <p className="text-lg text-slate-900 sm:text-xl bg-slate-100 rounded-lg px-2 py-1">{`${courseInfo?.price} ${courseInfo?.currency}`}</p>

              {/*<div className="ml-4 border-l border-gray-300 pl-4">*/}
              {/*  <h2 className="sr-only">Reviews</h2>*/}
              {/*  <div className="flex items-center">*/}
              {/*    <div>*/}
              {/*      <div className="flex items-center">*/}
              {/*        {[0, 1, 2, 3, 4].map((rating) => (*/}
              {/*          <StarIcon*/}
              {/*            key={rating}*/}
              {/*            className={classNames(*/}
              {/*              reviews.average > rating ? 'text-yellow-400' : 'text-gray-300',*/}
              {/*              'h-5 w-5 flex-shrink-0'*/}
              {/*            )}*/}
              {/*            aria-hidden="true"*/}
              {/*          />*/}
              {/*        ))}*/}
              {/*      </div>*/}
              {/*      <p className="sr-only">{reviews.average} out of 5 stars</p>*/}
              {/*    </div>*/}
              {/*    <p className="ml-2 text-sm text-gray-500">{reviews.totalCount} reviews</p>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="ml-4 border-l border-gray-300 pl-4 flex gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <p>{courseInfo?.duration} minutes</p>
              </div>


            </div>

            <div className="mt-4 space-y-6 max-h-96 overflow-y-auto">
              <p className="text-base text-gray-500 whitespace-pre-line">{courseInfo?.description}</p>
            </div>
            {courseInfo?.freeLesson ?
                <div className="mt-6 flex items-center">
                  <CheckIcon className="h-5 w-5 flex-shrink-0 text-green-500" aria-hidden="true" />
                  <p className="ml-2 text-sm text-gray-500">The first introductory lesson is free</p>
                </div>
                :
                <div></div>
            }

          </section>
        </div>

        {loading ?
            <div>Loading</div>
        :
            <div>
              {timeSelected ?
                  <StudentSummary bookAppointment={bookAppointment} bookingTime={bookingTime} back={back}></StudentSummary>
                  :
                  <Calendar makeAppointment={makeAppointment} userId={courseInfo?.userId} courseId={courseInfo?.lessonId} courseInfo={courseInfo}></Calendar>
              }
            </div>
        }

        <BasicModal show={showBookingModal} closeModal={() => {}}>
          <BookingModalContent booked={booked}></BookingModalContent>
        </BasicModal>

      </div>
            :
            <div className={"text-center text-lg font-semibold my-12 w-96 m-auto"}>This course is inactive at the moment. For more information, please contact the teacher.</div>
      }
    </div>
  )
}