import {useContext, useEffect, useRef, useState} from 'react'
import UserContext from "../../auth/UserContext";
import WherebyEmbedded from "./WherebyEmbedded";

import "./Classroom.scss"
import {useHistory} from "react-router";
import ClassroomLeaveScreen from "./ClassroomLeaveScreen";
import dataLoader from "../../api/DataLoader";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ClassroomV2(props) {
    const userContext = useContext(UserContext)
    let history = useHistory();

    const [wherebyURL, setWherebyURL] = useState();
    const [leftRoom, setLeftRoom] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        let roomName = window.location.pathname.slice(window.location.pathname.lastIndexOf("/") , window.location.pathname.length).replace("/", "") ;
        dataLoader.getClassroomUrl(roomName, userContext.authInfo.accessToken).then(r => setWherebyURL(r.data))

    }, []);

    useEffect(() => {
        if(wherebyURL){
            setLoading(false)
        }

    }, [wherebyURL]);

    // const elm = document.querySelector("whereby-embed");

    function leaveRoom(event) {
        setLeftRoom(true);
    }
    // elm?.addEventListener("leave", leaveRoom)

    return (
        <div className="bg-white h-screen w-full">
            <div className={"flex flex-col h-full w-full"}>
                {loading?
                    <div>Loading</div>
                :
                    <div>
                        {
                            leftRoom ?
                                <ClassroomLeaveScreen backToClassroom={()=>window.location.reload()}></ClassroomLeaveScreen>
                                :
                                <WherebyEmbedded roomURL={wherebyURL} name={userContext.userInfo.givenName} leaveRoom={leaveRoom}></WherebyEmbedded>

                        }
                    </div>
                }

            </div>
        </div>
    )
}