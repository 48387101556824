import axios from "axios";
import Hashids from "hashids";

const SERVICE_URL = "https://api.abakusz.io";
// const SERVICE_URL = "http://localhost:8080";

class DataLoader {

    getRoomName(){
        let hashids = new Hashids("life is very short and anxious for those who forget the past, neglect the present, and fear the future", 7);
        return hashids.encode(Math.floor(Math.random() * (10000 - 1) + 1));
    }

    loadNextAppment(userId, accessToken){
        return axios.get(SERVICE_URL + "/api/appointments/getnextappointment/" + userId,
            {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}}
            )
    }

    getNextAppointments(userId, accessToken){
        return axios.get(SERVICE_URL + "/api/appointments/getnextappointments/" + userId,
            {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}}
        )
    }

    getPrevAppointments(userId, accessToken){
        return axios.get(SERVICE_URL + "/api/appointments/getprevappointments/" + userId,
            {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}}
        )
    }

    getAppointmentsWaitingForApproval(userId, accessToken){
        return axios.get(SERVICE_URL + "/api/appointments/getwaitingforapproval/" + userId,
            {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}}
        )
    }

    //todo check if appointment is for user (now we can approve others appointments)
    //todo create bean as data
    approveAppointment(id, accessToken){
        return axios.post(SERVICE_URL + "/api/appointments/approveappointment/" + id,{},{withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    cancelAppointment(id, accessToken){
        return axios.post(SERVICE_URL + "/api/appointments/cancelappointment/" + id,{},{withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    getTwilioToken(userName, roomName, accessToken){
        return axios.get(SERVICE_URL + "/api/classroom/gettoken?username=" + userName + "&room=" + roomName, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    // getClassroomUrl(connectionId, accessToken){
    //     return axios.get(SERVICE_URL + "/api/classroom/geturl/" + connectionId, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    // }

    getClassroomUrl(roomName, accessToken){
        return axios.get(SERVICE_URL + "/api/classroom/geturl/" + roomName, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    getWorkinghours(userId, timezone){
        return axios.get(SERVICE_URL + "/api/user/getworkhours/" + userId +"/"+timezone.replace('/', '-'));
    }

    saveAllWorkinghours(userId, workingHours, accessToken){
        return axios.post(SERVICE_URL + "/api/user/saveworkhours/" + userId, workingHours,{withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    getCourseSettings(userId, accessToken){
        return axios.get(SERVICE_URL + "/api/user/getcoursesettings/" + userId, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}}) ;
    }

    getTeacherValidation(userId, accessToken){
        return axios.get(SERVICE_URL + "/api/user/validate/" + userId, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}}) ;
    }

    // getAppointmentsInTimeframe(userId, startDate, endDate){
    //     return axios.get(SERVICE_URL + "/api/appointments/getappointmentsintimeframe/" + userId + "/dates?startDate=" + startDate + "&endDate="+ endDate);
    // }

    getOpenTime(userId, startDate, endDate, timezone, courseId){
        return axios.get(SERVICE_URL + "/api/appointments/getopentime/" + userId + "/dates?startDate=" + startDate + "&endDate="+ endDate + "&timezone="+ timezone.replace('/', '-')+ "&courseId="+courseId);
    }

    getAppointmentByRoomName(roomName, accessToken){
        return axios.get(SERVICE_URL + "/api/appointments/getappointmentbyroomname/" + roomName, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    saveAppointment(teacherId, studentId, startDate, endDate, lessonTitle, accessToken){
        return axios.post(SERVICE_URL + "/api/appointments/saveapointment/",{teacherId: teacherId, studentId: studentId, connectionId: "", startDate: startDate, endDate: endDate, roomName: this.getRoomName(), lessonTitle: lessonTitle, state: "WAITING_FOR_APPROVAL"},{withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    search(phrase){
        return axios.get(SERVICE_URL + "/api/lessons/search?search=" + phrase);
    }

    getLessonCard(lessonId){
        return axios.get(SERVICE_URL + "/api/lessons/getlessoncard/" + lessonId);
    }

    getLessonCardsForUser(userId, accessToken){
        return axios.get(SERVICE_URL + "/api/lessons/getlessoncardsforuser/?userId=" + userId, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    getAllLessonCard(){
        return axios.get(SERVICE_URL + "/api/lessons/getalllessoncard", {});
    }

    getUserInfo(userId, accessToken){
        return axios.get(SERVICE_URL + "/api/getuserinfo/?userId=" + userId, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    saveProfilePicture(userId, extension, accessToken){
        return axios.put(SERVICE_URL + "/api/saveprofilepicture/",{userId: userId, extension: extension},{withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    getProfilePicture(userId, givenName){
        // console.log(axios.get(SERVICE_URL + "/api/getprofilepicture?userId=" + userId, {}))
        // return axios.get(SERVICE_URL + "/api/getprofilepicture?userId=" + userId, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
        // return axios.get(SERVICE_URL + "/api/getprofilepicture?userId=" + userId, {withCredentials: true}).then(res => this.getOrDefaultProfilePicture(res.data, givenName));
        return axios.get(SERVICE_URL + "/api/getprofilepicture?userId=" + userId, {withCredentials: true});

        // return this.getOrDefaultProfilePicture(pictureURL, givenName);
    }

    saveUserIntroduction(userId, userIntroduction, accessToken){
        return axios.post(SERVICE_URL + "/api/saveuserintroduction/",{userId: userId, userIntroduction: userIntroduction},{withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    getUserIntroduction(userId){
        return axios.get(SERVICE_URL + "/api/getuserintroduction?userId=" + userId, {});

    }

    saveLessonCards(lessonCards, accessToken){
        return axios.post(SERVICE_URL + "/api/lessons/savealllessoncards", lessonCards,{withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    saveLessonCard(lessonCard, accessToken){
        return axios.post(SERVICE_URL + "/api/lessons/savelessoncard", lessonCard,{withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    deleteLessonCard(id, accessToken){
        return axios.post(SERVICE_URL + "/api/lessons/deletelessoncard?id=" + id,{withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    modifyLessonCard(id, lessonCard, accessToken){
        // console.log(lessonCard)
        return axios.post(SERVICE_URL + "/api/lessons/modifylessoncard?id=" + id, lessonCard,{withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    sendMessage(message, accessToken){
        return axios.post(SERVICE_URL + "/api/sendmessage", message,{withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    getMessages(userId, accessToken){
        return axios.get(SERVICE_URL + "/api/getmessages?userId=" + userId, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    getNumberOfUnreadMessages(userId, accessToken){
        return axios.get(SERVICE_URL + "/api/getnumberofunread?userId=" + userId, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    markMessagesAsSeen(messageIds, accessToken){
        return axios.post(SERVICE_URL + "/api/markmessageasseen", messageIds, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    createWorkspace(userId, partnerId, lessonId, classroomName, accessToken){
        return axios.post(SERVICE_URL + "/api/createworkspace", {userId: userId, partnerId: partnerId, lessonId: lessonId, classroomName: classroomName}, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    getUploadFileUrl(extension, displayName, title, authorId, workspaceChapterId, accessToken){
        let uploadObject = {
            extension: extension,
            displayName: displayName,
            title: title,
            authorId: authorId,
            workspaceChapterId: workspaceChapterId
        }

        return axios.put(SERVICE_URL + "/api/files", uploadObject, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    getDownloadFileUrl(fileName, accessToken){
        //TODO remove extensions
        fileName = fileName +".html"
        return axios.get(SERVICE_URL + "/api/files?fileName=" + fileName);
    }

    dataURItoBlob(dataURI) {
        let binary = atob(dataURI.split(',')[1]);
        let array = [];
        for(let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: 'image/png'});
    }

    uploadFileToAWS(url, file, contentType){
        // if(true){
        //     return axios.put(url, file,{
        //         headers: {
        //             'Content-Encoding':'base64',
        //             'Content-Type': contentType,
        //         }
        //     });
        // }
        //
        // return axios.put(url, file);

        const blob = this.dataURItoBlob(file);
        return axios.put(url, blob);
    }

    loadFileFromAws(url){
        return axios.get(url);
    }

    getWorkspaceConnections(userId, accessToken){
        return axios.get(SERVICE_URL + "/api/getworkspaceconnections?userId=" + userId, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    getWorkspaceChapters(workspaceId, accessToken){
        return axios.get(SERVICE_URL + "/api/getworkspacechapters?workspaceId=" + workspaceId, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    getWorkspaceChapterContentMeta(chapterId, accessToken){
        return axios.get(SERVICE_URL + "/api/gethaptercontentmeta?chapterId=" + chapterId, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    getGoolgeDriveWorkspaces(userId, accessToken){
        return axios.get(SERVICE_URL + "/api/workspaces/getdriveworkspaces/" + userId, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }

    getFreeClassroom(roomName){
        return axios.get(SERVICE_URL + "/api/videochat/getfreeroom/" + roomName);
    }

    createFreeClassroom(){
        return axios.get(SERVICE_URL + "/api/videochat/createfreeroom");
    }

    saveBookingHit(lessonId){
        return axios.post(SERVICE_URL + "/api/analytics/bookinghit",{teacherId: "", lessonId: lessonId});
    }

    getBookingHits(userId,  accessToken){
        return axios.get(SERVICE_URL + "/api/analytics/bookinghit/" + userId, {withCredentials: true, headers: {"Authorization": "Bearer " + accessToken}});
    }
}

const dataLoader = new DataLoader();
export default dataLoader;