// import { withRouter } from 'react-router-dom';
import Navbar from "./pages/Navbar";
import UserContext from "./auth/UserContext";
import {useContext} from "react";
import WaitingRoomNavbar from "./pages/WaitingRoomNavbar";
const HiddenNavbar = (props) => {
    const userContext = useContext(UserContext)

    const { location } = props;
    if (location.pathname.match(/classroom/)){
        return null;
    }
    else if (location.pathname.match(/signup/)){
        return null;
    }
    else if (location.pathname.match(/login/)){
        return null;
    }

    else if (location.pathname.match(/calendar/)){
        return null;
    }

    else if (location.pathname.match(/makeappointment/)){
        return null;
    }

    else if (location.pathname.match(/videochat/)){
        return null;
    }

    else if (location.pathname.match(/room/)){
        // return (<WaitingRoomNavbar {...props}></WaitingRoomNavbar>)
        return null;
    }

    else if (location.pathname.match(/invite/)){
        return null;
    }

    return (
        <Navbar {...props}></Navbar>
    )
}

// const hiddenNavbar = withRouter(HiddenNavbar);
export default HiddenNavbar;