import React, {useEffect, useState} from "react";
import FreeClassroom from "./FreeClassroom";
import dataLoader from "../../api/DataLoader";
import toast, {Toaster} from "react-hot-toast";
import {XMarkIcon} from "@heroicons/react/20/solid";
import TrustBox from "./TrustBox";


const FreeClassroomContainer = (props) => {
    // const elm = document.querySelector("whereby-embed");
    // function leaveRoom(event) {
    //     alert("dsds")
    //
    // }
    // elm?.addEventListener("leave", leaveRoom)

    const [roomURL, setRoomURL] = useState();
    const [loading, setLoading] = useState(true);
    const [leftRoom, setLeftRoom] = useState(false);
    const [closeShareLink, setCloseShareLink] = useState(false);

    const notify = () => toast.success('Your classroom link has been copied to the clipboard!');


    const copyToClipboard = (e, text) => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
        notify()
    }

    useEffect(() => {
        let roomName = window.location.pathname.slice(window.location.pathname.lastIndexOf("/") , window.location.pathname.length).replace("/", "") ;
        dataLoader.getFreeClassroom(roomName).then(r => setRoomURL(r.data.roomURL))

    }, []);

    useEffect(() => {
        if(roomURL){
            setLoading(false)
        }

    }, [roomURL]);

    function leaveRoom(event) {
        setLeftRoom(true);
    }

    return <div>
        <Toaster
            position="top-right"
            reverseOrder={false}
        />

        {loading ?
            <div>asd</div>
            :
            <div className={"h-screen"}>
                {
                    leftRoom?
                        <div className={"h-screen"}>
                            {/*<iframe title="your title" style={{height: "100vh", width:"100vw"}} src={"https://form.jotform.com/233114926816356"}></iframe>*/}
                            <TrustBox></TrustBox>
                        </div>
                        :
                        <div className={"relative"}>
                            <div>
                                <FreeClassroom roomURL={roomURL} leaveRoom={leaveRoom}></FreeClassroom>
                            </div>

                            {!closeShareLink &&
                                <div className="bg-white shadow sm:rounded-lg absolute bottom-4 sm:left-4 left-0">
                                    <div className="px-4 py-5 sm:p-6">
                                        <div className={"flex justify-between"}>
                                            <h3 className="text-base font-semibold leading-6 text-gray-900">Invite people to this room!</h3>
                                            <XMarkIcon className={"w-5 hover:cursor-pointer hover:opacity-70"} onClick={()=>setCloseShareLink(true)}></XMarkIcon>
                                        </div>
                                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                                            <p>Copy the link below and send it to your partner!</p>
                                        </div>
                                        <form className="mt-5 sm:flex sm:items-center">
                                            <div className="bg-gray-100 p-1 rounded-lg w-full sm:max-w-xs">
                                                <p className={"text-sm"}>{window.location.href}</p>
                                            </div>
                                            <button
                                                onClick={(e)=>copyToClipboard(e, window.location.href)}
                                                className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:secondary-alt focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary sm:ml-3 sm:mt-0 sm:w-auto"
                                            >
                                                Copy
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            }

                        </div>
                }
            </div>

        }
    </div>
}

export default FreeClassroomContainer