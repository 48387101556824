import React, {useContext, useEffect, useState, Fragment} from "react";
import moment from "moment";
import dataLoader from "../../api/DataLoader";
import UserContext from "../../auth/UserContext";
import {ChevronLeftIcon, ChevronRightIcon, QuestionMarkCircleIcon} from "@heroicons/react/20/solid";
import {SparklesIcon} from "@heroicons/react/24/solid";
import {ShieldCheckIcon} from "@heroicons/react/24/outline";
import {Dialog, Transition} from '@headlessui/react'
import {useTimeoutFn} from "react-use";
import TimezoneSelect from "react-timezone-select";
import "./Calendar.scss"
import GradientScroll from "react-gradient-scroll-indicator";
import TimeformatToggle from "./TimeformatToggle";
import OptimiseSwitch from "../workhours-settings/OptimiseSwitch";

const TimeFormats = {
    Ampm: "h:mma",
    Full: "H:mm"
}

const Calendar = (props) => {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const userContext = useContext(UserContext)

    const [isShowing, setIsShowing] = useState(true)
    const [isShowing2, setIsShowing2] = useState(true)
    const [isEmpty, setIsEmpty] = useState(true)
    let [, , resetIsShowing] = useTimeoutFn(() => setIsShowing(true), 200)
    let [, , resetIsShowing2] = useTimeoutFn(() => setIsShowing2(true), 200)
    const [selectedTimezone, setSelectedTimezone] =useState(
        localStorage.getItem("selectedTimezone") !== null ?
            localStorage.getItem("selectedTimezone")
            :

            Intl.DateTimeFormat().resolvedOptions().timeZone
    )

    const [timeFormat, setTimeFormat] = useState(TimeFormats.Full)


    const [weeksFromNow, setWeeksFromNow] = useState(0)
    const [openTime, setOpenTime] = useState([])
    const [selectedTime, setSelectedTime] = useState()
    const [timeFrame, setTimeFrame] = useState(
        [
            moment().days(1).format("YYYY-MM-DD"),
            moment().days(2).format("YYYY-MM-DD"),
            moment().days(3).format("YYYY-MM-DD"),
            moment().days(4).format("YYYY-MM-DD"),
            moment().days(5).format("YYYY-MM-DD"),
            moment().days(6).format("YYYY-MM-DD"),
            moment().days(7).format("YYYY-MM-DD"),
        ]
    )

    useEffect(() => {
        dataLoader.getOpenTime(props.userId, timeFrame[0], timeFrame[6], selectedTimezone, props.courseId)
            .then(res => setOpenTime(convertHoursToDates(res.data)))
            // .then(res => console.log(res.data))
    }, [timeFrame, selectedTimezone]);

    useEffect(() => {
        let nextTimeFrame = [
            moment().days(weeksFromNow * 7 + 1).format("YYYY-MM-DD"),
            moment().days(weeksFromNow * 7 + 2).format("YYYY-MM-DD"),
            moment().days(weeksFromNow * 7 + 3).format("YYYY-MM-DD"),
            moment().days(weeksFromNow * 7 + 4).format("YYYY-MM-DD"),
            moment().days(weeksFromNow * 7 + 5).format("YYYY-MM-DD"),
            moment().days(weeksFromNow * 7 + 6).format("YYYY-MM-DD"),
            moment().days(weeksFromNow * 7 + 7).format("YYYY-MM-DD"),
        ];

        setTimeFrame(nextTimeFrame);
    }, [weeksFromNow]);

    function getDayNumber(dayName) {
        switch (dayName) {
            case "MONDAY":
                return 1;
            case "TUESDAY":
                return 2;
            case "WEDNESDAY":
                return 3;
            case "THURSDAY":
                return 4;
            case "FRIDAY":
                return 5;
            case "SATURDAY":
                return 6;
            case "SUNDAY":
                return 7;
            default:
                return "Invalid day name";
        }
    }

    const convertHoursToDates = (originalHours) => {
        const hours = [];

        originalHours.forEach(item => {
            const existingItem = hours.find(element => element.dayIndex === item.dayIndex);

            if (existingItem) {
                existingItem.dates = existingItem.dates.concat(item.dates);
            } else {
                hours.push({ ...item });
            }
        });



        let dates = hours.map(obj => {
            const suggestedHours = obj.dates.filter(e => e.suggested === true);


            // const multipliedDates = obj.dates.map(date => moment().days(weeksFromNow * 7 + (getDayNumber(obj.dayIndex))).set({"hour": date.hour / 60, "minute": 0}).format("YYYY-MM-DD HH"));
            // const suggestedDates = suggestedHours.map(suggested => moment().days(weeksFromNow * 7 + (getDayNumber(obj.dayIndex))).set({"hour": suggested.hour / 60, "minute": 0}).format("YYYY-MM-DD HH"));

            const multipliedDates = []

            for(let n of obj.dates){
                multipliedDates.push({hour: moment().days(weeksFromNow * 7 + (getDayNumber(obj.dayIndex))).set({"hour": n.hour / 60, "minute": n.hour % 60}).format("YYYY-MM-DD HH:mm"), suggested: n.suggested})
            }



            return {
                dayIndex: getDayNumber(obj.dayIndex),
                // dates: {hours: multipliedDates, suggestedHours: suggestedDates}
                dates: multipliedDates
                // suggested: suggestedDates
            };
        });

        return dates;
    }

    const selectTime = (selectedTime) => {
        setSelectedTime(selectedTime)
    }

    const selectTimeFormat = (selectedTimeFormat) => {
        if(selectedTimeFormat){
            setTimeFormat(TimeFormats.Full)

        }
        else{
            setTimeFormat(TimeFormats.Ampm)
        }
    }

    const makeAppointment = (e) => {
        e.stopPropagation()
        props.makeAppointment(selectedTime)
    }

    const stepToNextWeek = (e) => {
        setWeeksFromNow(weeksFromNow + 1)
        setIsShowing(false)
        setIsEmpty(true)
        resetIsShowing()
    }

    const stepToPrevWeek = (e) => {
        setWeeksFromNow(weeksFromNow - 1)
        setIsShowing2(false)
        setIsEmpty(true)
        resetIsShowing2()
    }

    const selectTimezone = (timezone) =>{
        setSelectedTimezone(timezone)
        localStorage.setItem("selectedTimezone", timezone)
    }

    const isPrevDisabled = (e) => {
        return timeFrame.includes(moment().format('YYYY-MM-DD'))
    }


    const isTimeDisabled = (time) => {
        // return moment(time).isBefore(moment().tz(selectedTimezone)) && moment().tz(selectedTimezone).isAfter(moment(time, "YYYY-MM-DD HH:mm").subtract(5, 'hours'))

        let isDisabled = moment().tz(selectedTimezone).isAfter(moment(time, "YYYY-MM-DD HH:mm").subtract(props.courseInfo.minimumNotice, 'hours'))

        if(!isDisabled && isEmpty){
            setIsEmpty(false)
        }

        return isDisabled
    }

    return (
        <div className="mt-10 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center">
            <section aria-labelledby="options-heading">
                <div>
                    <div className="">
                        <div className="flex items-center">

                            <h2 className="flex-auto font-semibold text-gray-900">
                                {`${moment(timeFrame[0]).format('MMMM D')} - ${moment(timeFrame[6]).format('MMMM D, YYYY')}`}
                            </h2>
                            <div className={"mx-4"}>
                                <TimeformatToggle
                                    setTimeFormat={selectTimeFormat}
                                ></TimeformatToggle>
                            </div>
                            <button
                                onClick={()=>stepToPrevWeek()}
                                type="button"
                                disabled={isPrevDisabled()}
                                // className="-my-1.5 flex disabled:bg-blue-300 flex-none items-center justify-center p-1 text-white hover:bg-secondary-alt bg-secondary rounded-lg"
                                className="-my-1.5 flex disabled:text-slate-400 disabled:border-slate-400 flex-none items-center justify-center p-1 text-gray-800 border border-gray-800 hover:text-gray-400 hover:border-gray-400 rounded-lg"
                            >
                                <span className="sr-only">Previous week</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                            <button
                                onClick={()=>stepToNextWeek()}
                                type="button"
                                // className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1 text-white hover:bg-secondary-alt bg-secondary rounded-lg"
                                className="-my-1.5 -mr-1.5 ml-2 flex disabled:text-slate-400 disabled:border-slate-400 flex-none items-center justify-center p-1 text-gray-800 border border-gray-800 hover:text-gray-400 hover:border-gray-400 rounded-lg"

                            >
                                <span className="sr-only">Next week</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>

                        <Transition
                            show={isShowing}
                            enter="transform transition duration-[400ms]"
                            enterFrom="translate-x-5 opacity-5"
                            enterTo="translate-x-0 opacity-100"
                            leave="transform duration-200 transition ease-in-out"
                            leaveFrom="translate-x-0 opacity-100"
                            leaveTo="-translate-x-5 opacity-5"
                        >

                            <Transition
                                show={isShowing2}
                                enter="transform transition duration-[400ms]"
                                enterFrom="translate-x-5 opacity-5"
                                enterTo="translate-x-0 opacity-100"
                                leave="transform duration-200 transition ease-in-out"
                                leaveFrom="-translate-x-0 opacity-100"
                                leaveTo="translate-x-5 opacity-5"
                            >

                        <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
                            <div className={"md:flex justify-center"}>
                                <div>Mon</div>
                                <span className="items-center justify-center font-semibold text-gray-900 px-1">
                                    {`${moment(timeFrame[0]).format('DD')}`}
                                </span>
                            </div>
                            <div className={"md:flex justify-center"}>
                                <div>Tue</div>
                                <span className="items-center justify-center font-semibold text-gray-900 px-1">
                                    {`${moment(timeFrame[1]).format('DD')}`}
                                </span>
                            </div>
                            <div className={"md:flex justify-center"}>
                                <div>Wed</div>
                                <span className="items-center justify-center font-semibold text-gray-900 px-1">
                                    {`${moment(timeFrame[2]).format('DD')}`}
                                </span>
                            </div>
                            <div className={"md:flex justify-center"}>
                                <div>Thu</div>
                                <span className="items-center justify-center font-semibold text-gray-900 px-1">
                                    {`${moment(timeFrame[3]).format('DD')}`}
                                </span>
                            </div>
                            <div className={"md:flex justify-center"}>
                                <div>Fri</div>
                                <span className="items-center justify-center font-semibold text-gray-900 px-1">
                                    {`${moment(timeFrame[4]).format('DD')}`}
                                </span>
                            </div>
                            <div className={"md:flex justify-center"}>
                                <div>Sat</div>
                                <span className="items-center justify-center font-semibold text-gray-900 px-1">
                                    {`${moment(timeFrame[5]).format('DD')}`}
                                </span>
                            </div>
                            <div className={"md:flex justify-center"}>
                                <div>Sun</div>
                                <span className="items-center justify-center font-semibold text-gray-900 px-1">
                                    {`${moment(timeFrame[6]).format('DD')}`}
                                </span>
                            </div>
                        </div>

                        {/*<div className="mt-2 grid grid-cols-7 text-sm h-72 overflow-scroll">*/}
                        {/*<div className="calendar mt-2 grid grid-cols-7 text-sm overflow-scroll">*/}
                        <div className="calendar">
                            {isEmpty?
                                <div
                                    className="bg-gray-50 relative block w-full h-full rounded-lg border-2 border-solid border-gray-300 p-12 text-center"
                                >

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         stroke-width="1.5" stroke="currentColor" className="mx-auto h-12 w-12 text-gray-400"
                                    >
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                    </svg>

                                    <span className="mt-2 block text-sm font-semibold text-gray-900">Tutor is not available this period</span>
                                    <span className="mt-2 block text-xs text-secondary cursor-pointer"
                                          onClick={()=>stepToNextWeek()}
                                    >Try next week</span>
                                </div>
                                :
                                <div></div>


                            }
                                <GradientScroll>

                                <div className="mt-2 grid grid-cols-7 text-sm">
                            <div>
                                {
                                    openTime.find(e => e.dayIndex === 1)?.dates.map((day) => (
                                        <div key={day.hour}>
                                            <button
                                                onClick={(e)=>selectTime(day.hour)}
                                                type="button"
                                                disabled={isTimeDisabled(day.hour)}
                                                className={classNames(
                                                    day.hour === selectedTime && 'text-white',
                                                    day.suggested && 'underline decoration-2 underline-offset-4 decoration-secondary',
                                                    day.hour !== selectedTime  && 'text-secondary sm:bg-secondary-alt2 text-sm sm:text-sm',
                                                    day.hour === selectedTime && 'bg-green-500',
                                                    day.hour !== selectedTime  && 'hover:border border-secondary',
                                                    day.hour === selectedTime  && 'font-semibold',
                                                    'disabled:line-through disabled:hidden disabled:border-none my-3 mx-auto flex h-5 sm:h-8 sm:px-3 px-1 items-center justify-center rounded-full'
                                                )}
                                            >
                                                <time dateTime={day.hour}>{moment(day.hour).format(timeFormat)}</time>
                                            </button>
                                        </div>
                                    ))

                                }
                            </div>
                            <div>
                                {
                                    openTime.find(e => e.dayIndex === 2)?.dates.map((day) => (
                                        <div key={day.hour}>
                                            <button
                                                onClick={(e)=>selectTime(day.hour)}
                                                type="button"
                                                disabled={isTimeDisabled(day.hour)}
                                                className={classNames(
                                                    day.hour === selectedTime && 'text-white',
                                                    day.suggested && 'underline decoration-2 underline-offset-4 decoration-secondary',
                                                    day.hour !== selectedTime  && 'text-secondary sm:bg-secondary-alt2 text-sm sm:text-sm',
                                                    day.hour === selectedTime && 'bg-green-500',
                                                    day.hour !== selectedTime  && 'hover:border border-secondary',
                                                    day.hour === selectedTime  && 'font-semibold',
                                                    'disabled:line-through disabled:hidden disabled:border-none my-3 mx-auto flex h-5 sm:h-8 sm:px-3 px-1 items-center justify-center rounded-full'
                                                )}
                                            >
                                                <time dateTime={day.hour}>{moment(day.hour).format(timeFormat)}</time>
                                                {/*<div>{moment(day.hour).isBefore(moment().tz(selectedTimezone))}</div>*/}
                                                {/*<div>{console.log(moment(day.hour).isBefore(moment().tz(selectedTimezone)))}</div>*/}
                                            </button>
                                        </div>
                                    ))
                                }
                            </div>
                            <div>
                                {
                                    openTime.find(e => e.dayIndex === 3)?.dates.map((day) => (
                                        <div key={day.hour}>
                                            <button
                                                onClick={(e)=>selectTime(day.hour)}
                                                type="button"
                                                disabled={isTimeDisabled(day.hour)}
                                                className={classNames(
                                                    day.hour === selectedTime && 'text-white',
                                                    day.suggested && 'underline decoration-2 underline-offset-4 decoration-secondary',
                                                    day.hour !== selectedTime  && 'text-secondary sm:bg-secondary-alt2 text-sm sm:text-sm',
                                                    day.hour === selectedTime && 'bg-green-500',
                                                    day.hour !== selectedTime  && 'hover:border border-secondary',
                                                    day.hour === selectedTime  && 'font-semibold',
                                                    'disabled:line-through disabled:hidden disabled:border-none my-3 mx-auto flex h-5 sm:h-8 sm:px-3 px-1 items-center justify-center rounded-full'
                                                )}
                                            >
                                                <time dateTime={day.hour}>{moment(day.hour).format(timeFormat)}</time>
                                            </button>
                                        </div>
                                    ))
                                }
                            </div>
                            <div>
                                {
                                    openTime.find(e => e.dayIndex === 4)?.dates.map((day) => (
                                        <div key={day.hour}>
                                            <button
                                                onClick={(e)=>selectTime(day.hour)}
                                                type="button"
                                                disabled={isTimeDisabled(day.hour)}
                                                className={classNames(
                                                    day.hour === selectedTime && 'text-white',
                                                    day.suggested && 'underline decoration-2 underline-offset-4 decoration-secondary',
                                                    day.hour !== selectedTime  && 'text-secondary sm:bg-secondary-alt2 text-sm sm:text-sm',
                                                    day.hour === selectedTime && 'bg-green-500',
                                                    day.hour !== selectedTime  && 'hover:border border-secondary',
                                                    day.hour === selectedTime  && 'font-semibold',
                                                    'disabled:line-through disabled:hidden disabled:border-none my-3 mx-auto flex h-5 sm:h-8 sm:px-3 px-1 items-center justify-center rounded-full'
                                                )}
                                            >
                                                <time dateTime={day.hour}>{moment(day.hour).format(timeFormat)}</time>
                                            </button>
                                        </div>
                                    ))
                                }
                            </div>
                            <div>
                                {
                                    openTime.find(e => e.dayIndex === 5)?.dates.map((day) => (
                                        <div key={day.hour}>
                                            <button
                                                onClick={(e)=>selectTime(day.hour)}
                                                type="button"
                                                disabled={isTimeDisabled(day.hour)}
                                                className={classNames(
                                                    day.hour === selectedTime && 'text-white',
                                                    day.suggested && 'underline decoration-2 underline-offset-4 decoration-secondary',
                                                    day.hour !== selectedTime  && 'text-secondary sm:bg-secondary-alt2 text-sm sm:text-sm',
                                                    day.hour === selectedTime && 'bg-green-500',
                                                    day.hour !== selectedTime  && 'hover:border border-secondary',
                                                    day.hour === selectedTime  && 'font-semibold',
                                                    // 'disabled:line-through disabled:hidden disabled:border-none mx-auto flex h-8 3x-2 items-center justify-center roundfull-md'
                                                    // 'disabled:hidden mx-auto flex h-8 px-2 items-center justify-center rounded-md'
                                                    'disabled:line-through disabled:hidden disabled:border-none my-3 mx-auto flex h-5 sm:h-8 sm:px-3 px-1 items-center justify-center rounded-full'

                                                )}
                                            >
                                                <time dateTime={day.hour}>{moment(day.hour).format(timeFormat)}</time>
                                            </button>
                                        </div>
                                    ))
                                }
                            </div>
                            <div>
                                {
                                    openTime.find(e => e.dayIndex === 6)?.dates.map((day) => (
                                        <div key={day.hour}>
                                            <button
                                                onClick={(e)=>selectTime(day.hour)}
                                                type="button"
                                                disabled={isTimeDisabled(day.hour)}
                                                className={classNames(
                                                    day.hour === selectedTime && 'text-white',
                                                    day.suggested && 'underline decoration-2 underline-offset-4 decoration-secondary',
                                                    day.hour !== selectedTime  && 'text-secondary sm:bg-secondary-alt2 text-xs sm:text-sm',
                                                    day.hour === selectedTime && 'bg-green-500',
                                                    day.hour !== selectedTime  && 'hover:border border-secondary',
                                                    day.hour === selectedTime  && 'font-semibold',
                                                    'disabled:line-through disabled:hidden disabled:border-none my-3 mx-auto flex h-5 sm:h-8 sm:px-3 px-1 items-center justify-center rounded-full'
                                                )}
                                            >
                                                <time dateTime={day.hour}>{moment(day.hour).format(timeFormat)}</time>
                                            </button>
                                        </div>
                                    ))
                                }
                            </div>
                            <div>
                                {
                                    openTime.find(e => e.dayIndex === 7)?.dates.map((day) => (
                                        <div key={day.hour}>
                                            <button
                                                onClick={(e)=>selectTime(day.hour)}
                                                type="button"
                                                disabled={isTimeDisabled(day.hour)}
                                                className={classNames(
                                                    day.hour === selectedTime && 'text-white',
                                                    day.suggested && 'underline decoration-2 underline-offset-4 decoration-secondary',
                                                    day.hour !== selectedTime  && 'text-secondary sm:bg-secondary-alt2 text-sm sm:text-sm',
                                                    day.hour === selectedTime && 'bg-green-500',
                                                    day.hour !== selectedTime  && 'hover:border border-secondary',
                                                    day.hour === selectedTime  && 'font-semibold',
                                                    'disabled:line-through disabled:hidden disabled:border-none my-3 mx-auto flex h-5 sm:h-8 sm:px-3 px-1 items-center justify-center rounded-full'
                                                )}
                                            >
                                                <time dateTime={day.hour}>{moment(day.hour).format(timeFormat)}</time>
                                            </button>
                                        </div>
                                    ))
                                }
                            </div>
                                </div>

                                </GradientScroll>

                        </div>

                            </Transition>
                        </Transition>

                    </div>

                    <div className={"w-full flex gap-2 justify-end px-2 pt-4"}>
                        <SparklesIcon className={"w-5 h-5"}></SparklesIcon>
                        <p className={"text-sm"}>Preferred hours are <span className={"underline decoration-2 underline-offset-4 decoration-secondary"}>highlighted</span></p>
                    </div>
                    <div className="mt-4 flex gap-4 justify-between">
                        <a href="https://www.craft.do/s/tbfLeVHvwxWfNw" target={"_blank"} className="group inline-flex text-sm text-gray-500 hover:text-gray-700 my-2 hidden sm:flex">
                            <span>How does it work?</span>
                            <QuestionMarkCircleIcon
                                className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                        </a>

                        <div className={"flex gap-2 text-sm px-2 w-full sm:w-6/12"}>
                            {/*<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">*/}
                            {/*    <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />*/}
                            {/*</svg>*/}
                            {/*<div className={"w-96"}>*/}
                            <div className={"w-full xl:w-96"}>
                                <TimezoneSelect
                                value={selectedTimezone}
                                onChange={(e) => selectTimezone(e.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <button
                            disabled={!selectedTime || userContext?.userInfo?.id === props.userId}
                            onClick={(e)=>makeAppointment(e)}
                            type="submit"
                            className="flex w-full items-center justify-center rounded-md border border-transparent bg-secondary py-3 px-8 text-base font-medium text-white hover:bg-secondary-alt disabled:bg-blue-300"
                        >
                            {/*Confirm time*/}

                            {userContext?.userInfo?.id !== props.userId ?
                                <>
                                    {selectedTime?
                                    // `Book a session on ${moment(selectedTime).format("dddd HH:mm")}`
                                    `Book a session on ${moment(selectedTime).format("dddd" + " " + timeFormat)}`
                                    :
                                    `Select a date`
                                }</>
                                :
                                    `Not possible to book a session with yourself`

                            }



                        </button>
                    </div>
                    <div className="mt-6 text-center">
                        <a href={"https://www.craft.me/s/IMdzEh8ikRiyPv"} target={"_blank"} className="group inline-flex text-base font-medium">
                            <ShieldCheckIcon
                                className="mr-2 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                            <span className="text-gray-500 hover:text-gray-700">Protected by Abakusz</span>
                        </a>
                    </div>
                </div>
            </section>

            {/*<button onClick={() => {*/}
            {/*    setIsShowing(false)*/}
            {/*    resetIsShowing()*/}
            {/*}}>*/}
            {/*    Toggle*/}
            {/*</button>*/}
        </div>
    )
}

export default Calendar;