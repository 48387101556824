import React, {useContext, useEffect, useRef, useState} from 'react'
import UserContext from "../../auth/UserContext";

import "./Classroom.scss"
import {useHistory} from "react-router";
import dataLoader from "../../api/DataLoader";
import WorkspaceCard from "../resources/WorkspaceCard";
import BasicButton from "../../components/BasicButton";
import driveIcon from "../../images/google_drive.svg";
import {FullPageLoading} from "../../FullPageLoading";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ClassroomLeaveScreen(props) {
    const userContext = useContext(UserContext)
    let history = useHistory();

    const [workspace, setWorkspaceInfo] = useState();

    useEffect(() => {
        dataLoader.getGoolgeDriveWorkspaces(userContext.userInfo.id, userContext.authInfo.accessToken)
            .then(res => setWorkspaceInfo(res.data))

    }, []);



    return (
        <div className="bg-white h-screen w-full absolute">
            <div className={"absolute top-5 left-5 w-full"}>
                <img
                    className="hidden h-12 w-auto lg:block"
                    src={process.env.PUBLIC_URL + '/primary-logo.svg'}
                    alt="Your Company"
                />
            </div>
            {workspace ?
                <div className={"max-w-7xl mx-auto my-32"}>
                    <div className={"w-6/12 flex flex-col mx-auto gap-12"}>


                    <div>
                        <h2 className={"text-2xl font-bold my-8 text-center text-slate-800"}>You left this Classroom</h2>
                        {/*<div className={"flex gap-4 w-max mx-auto"}>*/}
                        <div className={"flex gap-4 w-96 mx-auto"}>
                            <button
                                type="button"
                                onClick={()=>props.backToClassroom()}
                                className="w-96 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                <p className={"text-sm leading-8"}>Back to Classroom</p>
                            </button>
                            {/*<BasicButton*/}
                            {/*    text={"Schedule your next session with " + workspace[0].partnerDisplayName}></BasicButton>*/}
                            <BasicButton
                                onClick={()=>history.push("/")}
                                text={"Leave room"}></BasicButton>
                        </div>
                    </div>


                        <div>
                            <p className={"text-lg tracking-wide text-slate-700"}>Harness your dedicated Google Drive space to collaboratively create and
                                upload files together for free!</p>
                            <ul role="list" className="pt-6 mx-auto  px-2 sm:px-6 lg:px-8">
                                <WorkspaceCard partnerId={workspace[0].partnerId} shareURL={workspace[0].shareURL}
                                               name={workspace[0].partnerDisplayName}
                                               fileNames={workspace[0].fileNames}></WorkspaceCard>
                            </ul>
                        </div>

                    </div>

                </div>
                :
                    <FullPageLoading></FullPageLoading>

            }
            <div className={classNames(workspace?"bottom-10":"hidden","relative w-full text-center text-slate-500")}>support@abakusz.io</div>

        </div>
    )
}