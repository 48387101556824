import './App.css';

import LoginPage from "./pages/login/Login"
import HiddenNavbar from "./HiddenNavbar"
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";


import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faPhotoVideo,
    faMousePointer,
    faPen,
    faEraser,
    faFont,
    faVideo,
    faVideoSlash,
    faDesktop,
    faComment,
    faPlus,
    faMinus,
    faUser,
    faMicrophone,
    faMicrophoneSlash,
    faItalic,
    faBold,
    faUnderline,
    faCaretUp,
    faCaretDown,
    faWallet,
    faSearch,
    faCaretLeft,
    faCaretRight,
    faTimes,
    faCheck,
    faCheckCircle,
    faFileAlt,
    faChalkboardTeacher,
    faArrowRight,
    faCoins,
    faTrash,
    faHome,
    faCalendarAlt,
    faWalking,
    faHeart,
    faArrowCircleRight,
    faEnvelope,
    faChevronLeft,
    faChevronRight,
    faShareSquare,
    faFileDownload,
    faFileUpload,
    faFileInvoice, faSortAmountDown, faClock
} from '@fortawesome/free-solid-svg-icons'
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {faHeart as regularHeart} from "@fortawesome/fontawesome-free-regular";
import {AuthProvider} from "./auth/AuthProvider";
import BasicSignup from "./pages/signup/BasicSignup";
import {ProtectedRoute} from "./auth/ProtectedRoute";
import AppointmentsPage from "./pages/appointments-page/AppointmentsPage";
import UserTypeSelection from "./pages/signup/UserTypeSelection";
import TeacherOnBoarding from "./pages/signup/TeacherOnBoarding";
import StudentOnBoarding from "./pages/signup/StudentOnBoarding";
import WorkingHours from "./pages/signup/WorkingHours";
import CoursesPage from "./pages/courses/CoursesPage";
import ReservationPage from "./pages/lessonpage/ReservationPage";
import Settings from "./pages/settings/Settings";
import DiscordBanner from "./pages/DiscordBanner";
import ResourcesPage from "./pages/resources/ResourcesPage";
import ClassroomV2 from "./pages/classroom/ClassroomV2";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import WorkhoursSettings from "./pages/workhours-settings/WorkhoursSettings";
import TestAppointmentsPage from "./pages/appointments-page/TestAppointmentsPage";
import ResourcesPageTest from "./pages/resources/ResoucePageTest";
import FreeClassroomLanding from "./pages/free-classroom/FreeClassroomLanding";
import FreeClassroomContainer from "./pages/free-classroom/FreeClassroomContainer";
import TeacherChecklist from "./pages/TeacherChecklist";
import EditCourse from "./pages/edit-courses/EditCourse";
import InvitationReceived from "./pages/lessonpage/InvitationReceived";
import DashboardPage from "./pages/dashboard/DashboardPage";
// import {faTimesCircle} from "@fortawesome/free-regular-svg-icons";

library.add(
    faPhotoVideo,
    faMousePointer,
    faPen,
    faEraser,
    faFont,
    faVideo,
    faVideoSlash,
    faDesktop,
    faComment,
    faPlus,
    faMinus,
    faUser,
    faMicrophone,
    faMicrophoneSlash,
    faItalic,
    faBold,
    faUnderline,
    faCaretUp,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faWallet,
    faSearch,
    faTimes,
    faCheck,
    faCheckCircle,
    faFileAlt,
    faChalkboardTeacher,
    faArrowRight,
    faCoins,
    faTimesCircle,
    faTrash,
    faHome,
    faCalendarAlt,
    faWalking,
    regularHeart,
    faHeart,
    faArrowCircleRight,
    faEnvelope,
    faChevronLeft,
    faChevronRight,
    faShareSquare,
    faFileDownload,
    faFileUpload,
    faFileInvoice,
    faSortAmountDown,
    faClock
)

function App() {
  return (
      <Router history={"browserHistory"}>
        <div className="App flex flex-col">
            <AuthProvider>
                <Route component={DiscordBanner}/>
                <Route component={HiddenNavbar}/>
                <Route component={TeacherChecklist}/>

                <Switch>
                        {/*<LoaderLanding path={"/"} exact component={AppointmentsPage}></LoaderLanding>*/}
                        <Route path={"/login"} exact component={LoginPage}/>
                        <Route path={"/signup"} exact component={UserTypeSelection}/>
                        <Route path={"/signup/teacher"} exact component={TeacherOnBoarding}/>
                        <Route path={"/signup/student"} exact component={StudentOnBoarding}/>
                        <ProtectedRoute path={"/appointments"} exact component={AppointmentsPage}></ProtectedRoute>
                        <ProtectedRoute path={"/appointments/test"} exact component={TestAppointmentsPage}></ProtectedRoute>
                        <ProtectedRoute path={"/courses"} exact component={CoursesPage}></ProtectedRoute>
                        <ProtectedRoute path={"/courses/edit/:courseid"} exact component={EditCourse}></ProtectedRoute>
                        <ProtectedRoute path={"/resources"} exact component={ResourcesPage}></ProtectedRoute>
                        <ProtectedRoute path={"/resources/test"} exact component={ResourcesPageTest}></ProtectedRoute>
                        <ProtectedRoute path={"/dashboard"} exact component={DashboardPage}></ProtectedRoute>
                        {/*<Route path={"/res"} exact component={ReservationPage}></Route>*/}
                        {/*<Route path={'/room/:roomid'} component={ClassroomPage}/>*/}
                        {/*<Route path={'/room/test'} component={ClassroomV2}/>*/}
                        {/*<Route path={'/classroom'} component={ClassroomV2}/>*/}
                        <ProtectedRoute path={'/classroom/:roomid'} exact component={ClassroomV2}/>

                    {/*<Route path={"/editor"} component={TestEditor}></Route>*/}
                        <ProtectedRoute path={"/settings"} component={Settings}></ProtectedRoute>
                        <ProtectedRoute path={"/workhours"} component={WorkhoursSettings}></ProtectedRoute>
                        <Route path={"/videochat"} exact component={FreeClassroomLanding}></Route>
                        <Route path={"/videochat/:roomName"} exact component={FreeClassroomContainer}></Route>
                        <Route path={"/404"} component={PageNotFound}></Route>
                        <Route path={"/:lessonid/invite"} exact component={InvitationReceived}></Route>
                        <Route path={"/:lessonid"} exact component={ReservationPage}></Route>
                        <Redirect to='/appointments' />

                </Switch>
            </AuthProvider>
        </div>
      </Router>
  );
}

export default App;
