import React, {useContext, useEffect, useRef, useState} from "react";
import {CheckIcon, MinusIcon, PlusIcon, XMarkIcon} from "@heroicons/react/20/solid";
import dataLoader from "../api/DataLoader";
import UserContext from "../auth/UserContext";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import './TeacherChecklist.scss';


const TeacherChecklist = (props) => {
    const [closed, setClosed] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [validationResult, setValidationResult] = useState();
    const userContext = useContext(UserContext)

    const isMounted = useRef(false);


    useEffect(() => {
        if (userContext?.isAuthenticated && (typeof userContext?.userInfo !== undefined)) {
            dataLoader.getTeacherValidation(userContext?.userInfo?.id, userContext?.authInfo?.accessToken).then(r => setValidationResult(r.data))
        }

    }, [userContext.isAuthenticated]);

    useEffect(() => {
        if (isMounted.current) {
            setLoaded(true)
        } else {
            isMounted.current = true;
        }
    }, [validationResult]);


    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }


    // return <div>
    //     {!closed  && loaded && userContext.isTeacher && !(validationResult.activeCourse && validationResult.workhours) &&
    //     <div className="bg-white shadow border-blue-700 border sm:rounded-lg absolute bottom-4 sm:right-4 right-0 z-50">
    //         <div className="px-4 py-5 sm:p-6">
    //             <div className={"flex justify-between mb-4"}>
    //                 <h3 className="text-base font-semibold leading-6 text-gray-900">A few things still missing!</h3>
    //                 <XMarkIcon className={"w-5 hover:cursor-pointer hover:opacity-70"}
    //                            onClick={() => setClosed(true)}></XMarkIcon>
    //             </div>
    //             <div className="flow-root">
    //                 <ul role="list" className="-mb-8">
    //                     <li>
    //                         <div className="relative pb-4">
    //                             {validationResult.activeCourse ?
    //
    //                                 <div className="relative flex space-x-3">
    //                                     <div>
    //                                   <span
    //                                       className={classNames(
    //                                           "bg-green-500",
    //                                           'h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white'
    //                                       )}
    //                                   >
    //                                     <CheckIcon className="h-4 w-4 text-white" aria-hidden="true"/>
    //                                   </span>
    //                                     </div>
    //                                     <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-0.5">
    //                                         <div>
    //                                             <p className="text-sm text-gray-500">
    //                                                 {"You have an active course ready"}{' '}
    //                                                 <a href={"/courses"} className="font-medium text-gray-900">
    //                                                     {"Share it!"}
    //                                                 </a>
    //                                             </p>
    //
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                                 :
    //                                 <div className="relative flex space-x-3">
    //                                     <div>
    //                                   <span
    //                                       className={classNames(
    //                                           "bg-red-500",
    //                                           'h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white'
    //                                       )}
    //                                   >
    //                                     <MinusIcon className="h-4 w-4 text-white" aria-hidden="true"/>
    //                                   </span>
    //                                     </div>
    //                                     <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-0.5">
    //                                         <div>
    //                                             <p className="text-sm text-gray-500">
    //                                                 {"You need at least one active course"}{' '}
    //                                                 <a href={"/courses"} className="font-medium text-gray-900">
    //                                                     {"Create one here"}
    //                                                 </a>
    //                                             </p>
    //
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             }
    //                         </div>
    //                     </li>
    //                     <li>
    //                         <div className="relative pb-8">
    //                             {validationResult.workhours ?
    //
    //                                 <div className="relative flex space-x-3">
    //                                     <div>
    //                                   <span
    //                                       className={classNames(
    //                                           "bg-green-500",
    //                                           'h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white'
    //                                       )}
    //                                   >
    //                                     <CheckIcon className="h-4 w-4 text-white" aria-hidden="true"/>
    //                                   </span>
    //                                     </div>
    //                                     <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-0.5">
    //                                         <div>
    //                                             <p className="text-sm text-gray-500">
    //                                                 {"You have your availability set"}{' '}
    //                                             </p>
    //
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                                 :
    //                                 <div className="relative flex space-x-3">
    //                                     <div>
    //                                   <span
    //                                       className={classNames(
    //                                           "bg-red-500",
    //                                           'h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white'
    //                                       )}
    //                                   >
    //                                     <MinusIcon className="h-4 w-4 text-white" aria-hidden="true"/>
    //                                   </span>
    //                                     </div>
    //                                     <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-0.5">
    //                                         <div>
    //                                             <p className="text-sm text-gray-500">
    //                                                 {"You need to set your availability"}{' '}
    //                                                 <a href={"/courses"} className="font-medium text-gray-900">
    //                                                     {"Set it here!"}
    //                                                 </a>
    //                                             </p>
    //
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             }
    //                         </div>
    //                     </li>
    //                 </ul>
    //             </div>
    //         </div>
    //     </div>
    //     }
    // </div>

    return <div className={"fixed bottom-0 right-0"}>
        {!closed && loaded && userContext.isTeacher && !(validationResult.activeCourse && validationResult.workhours) &&
        <div>

            <div
                className="bg-white shadow-lg border-blue-700 border sm:rounded-lg relative bottom-20 sm:right-4 right-0 z-50">
                <div className="border-b border-gray-200 bg-gray-100 rounded-t-lg px-4 py-5 sm:px-6">
                    <h3 className="text-lg font-semibold leading-6 text-gray-900">A few things are still missing!</h3>
                </div>
                <div className="px-4 py-5 sm:p-6">
                    {/*<div className={"flex justify-between mb-4 border-b border-gray-200 bg-secondary py-0"}>*/}
                    {/*    <h3 className="text-base font-semibold leading-6 text-gray-900">A few things still missing!</h3>*/}
                    {/*</div>*/}
                    <div className="w-72">
                        <Accordion
                            allowMultipleExpanded={true}>
                            <AccordionItem dangerouslySetExpanded={true}>
                                <AccordionItemHeading>
                                    <AccordionItemButton className={"text-base font-semibold"}>
                                        {validationResult.activeCourse ?

                                            <div className="relative flex space-x-3">
                                                <div>
                                      <span
                                          className={classNames(
                                              "bg-green-500",
                                              'h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white'
                                          )}
                                      >
                                        <CheckIcon className="h-4 w-4 text-white" aria-hidden="true"/>
                                      </span>
                                                </div>
                                                <div
                                                    className="flex min-w-0 flex-1 justify-between space-x-4 pt-1 leading-3">
                                                    <p>
                                                        You have an active course
                                                    </p>
                                                </div>
                                            </div>
                                            :
                                            <div className="relative flex space-x-3">
                                                <div>
                                      <span
                                          className={classNames(
                                              "bg-red-500",
                                              'h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white'
                                          )}
                                      >
                                        <MinusIcon className="h-4 w-4 text-white" aria-hidden="true"/>
                                      </span>
                                                </div>
                                                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-0.5">
                                                    <div
                                                        className="flex min-w-0 flex-1 justify-between space-x-4 pt-1 leading-3">
                                                        <p>
                                                            You need an active course
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className={"text-sm"}>
                                    <p className={"py-2 text-gray-500"}>
                                        Your event type is live and ready to share with your invitees.

                                        You can continue edit this event type or create another one.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <div className={"w-full h-[2px] bg-gray-200 my-4"}></div>
                            <AccordionItem dangerouslySetExpanded={true}>
                                <AccordionItemHeading>
                                    <AccordionItemButton className={"text-base font-semibold"}>

                                        {validationResult.workhours ?

                                            <div className="relative flex space-x-3">
                                                <div>
                                      <span
                                          className={classNames(
                                              "bg-green-500",
                                              'h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white'
                                          )}
                                      >
                                        <CheckIcon className="h-4 w-4 text-white" aria-hidden="true"/>
                                      </span>
                                                </div>
                                                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-0.5">
                                                    <div>
                                                        <p className="text-sm text-gray-500">
                                                            {"You have your availability set"}{' '}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="relative flex space-x-3">
                                                <div>
                                      <span
                                          className={classNames(
                                              "bg-red-500",
                                              'h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white'
                                          )}
                                      >
                                        <MinusIcon className="h-4 w-4 text-white" aria-hidden="true"/>
                                      </span>
                                                </div>
                                                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-0.5">
                                                    <div
                                                        className="flex min-w-0 flex-1 justify-between space-x-4 pt-1 leading-3">
                                                        <p>
                                                            Set your availability!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className={"text-sm"}>
                                    <p className={"py-2 text-gray-500"}>
                                        Exercitation in fugiat est ut ad ea cupidatat ut in
                                        cupidatat occaecat ut occaecat consequat est minim minim
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
        }

        { loaded && userContext?.isTeacher && !(validationResult?.activeCourse && validationResult?.workhours)&&
            <div>
                {closed ?
                    <div className={"absolute bottom-4 sm:right-4 right-0 z-50"}>

                        <button
                            onClick={() => setClosed(false)}
                            type="button"
                            className="shadow-xl w-36 rounded-md bg-secondary px-3.5 py-2.5 text-sm font-semibold relative text-white shadow-sm hover:bg-secondary-alt focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Getting started
                        </button>
                        <span className="absolute -left-3 top-1 block h-4 w-4 -translate-y-1/2 translate-x-1/2 transform rounded-full bg-red-400 ring-2 ring-white" />
                    </div>

                    :

                    <button
                        onClick={() => setClosed(true)}
                        type="button"
                        className="shadow-xl absolute bottom-4 sm:right-4 right-0 z-50 rounded-full bg-secondary p-3 text-white shadow-sm hover:bg-secondary-alt focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <XMarkIcon className="h-5 w-5" aria-hidden="true"/>
                    </button>
                }
            </div>
        }
    </div>
}

export default TeacherChecklist