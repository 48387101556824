import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import HourSelect from "./HourSelect";
import moment from "moment";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TimeRangeSelector(props) {
    const convertMinsToTime = (minutes) => {
        // console.log(moment.utc().startOf('day').add(minutes, 'minutes'))
        // return moment.utc().startOf('day').add(minutes, 'minutes').format('HH:mm')
        return moment.utc().startOf('day').add(minutes, 'minutes').format('HH:mm')
    }

    const editFromTime = (time) => {

        // console.log(moment(time, [moment.ISO_8601, 'HH:mm']))
        // console.log(moment(time, [moment.ISO_8601, 'HH:mm']).hours())

        let minutes = moment(time, [moment.ISO_8601, 'HH:mm']).hours() * 60 + moment(time, [moment.ISO_8601, 'HH:mm']).minutes()

        props.editFromMinutes(minutes)



    }

    const editToTime = (time) => {

        let minutes = moment(time, [moment.ISO_8601, 'HH:mm']).hours() * 60 + moment(time, [moment.ISO_8601, 'HH:mm']).minutes()
        props.editToMinutes(minutes)
    }

    return (
        <div className={"flex bg-gray-200 rounded-md items-center hover:bg-gray-300"}>
            <HourSelect time={convertMinsToTime(props.from)} editTime={(time)=>editFromTime(time)}></HourSelect>
            <p>-</p>
            <HourSelect time={convertMinsToTime(props.to)} editTime={(time)=>editToTime(time)}></HourSelect>
        </div>
    )
}
