import React from 'react';
const TrustBox = () => {
    return (
        <div className={"w-screen h-full flex flex-col justify-center items-center text-center gap-8"}>
            <img
                className="mx-auto h-16 w-auto"
                src={process.env.PUBLIC_URL + '/primary-logo.svg'} alt="logo"
            />
            <h1 className={"text-lg font-semibold w-96"}>We are working day and night to create the best solution for teachers. Please register for our waiting list and experience our other features.</h1>
            <h1 className={"text-lg font-semibold w-96"}>Your feedback is our most valuable asset. Please take a moment to leave a review; it's greatly appreciated.</h1>
            <div className={"border-2 rounded border-green-600 w-96 h-24 flex justify-center p-4 cursor-pointer hover:shadow-lg"}>
                <a href="https://www.trustpilot.com/review/abakusz.io" target="_blank" rel="noopener" className={"flex gap-4"}>
                    <p className={"text-xl font-bold mt-5"}>Review us on</p>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Trustpilot_Logo_%282022%29.svg"
                         alt="trustpilot"
                        className={"w-48"}
                    />
                </a>
            </div>
        </div>

    );
};
export default TrustBox;