import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts"
import {useEffect, useState} from "react";
import dataLoader from "../../api/DataLoader";
import moment from "moment";

const defData = [
    {
        name: "Jan",
        total: 0,
    },
    {
        name: "Feb",
        total: 0,
    },
    {
        name: "Mar",
        total: 0,
    },
    {
        name: "Apr",
        total: 0,
    },
    {
        name: "May",
        total: 0,
    },
    {
        name: "Jun",
        total: 0,
    },
    {
        name: "Jul",
        total: 0,
    },
    {
        name: "Aug",
        // total: Math.floor(Math.random() * 5000) + 1000,
        total: 0,
    },
    {
        name: "Sep",
        total: 0,
    },
    {
        name: "Oct",
        total: 0,
    },
    {
        name: "Nov",
        total: 0,
    },
    {
        name: "Dec",
        total: 0,
    },
]

export function Chart(props) {
    const [selectedTimezone, setSelectedTimezone] =useState(
        localStorage.getItem("selectedTimezone") !== null ?
            localStorage.getItem("selectedTimezone")
            :

            Intl.DateTimeFormat().resolvedOptions().timeZone
    )

    const [data, setData] = useState(defData)


    useEffect(() => {

        let groupedBookings = Object.groupBy(props.bookings, ({ startDate }) => moment(startDate).tz(selectedTimezone).format('MMM'))
        // groupedBookings = groupedBookings.

        let tmpData = defData

        // console.log(groupedBookings)

        for (const [key, value] of Object.entries(groupedBookings)) {
            for (const [key2, value2] of Object.entries(value)) {
                // console.log(props.courseInfo)
                tmpData = tmpData.map(el => el.name === key ? {...el, total: el.total + props.courseInfo[0]?.price} : el);
            }
        }

        setData(tmpData);


    }, [props]);

    return (
        <ResponsiveContainer width="100%" height={350}>
            <BarChart data={data}>
                <XAxis
                    dataKey="name"
                    stroke="#888888"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                />
                <YAxis
                    stroke="#888888"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={(value) => `$${value}`}
                />
                <Bar
                    dataKey="total"
                    fill="#398bfb"
                    radius={[4, 4, 0, 0]}
                    className="fill-primary"
                />
            </BarChart>
        </ResponsiveContainer>
    )
}