import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import dataLoader from "../../api/DataLoader";
import UserContext from "../../auth/UserContext";
import {useHistory} from "react-router";
import {Helmet} from "react-helmet";
import {Chart} from "./Chart";
import ProfilePicture from "../../components/ProfilePicture";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function InvitationReceived(props) {
    const userContext = useContext(UserContext)
    let history = useHistory();
    const [prevBookings, setPrevBookings] = useState([])
    const [revenue, setRevenue] = useState()
    const [completedSessions, setCompletedSessions] = useState()
    const [userInfo, setUserInfo] = useState([])
    const [pageViews, setPageViews] = useState()
    const [courseInfo, setCourseInfo] = useState()
    // const [timeZone, setTimezone] = useState()

    useEffect(() => {
        dataLoader.getPrevAppointments(userContext?.userInfo?.id, userContext?.authInfo?.accessToken).then(res =>{
                setPrevBookings(res.data.scheduledAppointments)
                setUserInfo(res.data.userInfo)
                setCourseInfo(res.data.lessonCards)
                console.log(res.data)
        }

        )

        dataLoader.getBookingHits(userContext?.userInfo?.id, userContext?.authInfo?.accessToken).then(res =>{
                setPageViews(res.data.length)
            }

        )



    }, []);

    useEffect(() => {
        if(courseInfo){
            let approved = prevBookings.filter(booking => booking.state === "APPROVED")
            setCompletedSessions(approved.length)
            setRevenue(approved.length * courseInfo[0]?.price)

        }


        // console.log(approved)





    }, [courseInfo]);

    return (
        <div className={"h-screen bg-background"}>
            <div className="pt-6 mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <Helmet>
                <title>Appointments - Abakusz</title>
            </Helmet>
            <div className="border-b border-gray-200 pb-5 flex justify-between">
                <h3 className="text-lg font-medium leading-6 text-gray-900 py-1.5 ">
                    Appointments
                </h3>
            </div>
                <div className="w-full mx-1 grid gap-4 md:grid-cols-2 lg:grid-cols-4 mt-4">
                    <div className="rounded-xl border bg-card text-card-foreground shadow">
                        <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2"><h3
                            className="tracking-tight text-sm font-medium">Total Estimated Revenue</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                 className="h-4 w-4 text-muted-foreground">
                                <path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                            </svg>
                        </div>
                        <div className="p-6 pt-0">
                            <div className="text-2xl font-bold">${revenue}</div>
                            {/*<p className="text-xs text-muted-foreground">+20.1% from last month</p>*/}
                        </div>
                    </div>
                    <div className="rounded-xl border bg-card text-card-foreground shadow">
                        <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2"><h3
                            className="tracking-tight text-sm font-medium">Completed Sessions</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                 className="h-4 w-4 text-muted-foreground">
                                <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                                <circle cx="9" cy="7" r="4"></circle>
                                <path d="M22 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75"></path>
                            </svg>
                        </div>
                        <div className="p-6 pt-0">
                            <div className="text-2xl font-bold">+{completedSessions}</div>
                            {/*<p className="text-xs text-muted-foreground">+180.1% from last month</p>*/}
                        </div>
                    </div>
                    <div className="rounded-xl border bg-card text-card-foreground shadow">
                        <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2"><h3
                            className="tracking-tight text-sm font-medium">Booked Sessions</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                 className="h-4 w-4 text-muted-foreground">
                                <rect width="20" height="14" x="2" y="5" rx="2"></rect>
                                <path d="M2 10h20"></path>
                            </svg>
                        </div>
                        <div className="p-6 pt-0">
                            <div className="text-2xl font-bold">+{prevBookings.length}</div>
                            {/*<p className="text-xs text-muted-foreground">+19% from last month</p>*/}
                        </div>
                    </div>
                    <div className="rounded-xl border bg-card text-card-foreground shadow">
                        <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2"><h3
                            className="tracking-tight text-sm font-medium">Page Views</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                 className="h-4 w-4 text-muted-foreground">
                                <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                            </svg>
                        </div>
                        <div className="p-6 pt-0">
                            <div className="text-2xl font-bold">+{pageViews}</div>
                            {/*<p className="text-xs text-muted-foreground">+201 since last month</p>*/}
                        </div>
                    </div>
                </div>

                <div className={"grid gap-4 md:grid-cols-2 lg:grid-cols-7 mt-4"}>
                    <div className={"rounded-xl border bg-card text-card-foreground shadow col-span-4"}>
                        <div className="flex flex-col space-y-1.5 p-6"><h3
                            className="font-semibold leading-none tracking-tight">Overview</h3></div>
                        {courseInfo?
                            <Chart bookings={prevBookings} courseInfo={courseInfo}></Chart>
                            // console.log(courseInfo)

                            :
                            <div>loading</div>
                        }
                    </div>
                    <div className="rounded-xl border bg-card text-card-foreground shadow col-span-3">
                        <div className="flex flex-col space-y-1.5 p-6"><h3
                            className="font-semibold leading-none tracking-tight">Previous Sessions</h3><p
                            className="text-sm text-muted-foreground">You made {prevBookings.length} sales this month.</p></div>
                        <div className="p-6 pt-0">
                            <div className="space-y-8 overflow-scroll h-[320px]">
                                {prevBookings.map(booking =>
                                    <div className="flex items-center"><span
                                        className="relative flex shrink-0 overflow-hidden rounded-full h-9 w-9">
                                        {/*<img*/}
                                        {/*className="aspect-square h-full w-full" alt="Avatar" src="/avatars/05.png"/>*/}
                                        <ProfilePicture name={userInfo[booking?.studentId]?.givenName + " " + userInfo[booking?.studentId]?.familyName} height={"aspect-square h-full w-full"}></ProfilePicture>

                                    </span>
                                        <div className="ml-4 space-y-1">
                                            <p className="text-sm font-medium leading-none">{userInfo[booking?.studentId]?.givenName + " " + userInfo[booking?.studentId]?.familyName}</p>
                                            <p className="text-sm text-muted-foreground">{userInfo[booking?.studentId]?.email}</p>
                                        </div>
                                        <div className="ml-auto font-medium">+${courseInfo  && courseInfo[0].price}</div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}