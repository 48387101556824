import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline'


export default function DriveCTA() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl py-4 sm:px-6 sm:py-6 lg:px-8">
                <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                    <svg
                        viewBox="0 0 1024 1024"
                        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                        aria-hidden="true"
                    >
                        <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                        <defs>
                            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                <stop stopColor="#7775D6" />
                                <stop offset={1} stopColor="#E935C1" />
                            </radialGradient>
                        </defs>
                    </svg>
                    {/*<div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-20 lg:text-left">*/}
                    <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-10 lg:text-left">
                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            Share your notes with Drive like you always do.
                            {/*<br />*/}
                            {/*Start using our app today.*/}
                        </h2>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            With our Google Drive integration, you can create, share and work on every kind of documents.
                        </p>
                        <ChevronDoubleDownIcon className="m-auto mt-4 block h-8 w-8 text-white"></ChevronDoubleDownIcon>
                    </div>
                    {/*<div className="relative mt-16 h-80 lg:mt-8">*/}
                    <div className="relative mt-16 h-40 lg:mt-8">
                        <img
                            className="absolute left-0 top-0 w-[37rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
                            src="https://i.insider.com/5f205b443f7370251118c1a1?width=750&format=jpeg&auto=webp"
                            alt="App screenshot"
                            width={1824}
                            height={1080}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}