import React, {useContext, useState} from 'react';
import { Wizard, Steps, Step } from 'react-albus';
import BasicSignup from "./BasicSignup";
import StudentFinishPage from "./StudentFinishPage";

function StudentOnBoarding(props) {

    return (
        <Wizard>
            <Steps>
                <Step
                    id="form"
                    render={({ next }) => (
                        <BasicSignup next={next} userType={"STUDENT"}></BasicSignup>
                    )}
                />
                <Step
                    id="student-finish"
                    render={({ next }) => (
                        <StudentFinishPage></StudentFinishPage>
                    )}
                />
            </Steps>
        </Wizard>
    );
}

export default StudentOnBoarding;