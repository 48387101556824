import "@whereby.com/browser-sdk"
import {useEffect} from "react";

const WherebyEmbedded = (props) => {
    // const elm = document.querySelector("whereby-embed");
    // function leaveRoom(event) {
    //     alert("dsds")
    //
    // }
    // elm?.addEventListener("leave", leaveRoom)

    useEffect(() => {
        const elm=document.querySelector("whereby-embed");
        elm?.addEventListener("leave", props.leaveRoom)
    });


    return <whereby-embed
        // room={"https://abakusz.whereby.com/bff408cb-0fe4-4df9-a46a-51189d5e2578?roomIntegrations=on"}
        // room={props.roomURL+"?roomIntegrations=on"}
        room={props.roomURL}
        displayName={props.name}
        participantCount={"off"}
        locking={"off"}
        moreButton={"off"}
        roomIntegrations={"on"}
        people={"off"}
    />
}

export default WherebyEmbedded