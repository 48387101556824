import React, {Fragment, useCallback, useContext, useRef, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {BanknotesIcon} from "@heroicons/react/20/solid";
import UserContext from "../../auth/UserContext";
import dataLoader from "../../api/DataLoader";
import Hashids from "hashids";
import CustomCurrencyInput from "../signup/CustomCurrencyInput";
import ProfilePicture from "../../components/ProfilePicture";

export default function EditCourseModal(props) {
    const userContext = useContext(UserContext)

    const cancelButtonRef = useRef(null)

    const [price, setPrice] = useState(props?.courseInfo?.price);
    const [currency, setCurrency] = useState(props?.courseInfo?.currency);

    const getLessonId=()=>{
        let hashids = new Hashids("life is very short and anxious for those who forget the past, neglect the present, and fear the future", 7);
        return hashids.encode(Math.floor(Math.random() * (10000 - 1) + 1));
    }

    const handleSubmit = useCallback(async event => {
        event.preventDefault();

        const { title, description, freelesson } = event.target.elements;

        // let lessonId=getLessonId();
        // console.log(event.target.elements.freelesson.checked)

        let lessonId = props?.courseInfo?.lessonId ? props.courseInfo.lessonId : getLessonId();

        const newLessonCard = {
            userId: userContext.userInfo.id,
            lessonId: lessonId,
            displayName: userContext.userInfo.givenName + " " + userContext.userInfo.familyName,
            title: title.value, description: description.value,
            price: parseFloat(price), currency: currency,
            freeLesson:freelesson.checked,
            duration: 60,
            startIncrements: 30,
            minimumNotice: 4,
            timeBetween: 15,
            maxNumber: 8,
            isActive: true
        };

        props.courseInfo?
            dataLoader.modifyLessonCard(props.courseInfo.id, newLessonCard, userContext.authInfo.accessToken)
                // .then(r => props.setOpen(false))
                .then(r => window.location.reload())
            :
            dataLoader.saveLessonCard(newLessonCard, userContext.authInfo.accessToken)
                .then(r => window.location.reload())
    }, [price, currency]);

    const handleChange = useCallback(async event => {
        event.preventDefault();
    }, []);

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <form className="space-y-6" onSubmit={handleSubmit}>
                                    <div className={"flex flex-row gap-4"}>
                                        <ProfilePicture userId={userContext.userInfo.id} name={userContext.userInfo.givenName} height={"h-16 w-16"}></ProfilePicture>

                                        <div className={"text-xs font-medium lg:text-base w-full"}>
                                            <h3>{userContext.userInfo.givenName + " " + userContext.userInfo.familyName}</h3>
                                            <label htmlFor="email" className="sr-only">
                                                Email
                                            </label>
                                            <input
                                                onChange={handleChange}
                                                type="text"
                                                name="title"
                                                id="title"
                                                className="my-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                placeholder="Title of your first course"
                                                defaultValue={props?.courseInfo?.title}
                                            />
                                        </div>
                                    </div>
                                    <div className={"flex py-1 min-h-full justify-center"}>
                            <textarea
                                rows={8}
                                name="description"
                                id="description"
                                className="resize-none block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                placeholder="Tell us about your first course..."
                                defaultValue={props?.courseInfo?.description}
                            />
                                    </div>

                                    <CustomCurrencyInput value={price} setPrice={(price)=>setPrice(price)} setCurrency={(currency)=>setCurrency(currency)}></CustomCurrencyInput>


                                    <div className="flex items-center py-1">
                                        <input
                                            id="freelesson"
                                            name="freelesson"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-secondary focus:ring-0 cursor-pointer"
                                            defaultChecked={props?.courseInfo?.freeLesson}
                                        />
                                        <label htmlFor="freelesson" className="ml-2 block text-sm text-gray-900 cursor-pointer">
                                            The first, introductory lesson is free
                                        </label>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-secondary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-secondary-alt sm:col-start-2 sm:text-sm"
                                        >
                                            Save changes
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}