import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import UserContext from "../../auth/UserContext";
import dataLoader from "../../api/DataLoader";
import CourseCard from "../../components/CourseCard";
import {Helmet} from "react-helmet";
import {ChevronLeftIcon, ChevronRightIcon, PhotoIcon, UserCircleIcon} from "@heroicons/react/20/solid";
import CurrencyInput from "react-currency-input-field";
import CustomCurrencyInput from "../signup/CustomCurrencyInput";
import { Switch } from '@headlessui/react'
import Hashids from "hashids";
import BookingModalContent from "../lessonpage/BookingModalContent";
import BasicModal from "../../components/BasicModal";
import {useHistory} from "react-router";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const options= [
    {
        locale: 'de-DE',
        currency: 'EUR',
    },
    {
        locale: 'hu-HU',
        currency: 'HUF',
    },
    {
        locale: 'en-US',
        currency: 'USD',
    },
    {
        locale: 'en-GB',
        currency: 'GBP',
    },
];

function EditCourse(props) {
    const userContext = useContext(UserContext)
    const [courseInfo, setCourseInfo] = useState([]);
    const [courseId, setCourseId] = useState(window.location.pathname.split("/").pop());
    const [isActive, setIsActive] = useState();
    const [sessionDuration, setSessionDuration] = useState(60);
    const [startTimeIncrement, setStartTimeIncrement] = useState(15);
    const [minNotice, setMinNotice] = useState(24);
    const [timeBetweenSessions, setTimeBetweenSessions] = useState(15);
    const [maxNumOfSessions, setMaxNumOfSessions] = useState(8);
    const [price, setPrice] = useState();
    const [currency, setCurrency] = useState();

    const [deleteCourseModalOpen, setDeleteCourseModalOpen] = useState(false);

    const isMounted = useRef(false);


    const [loading, setLoading] = useState(false);

    const history = useHistory();



    useEffect(() => {
        setLoading(true)

        dataLoader.getLessonCard(window.location.pathname.split("/").pop()).then(res =>
            setCourseInfo(res.data)
        )

    }, []);

    useEffect(() => {
        if (isMounted.current) {
            // console.log(courseInfo)
            setIsActive(courseInfo.isActive)
            setPrice(courseInfo.price)
            setCurrency(courseInfo.currency)
            setSessionDuration(courseInfo.duration)
            setStartTimeIncrement(courseInfo.startIncrements)
            setMinNotice(courseInfo.minimumNotice)
            setTimeBetweenSessions(courseInfo.timeBetween)
            setMaxNumOfSessions(courseInfo.maxNumber)
            setLoading(false)
        } else {
            isMounted.current = true;
        }

    }, [courseInfo]);



    const stateIncrement = (state, set, increment) => {
        console.log(state)
        set(state + increment)
    };

    const stateDecrement = (state, set, increment) => {
        if(state - increment > 0){
            set(state - increment)
        }
    };

    const getLessonId=()=>{
        let hashids = new Hashids("life is very short and anxious for those who forget the past, neglect the present, and fear the future", 7);
        return hashids.encode(Math.floor(Math.random() * (10000 - 1) + 1));
    }

    const handleSubmit = useCallback(async event => {
        event.preventDefault();

        const { title, description, freelesson} = event.target.elements;

        let lessonId = courseInfo.lessonId ? courseInfo.lessonId : getLessonId();

        // console.log(startTimeIncrement)
        // console.log(minNotice)
        // console.log(maxNumOfSessions)
        // console.log(timeBetweenSessions)
        // console.log(isActive)

        const newLessonCard = {
            userId: userContext.userInfo.id,
            lessonId: lessonId,
            displayName: userContext.userInfo.givenName + " " + userContext.userInfo.familyName,
            title: title.value, description: description.value,
            price: parseFloat(price), currency: currency,
            freeLesson:freelesson.checked,
            duration: sessionDuration,
            startIncrements: startTimeIncrement,
            minimumNotice: minNotice,
            timeBetween: timeBetweenSessions,
            maxNumber: maxNumOfSessions,
            isActive: isActive
        };

        // console.log(newLessonCard)

        courseInfo?
            dataLoader.modifyLessonCard(courseInfo.id, newLessonCard, userContext.authInfo.accessToken)
                // .then(r => props.setOpen(false))
                .then(r => window.location.reload())
            :
            dataLoader.saveLessonCard(newLessonCard, userContext.authInfo.accessToken)
                .then(r => window.location.reload())

        history.push('/courses')
    }, [price, currency, sessionDuration, startTimeIncrement, minNotice, timeBetweenSessions, maxNumOfSessions, isActive]);

    const setCourseActive = (e) => {
        setIsActive(e)
    };

    const openDeleteCourseModal = (e) => {
        e.preventDefault()
        setDeleteCourseModalOpen(true)
    };

    const deleteCard = () =>{
        dataLoader.deleteLessonCard(courseInfo.id, userContext.authInfo.accessToken)
            .then(r => history.push("/courses"))
    }

    return (
        <div>
            <div className={"pt-6 mx-auto max-w-7xl px-2 sm:px-6 lg:px-8"}>
                <Helmet>
                    <title>Your Courses - Abakusz</title>
                </Helmet>

                <div>
                    <div>
                        <nav className="sm:hidden" aria-label="Back">
                            <a href="#" className="flex items-center text-sm font-medium text-gray-400 hover:text-gray-200">
                                <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
                                Back
                            </a>
                        </nav>
                        <nav className="hidden sm:flex" aria-label="Breadcrumb">
                            <ol role="list" className="flex items-center space-x-4">
                                <li>
                                    <div className="flex">
                                        <a href="#" className="text-sm font-medium text-gray-400 hover:text-gray-200">
                                            Courses
                                        </a>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
                                        <a href="#" className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-200">
                                            {courseInfo.title}
                                        </a>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className={"max-w-7xl pt-2 mb-8"}>
                        <div className="border-b border-gray-200 pb-3 sm:flex sm:items-center sm:justify-between">
                            <h3 className="text-lg font-medium leading-6 text-gray-900 ">
                                Edit Course
                            </h3>
                            <div className="mt-3 sm:mt-0 sm:ml-4 h-8 gap-4 flex text-sm">
                                <span className={"pt-1"}>Course active</span>
                                <Switch
                                    checked={isActive}
                                    onChange={e => setCourseActive(e)}
                                    className={classNames(
                                        isActive ? 'bg-green-600' : 'bg-gray-200',
                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2'
                                    )}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            isActive ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                        )}
                                    />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
                {loading?
                    <div>Loading</div>
                    :
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className="space-y-12">
                                <div
                                    className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                    <div>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Course Information</h2>
                                        <p className="mt-1 text-sm leading-6 text-gray-600">
                                            You can view and modify details about your educational offerings.
                                        </p>
                                    </div>

                                    <div
                                        className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                        <div className="sm:col-span-4">
                                            <label htmlFor="title"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Course Title
                                            </label>
                                            <div className="mt-2">
                                                <div
                                                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary sm:max-w-md">
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        id="title"
                                                        autoComplete="title"
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                                                        defaultValue={courseInfo?.title}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-full">
                                            <label htmlFor="description"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Description
                                            </label>
                                            <div className="mt-2">
                <textarea
                    id="description"
                    name="description"
                    rows={6}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                    defaultValue={courseInfo?.description}
                />
                                            </div>
                                            <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about
                                                your course.</p>
                                        </div>

                                        {/*                          <div className="w-40">*/}

                                        {/*                              <label htmlFor="price"*/}
                                        {/*                                     className="block text-sm font-medium leading-6 text-gray-900">*/}
                                        {/*                                  Duration of a session*/}
                                        {/*                              </label>*/}
                                        {/*                              <div className="relative mt-2 rounded-md shadow-sm">*/}
                                        {/*                                  <input*/}
                                        {/*                                      type="text"*/}
                                        {/*                                      name="duration"*/}
                                        {/*                                      id="duration"*/}
                                        {/*                                      className="block w-full rounded-md border-0 py-1.5 pl-8 pr-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6"*/}
                                        {/*                                      placeholder="60"*/}
                                        {/*                                      value={"60"}*/}
                                        {/*                                      aria-describedby="price-currency"*/}
                                        {/*                                  />*/}
                                        {/*                                  <div*/}
                                        {/*                                      className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">*/}
                                        {/*<span className="text-gray-500 sm:text-sm" id="price-currency">*/}
                                        {/*  minutes*/}
                                        {/*</span>*/}
                                        {/*                                  </div>*/}
                                        {/*                              </div>*/}
                                        {/*                          </div>*/}

                                        <div className="col-span-full">
                                            <div className="max-w-xs ">


                                                <label htmlFor="sessionduration"
                                                       className="block mb-2 text-sm font-medium text-gray-900">Session Duration</label>
                                                <div className="relative flex items-center max-w-[11rem]">
                                                    <button type="button" id="decrement-button"
                                                            onClick={()=>stateDecrement(sessionDuration, setSessionDuration, 15)}
                                                            className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 focus:ring-2 focus:outline-none">
                                                        <svg className="w-3 h-3 text-gray-900"
                                                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 18 2">
                                                            <path stroke="currentColor" stroke-linecap="round"
                                                                  stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>
                                                        </svg>
                                                    </button>
                                                    <input type="text" id="sessionduration" data-input-counter
                                                           data-input-counter-min="1" data-input-counter-max="5"
                                                           aria-describedby="helper-text-explanation"
                                                           className="bg-white border-x-0 border-gray-300 h-11 font-medium text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pb-6"
                                                           placeholder="" value={sessionDuration}/>
                                                    <div
                                                        className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-600 space-x-1 rtl:space-x-reverse">
                                                        <span>minutes</span>
                                                    </div>
                                                    <button type="button" id="increment-button"
                                                        // data-input-counter-increment="bedrooms-input"
                                                            onClick={()=>stateIncrement(sessionDuration, setSessionDuration, 15)}
                                                            className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 focus:ring-2 focus:outline-none">
                                                        <svg className="w-3 h-3 text-gray-900"
                                                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                             fill="none" viewBox="0 0 18 18">
                                                            <path stroke="currentColor" stroke-linecap="round"
                                                                  stroke-linejoin="round" stroke-width="2"
                                                                  d="M9 1v16M1 9h16"/>
                                                        </svg>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-span-full">
                                            <div className="max-w-xs ">


                                                <label htmlFor="starttimeincrement"
                                                       className="block mb-2 text-sm font-medium text-gray-900">Start Time Increments</label>
                                                <div className="relative flex items-center max-w-[11rem]">
                                                    <button type="button" id="decrement-button"
                                                            onClick={()=>stateDecrement(startTimeIncrement, setStartTimeIncrement,15)}

                                                            className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 focus:ring-2 focus:outline-none">
                                                        <svg className="w-3 h-3 text-gray-900"
                                                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 18 2">
                                                            <path stroke="currentColor" stroke-linecap="round"
                                                                  stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>
                                                        </svg>
                                                    </button>
                                                    <input type="text" id="starttimeincrement" data-input-counter
                                                           data-input-counter-min="1" data-input-counter-max="5"
                                                           aria-describedby="helper-text-explanation"
                                                           className="bg-white border-x-0 border-gray-300 h-11 font-medium text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pb-6"
                                                           placeholder=""
                                                           value={startTimeIncrement}
                                                    />
                                                    <div
                                                        className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-600 space-x-1 rtl:space-x-reverse">
                                                        <span>minutes</span>
                                                    </div>
                                                    <button type="button" id="increment-button"
                                                            onClick={()=>stateIncrement(startTimeIncrement, setStartTimeIncrement,15)}
                                                            className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 focus:ring-2 focus:outline-none">
                                                        <svg className="w-3 h-3 text-gray-900"
                                                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                             fill="none" viewBox="0 0 18 18">
                                                            <path stroke="currentColor" stroke-linecap="round"
                                                                  stroke-linejoin="round" stroke-width="2"
                                                                  d="M9 1v16M1 9h16"/>
                                                        </svg>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="col-span-3">
                                            <label htmlFor="price"
                                                   className="block mb-2 text-sm font-medium text-gray-900">Session Price</label>
                                            <CustomCurrencyInput value={price} setPrice={(price)=>setPrice(price)} setCurrency={(currency)=>setCurrency(currency)}></CustomCurrencyInput>
                                        </div>

                                        <div className="col-span-full">
                                            <div className="relative flex gap-x-3">
                                                <div className="flex h-6 items-center">
                                                    <input
                                                        id="freelesson"
                                                        name="freelesson"
                                                        type="checkbox"
                                                        defaultChecked={courseInfo?.freeLesson}
                                                        className="h-4 w-4 rounded border-gray-300 ring-none text-secondary focus:ring-none"
                                                    />
                                                </div>
                                                <div className="text-sm leading-6">
                                                    <label htmlFor="candidates" className="font-medium text-gray-900">
                                                        First session is free
                                                    </label>
                                                    <p className="text-gray-500">Get notified when a candidate applies for a job.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                    <div>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Scheduling Settings</h2>
                                        <p className="mt-1 text-sm leading-6 text-gray-600">Maximize the benefits of our scheduling features to ensure convenience for both you and your students.</p>
                                    </div>

                                    <div
                                        className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                        <div className="sm:col-span-2 sm:col-start-1">
                                            <label htmlFor="minimumnotice"
                                                   className="block mb-2 text-sm font-medium text-gray-900">Minimum Notice</label>
                                            <div className="relative flex items-center max-w-[11rem]">
                                                <button type="button" id="minimumnotice"
                                                        onClick={()=>stateDecrement(minNotice, setMinNotice, 1)}
                                                        className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 focus:ring-2 focus:outline-none">
                                                    <svg className="w-3 h-3 text-gray-900"
                                                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 18 2">
                                                        <path stroke="currentColor" stroke-linecap="round"
                                                              stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>
                                                    </svg>
                                                </button>
                                                <input type="text" id="bedrooms-input" data-input-counter
                                                       data-input-counter-min="1" data-input-counter-max="5"
                                                       aria-describedby="helper-text-explanation"
                                                       className="bg-white border-x-0 border-gray-300 h-11 font-medium text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pb-6"
                                                       placeholder="" value={minNotice}/>
                                                <div
                                                    className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-600 space-x-1 rtl:space-x-reverse">
                                                    <span>hours</span>
                                                </div>
                                                <button type="button" id="increment-button"
                                                        onClick={()=>stateIncrement(minNotice, setMinNotice, 1)}
                                                        className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 focus:ring-2 focus:outline-none">
                                                    <svg className="w-3 h-3 text-gray-900"
                                                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                         fill="none" viewBox="0 0 18 18">
                                                        <path stroke="currentColor" stroke-linecap="round"
                                                              stroke-linejoin="round" stroke-width="2"
                                                              d="M9 1v16M1 9h16"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="sm:col-span-2">
                                            <label htmlFor="timebetween"
                                                   className="block mb-2 text-sm font-medium text-gray-900">Time Between Sessions</label>
                                            <div className="relative flex items-center max-w-[11rem]">
                                                <button type="button" id="decrement-button"
                                                        onClick={()=>stateDecrement(timeBetweenSessions, setTimeBetweenSessions, 15)}
                                                        className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 focus:ring-2 focus:outline-none">
                                                    <svg className="w-3 h-3 text-gray-900"
                                                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 18 2">
                                                        <path stroke="currentColor" stroke-linecap="round"
                                                              stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>
                                                    </svg>
                                                </button>
                                                <input type="text" id="timebetween" data-input-counter
                                                       data-input-counter-min="1" data-input-counter-max="5"
                                                       aria-describedby="helper-text-explanation"
                                                       className="bg-white border-x-0 border-gray-300 h-11 font-medium text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pb-6"
                                                       placeholder="" value={timeBetweenSessions}/>
                                                <div
                                                    className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-600 space-x-1 rtl:space-x-reverse">
                                                    <span>minutes</span>
                                                </div>
                                                <button type="button" id="increment-button"
                                                        onClick={()=>stateIncrement(timeBetweenSessions, setTimeBetweenSessions, 15)}
                                                        className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 focus:ring-2 focus:outline-none">
                                                    <svg className="w-3 h-3 text-gray-900"
                                                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                         fill="none" viewBox="0 0 18 18">
                                                        <path stroke="currentColor" stroke-linecap="round"
                                                              stroke-linejoin="round" stroke-width="2"
                                                              d="M9 1v16M1 9h16"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="maxnumber"
                                                   className="block mb-2 text-sm font-medium text-gray-900">Max Number of Sessions</label>
                                            <div className="relative flex items-center max-w-[11rem]">
                                                <button type="button" id="decrement-button"
                                                        onClick={()=>stateDecrement(maxNumOfSessions, setMaxNumOfSessions, 1)}
                                                        className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 focus:ring-2 focus:outline-none">
                                                    <svg className="w-3 h-3 text-gray-900"
                                                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 18 2">
                                                        <path stroke="currentColor" stroke-linecap="round"
                                                              stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>
                                                    </svg>
                                                </button>
                                                <input type="text" id="maxnumber" data-input-counter
                                                       data-input-counter-min="1" data-input-counter-max="5"
                                                       aria-describedby="helper-text-explanation"
                                                       className="bg-white border-x-0 border-gray-300 h-11 font-medium text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pb-6"
                                                       placeholder="" value={maxNumOfSessions}/>
                                                <div
                                                    className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-600 space-x-1 rtl:space-x-reverse">
                                                    <span>sessions</span>
                                                </div>
                                                <button type="button" id="increment-button"
                                                        onClick={()=>stateIncrement(maxNumOfSessions, setMaxNumOfSessions, 1)}
                                                        className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 focus:ring-2 focus:outline-none">
                                                    <svg className="w-3 h-3 text-gray-900"
                                                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                         fill="none" viewBox="0 0 18 18">
                                                        <path stroke="currentColor" stroke-linecap="round"
                                                              stroke-linejoin="round" stroke-width="2"
                                                              d="M9 1v16M1 9h16"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div
                                    className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                    <div>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Delete Course</h2>
                                        <p className="mt-1 text-sm leading-6 text-gray-600">
                                            Permanently remove your course.
                                        </p>
                                    </div>

                                    <div className="max-w-2xl space-y-10 md:col-span-2">
                                        <button
                                            onClick={openDeleteCourseModal}
                                            type="submit"
                                            className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
                                        >
                                            Delete this course
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="my-6 flex items-center justify-end gap-x-6">
                                <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="rounded-md bg-secondary px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-alt focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Save
                                </button>
                            </div>
                        </form>

                    </div>
                }
            </div>
            <BasicModal show={deleteCourseModalOpen} closeModal={() => {}}>
                <div className={"flex flex-col gap-2"}>
                    <h1 className={"font-semibold text-lg"}>Confirm course removal</h1>
                    <p>Are you sure you want to delete this course from Abakusz?</p>
                    <div className={"mt-2 p-4 bg-orange-100 rounded-lg text-red-700"}>
                        <div className={"flex gap-2 font-semibold"}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                            </svg>

                            <p>Warning</p>
                        </div>
                        <p className={"text-sm mt-2"}>By deleting this course, users will not be able to book a session! The magic link you shared so far, will be unavailable.</p>
                    </div>

                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                            onClick={() => deleteCard()}
                        >
                            Yes, confirm delete
                        </button>
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={() => setDeleteCourseModalOpen(false)}
                            // ref={cancelButtonRef}
                        >
                            No, cancel
                        </button>
                    </div>
                </div>
            </BasicModal>
        </div>
    );
}

export default EditCourse;