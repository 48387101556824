import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import dataLoader from "../../api/DataLoader";
import UserContext from "../../auth/UserContext";
import {useHistory} from "react-router";
import ProfilePicture from "../../components/ProfilePicture";
import {CheckIcon} from "@heroicons/react/20/solid";
import StudentSummary from "./StudentSummary";
import Calendar from "./Calendar";
import BasicModal from "../../components/BasicModal";
import BookingModalContent from "./BookingModalContent";
import SignupModalForm from "../signup/SignupModalForm";
import LoginModalForm from "../login/LoginModalForm";
import ProcessingBooking from "./ProcessingBooking";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function InvitationReceived(props) {
    const userContext = useContext(UserContext)
    let history = useHistory();
    const [courseInfo, setCourseInfo] = useState()
    const [signupModalOpen, setSignupModalOpen] = useState(false)
    const [processingModalOpen, setProcessingModalOpen] = useState(false)
    const [isSignup, setIsSignup] = useState(true)
    const [date, setDate] = useState()
    const [time, setTime] = useState()
    const [timeZone, setTimezone] = useState()
    const [booked, setBooked] = useState(false)

    const [selectedTimezone, setSelectedTimezone] =useState(
        localStorage.getItem("selectedTimezone") !== null ?
            localStorage.getItem("selectedTimezone")
            :

            Intl.DateTimeFormat().resolvedOptions().timeZone
    )

    useEffect(() => {
        let tokens = props.location.search.split("=")

        setDate(tokens[1].replace("&time", ""))
        setTime(tokens[2].replace("&timezone", ""))
        setTimezone(tokens[3])

        dataLoader.getLessonCard(props.match.params.lessonid)
            .then(res => res.data ? setCourseInfo(res.data) : history.push("/404"))
    }, []);


    const bookSession = (text) => {
        // console.log(moment(date + " "+ time).tz(timeZone, true).format())
        // console.log(userContext?.isAuthenticated)
        if(!userContext?.isAuthenticated){
            setSignupModalOpen(true)
            // console.log("1")

        }

        else{
            // dataLoader.saveAppointment(courseInfo?.userId, userContext.userInfo.id, moment(date + " "+ time).tz(timeZone, true).format(), moment(date + " "+ time).tz(timeZone, true).add(courseInfo.duration, 'minutes').format(), courseInfo?.title, userContext.authInfo.accessToken)
            //     .then(r => setBooked(true))
            //
            // console.log("2")
            processBooking()
        }
    }

    const processBooking = (text) => {
        setSignupModalOpen(false)
        setProcessingModalOpen(true)
    }

    const setBookingProcessed = () => {
        setProcessingModalOpen(false)
        history.push('/')
    }
    return (
        <div className={"w-screen h-screen flex justify-center py-16"}>
            <div className={"border border-gray-200 rounded-lg h-max p-8"}>
                <div className={"flex gap-3 mb-4"}>
                    <img
                        className= "h-12 w-auto"
                        src={process.env.PUBLIC_URL + '/primary-logo.svg'}
                        alt="Abakusz"
                    />
                    <h1 className={"text-center text-2xl text-secondary font-bold pt-2"}>Invitation</h1>

                </div>

                <div
                    // className="mx-auto max-w-2xl py-16 px-4 sm:py-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    // className="mx-auto max-w-2xl py-16 px-4 sm:py-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8"
                    >
                    <div className="lg:max-w-lg">
                        <div className="flex flex-col items-center gap-2">
                            <ProfilePicture userId={courseInfo?.userId} name={courseInfo?.displayName}
                                            height={"h-16"}></ProfilePicture>
                            <div className={"text-sm font-medium leading-6"}>
                                {courseInfo?.displayName} invited you to study!
                            </div>
                        </div>
                        <div className="mt-4 text-center">
                            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{courseInfo?.title}</h1>
                        </div>

                        <section aria-labelledby="information-heading" className="mt-4">
                            <div className="flex w-full justify-center items-center">
                                <p className="text-lg text-slate-900 sm:text-xl bg-slate-100 rounded-lg px-2 py-1">{`${courseInfo?.price} ${courseInfo?.currency}`}</p>

                                <div className="ml-4 border-l border-gray-300 pl-4 flex gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                    </svg>
                                    <p>{courseInfo?.duration} minutes</p>
                                </div>


                            </div>

                            <div className="mt-8 space-y-6 max-h-64 overflow-y-auto">
                                <p className="text-base text-gray-500 whitespace-pre-line">{
                                    <div className="space-y-6 py-4 px-4 sm:px-6">
                                        <div className={"flex gap-2"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                            </svg>
                                            <ul role="list" className="px-6 list-disc text-slate-500 w-96">
                                                <li className="text-sm">Please take care of the necessary payments as you agreed with your tutor</li>
                                                <li className="text-sm">In case you need to modify your booking, please notify the tutor as soon as possible</li>
                                            </ul>
                                        </div>

                                        <div className={"text-slate-500 text-sm"}>
                                            Once you've made a booking, your tutor will be promptly notified. They'll then have the option to accept or decline the booking, and we'll keep you informed via email about their decision.
                                        </div>
                                    </div>
                                }</p>
                            </div>
                            {courseInfo?.freeLesson ?
                                <div className="mt-6 flex items-center">
                                    <CheckIcon className="h-5 w-5 flex-shrink-0 text-green-500" aria-hidden="true"/>
                                    <p className="ml-2 text-sm text-gray-500">The first introductory lesson is free</p>
                                </div>
                                :
                                <div></div>
                            }

                        </section>
                    </div>

                </div>
                <div className="w-full border-t border-gray-300 my-4" />
                <div className={"flex justify-between"}>
                    <p className={"text-sm"}>Lorem ipsum lorem ipsum</p>
                    <h2 className={"font-bold text-lg"}>
                        {/*moment(date).format("MMMM D, h:mm")*/}
                        <span className="text-sm text-gray-900">{moment(date + " " + time).tz(timeZone, true)?.tz(selectedTimezone)?.format('MMMM Do, ')}</span>
                        <span className="text-xl font-medium text-gray-900">{moment(date + " " + time).tz(timeZone, true)?.tz(selectedTimezone)?.format('HH:mm')}</span>
                    </h2>
                </div>


                <button
                    onClick={()=>bookSession()}
                    type="button"
                    className="w-full rounded-md bg-secondary mt-4 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-secondary-alt focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                >
                    Accept
                </button>
            </div>

            <BasicModal show={signupModalOpen} closeModal={() => {setSignupModalOpen(false)}}>
                <div>
                    {isSignup ?
                        <SignupModalForm changeMode={()=> {setIsSignup(false)}} callback={() => {processBooking()}}></SignupModalForm>
                        :
                        <LoginModalForm changeMode={()=> {setIsSignup(true)}} hook={() => {bookSession()}}></LoginModalForm>
                    }
                </div>
            </BasicModal>

            <BasicModal show={processingModalOpen} closeModal={() => {setProcessingModalOpen(false)}}>
                <ProcessingBooking setBooked={() => setBookingProcessed()} courseInfo={courseInfo} date={date} time={time} timeZone={timeZone}></ProcessingBooking>
            </BasicModal>
        </div>
    )
}