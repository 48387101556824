import React, {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import BasicModal from "./BasicModal";
import DatePicker from "./DatePicker";
import moment from "moment";

export default function InvoiceModal(props){
    const [date, setDate] = useState();
    const [dateStringURL, setDateStringURL] = useState();

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        // notify()
    }


    return(
        <div>
            <BasicModal show={props.open} closeModal={props.setOpen}>
                <div className={"flex flex-col gap-2"}>
                    <h1 className={"font-semibold text-lg"}>Pick a date and time</h1>
                    <p>Are you sure you want to delete this course from Abakusz?</p>

                </div>
                <div className={"w-full"}>
                    <DatePicker setDate={(date)=>setDate(date)} courseInfo={props.courseInfo} setDateStringURL={setDateStringURL}></DatePicker>

                </div>
                <div className="mt-6">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 mb-1">
                        Personal invitation
                    </label>
                    <div className="flex space-x-2">

                        <input
                            className="bg-gray-100 border-none focus:outline-none flex h-10 w-full rounded-md px-3 py-2 text-sm file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                            id="link" readOnly="" value={dateStringURL ? "app.abakusz.io/" + props.courseInfo.lessonId + "/invite?" + dateStringURL : "Select date and time"} disabled={!dateStringURL}>
                        </input>
                        <button
                            disabled={!dateStringURL}
                            onClick={()=>copyToClipboard("app.abakusz.io/" + props.courseInfo.lessonId + "/invite?" + dateStringURL)}
                            className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-secondary text-white hover:bg-secondary-alt h-10 px-4 py-2 shrink-0">
                            Copy Invite Link
                        </button>
                    </div>
                </div>
                {dateStringURL ?

                    <p className={"mt-2 mb-6 text-sm"}>
                        Your selected time is: {date?.format("MMMM DD, HH:mm")}
                    </p>
                    :
                    <p className={"mt-2 mb-6 font-sm h-2"}>
                    </p>
                }
                {/*<div>*/}
                {/*    <p className={"text-sm"}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.</p>*/}
                {/*</div>*/}
            </BasicModal>
        </div>
    )
}