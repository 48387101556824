import React, {useContext} from "react";
import { Route, Redirect } from "react-router-dom";
import UserContext from "./UserContext";

export const ProtectedRoute = ({component: Component, ...rest}) => {

    const userContext = useContext(UserContext)

    return (
        <Route
            {...rest}
            render={props => {
                if(userContext.isAuthenticated){
                    return <Component {...props} />;
                }

                return (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {
                                from: props.location
                            }
                        }}
                    />
                );

            }}
        />
    );
}
