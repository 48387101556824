import React, {useEffect, useState} from 'react';

import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import HourSelect from "../pages/workhours-settings/HourSelect";
import moment from "moment";

export default function DatePicker(props) {
    const [selected, setSelected] = useState();
    // const [selectedHour, setSelectedHour] = useState(moment().format('h:mm A'));
    const [selectedHour, setSelectedHour] = useState();

    useEffect(() => {
        if(selected && selectedHour){

            let hour = selectedHour?.split(':')[0]
            let minute = selectedHour?.split(':')[1].split(" ")[0]

            let timezone = localStorage.getItem("selectedTimezone")!==null? localStorage.getItem("selectedTimezone"): Intl.DateTimeFormat().resolvedOptions().timeZone


            // props.setDate(moment(selected).set("hour", hour).set("minute", minute).tz(timezone, true).format())
            props.setDate(moment(selected).set("hour", hour).set("minute", minute).tz(timezone, true))
            props.setDateStringURL("date="+ moment(selected).tz(timezone, true).format("YYYY-MM-DD") + "&time="+selectedHour + "&timezone=" + timezone)

        }
    }, [selected, selectedHour]);

    const handleTimeChange = (e) => {
        // console.log(e.target.value)

        setSelectedHour(e.target.value)
    }


    return (
        <div className={"h-full flex flex-col justify-between"}>
            <div className={"flex justify-center h-[340px]"}>
                <DayPicker
                    mode="single"
                    selected={selected}
                    onSelect={setSelected}
                />
            </div>
            <div className={"flex justify-between"}>
                <div className={"flex gap-3"}>
                    <p className={"mt-2"}>Pick a time: </p>
                    {/*<HourSelect time={selectedHour} editTime={(time)=>setSelectedHour(time)} style={"bg-gray-100"}></HourSelect>*/}
                    <input onChange={(e)=>handleTimeChange(e)}type="time" id="appt" name="appt" min="09:00" max="18:00" required />
                </div>
                <div className="mt-1 ml-4 pl-4 flex gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                    <p>{props.courseInfo?.duration} minutes</p>
                </div>
            </div>

        </div>


    );
}