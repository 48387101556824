import Lottie from 'react-lottie';
import animationData from '../../lotties/calendar-booking.json';
import checkmarkAnimationData from '../../lotties/checkmark.json';
import {useEffect, useState} from "react";
import {useHistory} from "react-router";

export default function BookingModalContent(props){
    const [loaded, setLoaded] = useState(false);
    let history = useHistory();

    useEffect(
        () => {
            let timer = setTimeout(() => setLoaded(true), 5000);
            return () => {
                clearTimeout(timer);
            };
        }, []);

    useEffect(
        () => {
            if(loaded && props.booked) {
                let timer = setTimeout(() => history.push("/"), 1000);
                return () => {
                    clearTimeout(timer);
                };
            }
        }, [loaded, props.booked]);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const checkmarkOptions = {
        loop: false,
        autoplay: true,
        animationData: checkmarkAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };


    return(
        <div>
            {
                loaded  && props.booked ?
                    <div className={"flex flex-col items-center gap-10 my-8"}>
                        <Lottie
                            options={checkmarkOptions}
                            height={300}
                            width={400}
                        />
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    className="animate-bounce inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-xl text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
                        {/*>*/}
                        {/*    Check out your appointments*/}
                        {/*</button>*/}
                    </div>
                    :
                    <div className={"flex flex-col items-center gap-10 my-8"}>
                        <Lottie
                            options={defaultOptions}
                            height={300}
                            width={400}
                        />
                        <h2 className={"animate-pulse text-xl font-bold py-3"}>We are processing your booking</h2>
                    </div>
            }
        </div>
    )
}